import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FadeIn } from 'src/skins/common/effects';

const Wrapper = styled.div`
  border-radius: inherit;
  overflow-y: hidden;

  .container {
    padding: 1rem;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    border-radius: inherit;
  }
  .light {
    background-color: ${props => props.theme.color.light};
    color: ${props => props.theme.color.primary};
  }
  .primary {
    background-color: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.light};
  }
`;

/**
 * @description PageView Component
 * @param {PageViewProps} props
 */
const PageView = props => {
  const { children, background, className } = props;

  return (
    <FadeIn>
      <Wrapper>
        <div className={`container ${background} ${className}`}>{children}</div>
      </Wrapper>
    </FadeIn>
  );
};

PageView.defaultProps = {
  background: 'light',
  className: '',
  isSkinPage: true,
};

/**
 * PageView PropType Definition
 * @typedef {String} className
 * @typedef {Boolean} isSkinPage
 * @typedef {Object} PageViewProps
 * @property {('light'|'primary')} background
 */
PageView.propTypes = {
  className: PropTypes.string.isRequired,
  isSkinPage: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  background: PropTypes.oneOf(['light', 'primary']).isRequired,
};

export { PageView };
