import React from 'react';
import styled from 'styled-components';
import appErrorSvg from 'src/assets/svgs/app-error.svg';
import { Button, PageView } from 'src/skins/common/components';
import { reportError } from 'src/store/actions/errorAlert';

const errorPreviewDuration = 5000; // in milliseconds

const Wrapper = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  .error_img {
    height: 30vh;
  }
  .app_error_text {
    text-align: center;
  }
`;

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    const data = {
      appName: 'mmo-mobile-app',
      errorLevel: 'Critical',
      message: error.message,
      fileName: JSON.stringify({ error, info }),
      meta: { hasError: this.state.hasError, error, info },
    };
    reportError(data);

    // Reset Error UI Fallback after some time
    setTimeout(() => {
      this.setState({ hasError: false });
    }, errorPreviewDuration);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageView>
          <Wrapper>
            <img src={appErrorSvg} className="error_img" />
            <h5>An error has been encountered!</h5>
            <p className="app_error_text">
              An Error report has been sent and this would help us resolve this issue quickly and easily.
            </p>
            <Button text="Refresh App" onClick={() => window.location.reload()} />
          </Wrapper>
        </PageView>
      );
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorHandler;
