import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageView, Spinner } from 'src/skins/common/components';
import { setShortCode, setupApp } from 'src/store/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 5%;
`;

const Setup = ({ match }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.APP);
  const history = useHistory();

  useEffect(() => {
    setShortCode(match.params.platform);
    dispatch(setupApp());
  }, []);

  useEffect(() => {
    !isLoading &&
      setTimeout(() => {
        history.push('/splash');
      }, 3000);
  }, [isLoading]);

  return (
    <PageView background="light">
      <Wrapper>
        <Spinner />
      </Wrapper>
    </PageView>
  );
};

Setup.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Setup;
