const reactSelectTheme = (theme, primaryColor) => ({
  ...theme,
  outline: 'none',
  borderRadius: '.25rem',
  colors: {
    ...theme.colors,
    primary25: `${primaryColor}30`,
    primary: primaryColor,
  },
});

export { reactSelectTheme };
