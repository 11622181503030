import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MarsButton, MarsModal } from 'src/skins/mars/components/common';
import { TextInput, PinInput } from 'src/skins/mars/components/inputs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionPin } from 'src/store/actions/userActions';

/**
 * @description SetTransactionPinModal Component
 * @param {SetTransactionPinModalProps} props
 */
const SetTransactionPinModal = props => {
  const { isOpen, closeModal } = props;
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.USER);

  const [pin, setPin] = useState({});

  const getPin = () => {
    let pinString = '';
    Object.keys(pin).forEach(item => (pinString = pinString + pin[item]));
    return pinString;
  };

  const onSubmit = data => {
    const { password } = data;
    const payload = {
      pin: getPin(),
      password,
    };

    dispatch(
      setTransactionPin(payload, success => {
        return success && closeModal(), success && setPin({});
      })
    );
  };

  return (
    <MarsModal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h5 className="color-primary">Set Transaction Pin</h5>
        <p>Pin can only be set once.</p>
        {/* <p>Please contact support to reset Pin.</p> */}

        <br />

        <p style={{ marginBottom: '0.5rem' }}>Enter Pin</p>
        <PinInput value={pin} onChange={setPin} />

        <br />

        <p style={{ marginBottom: '0.5rem' }}>Enter Password</p>
        <TextInput type="password" name="password" ref={register} required />
        <br />

        <div style={{ padding: '0.5rem 0', marginBottom: '1.5rem' }}>
          <MarsButton type="submit" text="Set Pin" size="large" isLoading={isLoading.setTransactionPin} />
        </div>
      </form>
    </MarsModal>
  );
};

SetTransactionPinModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

/**
 * SetTransactionPinModal PropType Definition
 * @typedef {Object} SetTransactionPinModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 */
SetTransactionPinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export { SetTransactionPinModal };
