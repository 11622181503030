import React from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    h2 {
      font-size: large;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 2rem 0;
  }
`;

const HtbCollection = () => {
  const history = useHistory();

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard/collections')} />
            <h2 style={{ marginLeft: '0.5rem' }}>Health In The Box</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>!coming soon</section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default HtbCollection;
