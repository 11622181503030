import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'src/skins/common/components';
import { TextInput, SelectInput } from 'src/skins/venus/components/inputs';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const FilterInputsWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;

  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;

    & input {
      max-width: 9rem;
    }
  }
`;

const FilterTransactionsModal = ({ isOpen, closeModal, filterTransactions }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    if (Object.entries(data).length) filterTransactions(data);
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} bottom>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <center>
          <h4>Filter by</h4>
        </center>

        <br />

        <FilterInputsWrapper>
          {/* <TextInput type="text" name="search" placeholder="Search transactions" ref={register} /> */}

          <SelectInput
            label="Status"
            name="status"
            options={statusOptions}
            ref={register}
            placeholder="Select Status"
          />

          <div className="grid-col-2">
            <TextInput label="Start date" type="date" name="start_date" placeholder="Start date" ref={register} />
            <TextInput label="End date" type="date" name="end_date" placeholder="End date" ref={register} />
          </div>

          <SelectInput
            label="Transaction type"
            name="narrative"
            options={transactionTypesOptions}
            ref={register}
            placeholder="Select Transaction type"
          />
        </FilterInputsWrapper>

        <br />

        <Button type="submit" text="Filter" size="large" />
      </form>
    </Modal>
  );
};

const statusOptions = [
  {
    label: 'Delivered',
    value: 'delivered',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

const transactionTypesOptions = [
  {
    label: 'Bank Transfer',
    value: 'Bank Transfer',
  },
  {
    label: 'Recharge Card',
    value: 'Recharge Card',
  },
  {
    label: 'Data Plan',
    value: 'Data Plan',
  },
];

FilterTransactionsModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

/**
 * FilterTransactionsModal PropType Definition
 * @typedef {Object} FilterTransactionsModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {Function} filterTransactions
 */
FilterTransactionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  filterTransactions: PropTypes.func,
};

export { FilterTransactionsModal };
