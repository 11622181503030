import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { infoAlert } from 'src/store/actions/alertActions';
import { copyToClipboard } from 'src/utils';
import { createVirtualWallet, getVirtualWalletRequests } from 'src/store/actions/userActions';
import { RequestBvnModal } from 'src/skins/pluto/modals';

const pageTitle = 'Fund wallet';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  section {
    margin: 1rem 0;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }

  .transfer-instructions {
    margin-top: 3rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    padding: 1rem;
    border-radius: 8px;

    .transfer-to {
      font-weight: 600;
    }

    .account-info {
      position: relative;
      font-size: 1rem;
      text-align: center;

      .copy-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        width: fit-content;
        cursor: pointer;
      }

      & > div {
        &:first-child + * {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & > :first-child {
            font-weight: bold;
          }
        }

        &:last-child {
          display: flex;
          justify-content: space-around;
          margin-top: 1rem;

          & > div > :first-child {
            font-weight: bold;
          }
        }
      }
    }
  }

  .data-flex {
    display: flex;
    justify-content: space-between;

    & > * {
      margin: 0.25rem 0;

      &::first-child-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }
`;

const Wallet = () => {
  const mmo = useSelector(state => state.MMO.mmo);
  const { wallet, isLoading, virtualWalletRequests } = useSelector(state => state.USER);
  const unapprovedWalletRequests = virtualWalletRequests.filter(request => {
    return request.status !== 'approved';
  });
  const dispatch = useDispatch();
  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  useEffect(() => {
    dispatch(getVirtualWalletRequests());
  }, []);

  const virtualWalletsHaveBankName = bankName => {
    const virtualWallets = wallet?.virtualWallets || [];
    const hasBankName = virtualWallets.some(wallet => {
      return new RegExp(bankName, 'i').test(wallet.bankName);
    });
    return hasBankName;
  };

  const mmoIsNotBlacklisted = !mmo.blacklisted;
  const mmoIsKredi = mmo?.shortCode === 'kredi';
  const mmoIsNotKredi = !mmoIsKredi;
  const safeHavenWalletExist = () => virtualWalletsHaveBankName('SAFE HAVEN');
  const krediWalletExist = () => virtualWalletsHaveBankName('Kredi');
  const providusWalletExist = () => virtualWalletsHaveBankName('Providus');
  const transferToMsg =
    wallet?.virtualWallets?.length > 1
      ? 'Transfer to any of these accounts to fund your wallet'
      : 'Transfer to this account to fund your wallet';

  const [showBvnModal, setShowBvnModal] = useState(false);
  const openBvnModal = vendor => setShowBvnModal(vendor);
  const closeBvnModal = () => setShowBvnModal(false);

  const virtualWallets = mmoIsKredi
    ? (wallet.virtualWallets || []).filter(wallet => {
        return new RegExp('kredi', 'i').test(wallet.bankName);
      })
    : wallet.virtualWallets || [];

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <h2>{pageTitle}</h2>
          <img src={UserIcon} alt="USER" />
        </div>

        <br />

        <div className="text-normal">{transferToMsg}</div>

        <section>
          {isLoading.getUserWallet && <p>Fetching Wallet details ....</p>}
          {isLoading.createVirtualWallet && <p>Creating virtual wallet</p>}

          <br />

          {!isLoading.state &&
            virtualWallets.length > 0 &&
            virtualWallets.map((wallet, index) => (
              <div key={wallet.accountNumber}>
                <input
                  id={`virtualAcctNumber${index}`}
                  type="text"
                  value={wallet.accountNumber}
                  onChange={null}
                  readOnly
                  style={hiddenInputStyle}
                />
                <div className="transfer-instructions">
                  <div className="account-info">
                    <div className="copy-wrapper">
                      <Icon
                        name="copy"
                        size="small"
                        onClick={() => copyToClipboard(`virtualAcctNumber${index}`, copiedAlert)}
                      />
                    </div>
                    <div>
                      <div className="text-xl">{wallet.accountNumber}</div>
                      <div className="text-sm">Account Number</div>
                    </div>
                    <div>
                      <div>
                        <div>{wallet.accountName}</div>
                        <div className="text-xs">Account Name</div>
                      </div>
                      <div>
                        <div>{wallet.bankName}</div>
                        <div className="text-xs">Bank Name</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {mmoIsNotBlacklisted && mmoIsNotKredi && (
            <>
              {!safeHavenWalletExist() && (
                <>
                  <Button
                    size="large"
                    text="Generate Safe Haven Virtual Account"
                    className="outline"
                    isLoading={isLoading.createVirtualWallet === 'safe-haven'}
                    spinnerTheme="primary"
                    onClick={() => openBvnModal('safe-haven')}
                  />
                  <RequestBvnModal
                    isOpen={showBvnModal === 'safe-haven'}
                    closeModal={closeBvnModal}
                    clickIsLoading={isLoading.createVirtualWallet === 'safe-haven'}
                    handleConfirmTransactionClick={bvn => {
                      dispatch(
                        createVirtualWallet(
                          { bvn, vendor: 'safe-haven' },
                          {
                            success: () => closeBvnModal(),
                          }
                        )
                      );
                    }}
                  />
                </>
              )}

              <br />
            </>
          )}

          {!krediWalletExist() && (
            <>
              <Button
                size="large"
                text="Generate Kredi Virtual Account"
                className="outline"
                isLoading={isLoading.createVirtualWallet === 'kredi'}
                spinnerTheme="primary"
                onClick={() => openBvnModal('kredi')}
              />
              <RequestBvnModal
                isOpen={showBvnModal === 'kredi'}
                closeModal={closeBvnModal}
                clickIsLoading={isLoading.createVirtualWallet === 'kredi'}
                handleConfirmTransactionClick={bvn => {
                  dispatch(
                    createVirtualWallet(
                      { bvn, vendor: 'kredi' },
                      {
                        success: () => closeBvnModal(),
                      }
                    )
                  );
                }}
              />
              <br />
            </>
          )}

          {mmoIsNotBlacklisted && mmoIsNotKredi && (
            <>
              {!providusWalletExist() && (
                <>
                  <Button
                    size="large"
                    text="Generate Providus Virtual Account"
                    className="outline"
                    isLoading={isLoading.createVirtualWallet === 'providus'}
                    spinnerTheme="primary"
                    onClick={() => openBvnModal('providus')}
                  />
                  <RequestBvnModal
                    isOpen={showBvnModal === 'providus'}
                    closeModal={closeBvnModal}
                    clickIsLoading={isLoading.createVirtualWallet === 'providus'}
                    handleConfirmTransactionClick={bvn => {
                      dispatch(
                        createVirtualWallet(
                          { bvn, vendor: 'providus' },
                          {
                            success: () => closeBvnModal(),
                          }
                        )
                      );
                    }}
                  />
                </>
              )}
            </>
          )}
        </section>

        {unapprovedWalletRequests.length > 0 && (
          <section>
            <br />

            <div className="header">
              <h3>Wallet Requests</h3>
            </div>

            <div>
              {unapprovedWalletRequests.map((request, index) => (
                <div key={index}>
                  <div className="flex-between">
                    <span>{index + 1}.</span>
                  </div>
                  <div className="flex-between">
                    <span>Virtual Wallet Type</span>
                    <span>{request.vendor}</span>
                  </div>
                  <div className="flex-between">
                    <span>Status</span>
                    <span>{request.status}</span>
                  </div>
                  {request.status !== 'pending' && request.reason && (
                    <div className="flex-between">
                      <span>Reason</span>
                      <span>{request.reason}</span>
                    </div>
                  )}
                  <div className="flex-between">
                    <span>Requested on</span>
                    <span>{request.timeCreated ? new Date(request.timeCreated).toDateString() : ''}</span>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
      </Wrapper>
    </DashboardLayout>
  );
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '-5rem' };

export default Wallet;
