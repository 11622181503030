import React from 'react';
import PropTypes from 'prop-types';
import { MarsModal, MarsButton } from 'src/skins/mars/components/common';
import { TextInput } from 'src/skins/mars/components/inputs';
import { useForm } from 'react-hook-form';
// import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateDeviceName, getMyDevices } from 'src/store/actions/deviceActions';

const EditDeviceNameModal = ({ isOpen, closeModal, device }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.DEVICE);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      device_name: device?.deviceName || '',
    },
  });

  const onSubmit = data => {
    const { device_name } = data;
    dispatch(
      updateDeviceName(device?._id, device_name, {
        success: () => {
          closeModal();
          dispatch(getMyDevices());
        },
      })
    );
  };

  return (
    <MarsModal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h5 className="color-primary">Edit Device Name</h5>

        <br />

        <TextInput type="text" name="device_name" ref={register} required placeholder="Device name" />

        <br />
        <br />

        <MarsButton type="submit" text="Submit" size="large" isLoading={isLoading.updateDeviceName} />
      </form>
    </MarsModal>
  );
};

EditDeviceNameModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  device: null,
};

/**
 * EditDeviceNameModal PropType Definition
 * @typedef {Object} EditDeviceNameModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {object} device
 */
EditDeviceNameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  device: PropTypes.object,
};

export { EditDeviceNameModal };
