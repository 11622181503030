import clients from 'src/clients';
import { SET_DEVICE_LOADING, SET_MY_DEVICES, SET_MY_DEVICE } from 'src/store/types';
import { errorAlert, successAlert } from './alertActions';

const { appService, posService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_DEVICE_LOADING,
    payload: { action, state },
  };
};

export const setMyDevice = device => ({ type: SET_MY_DEVICE, payload: device || {} });

export const getMyDevices = () => dispatch => {
  dispatch(setIsLoading('getMyDevices', true));
  appService
    .get('/pos/get-devices')
    .then(({ data }) => {
      dispatch({
        type: SET_MY_DEVICES,
        payload: data.data || [],
      });
    })
    .catch(error => dispatch(errorAlert(error?.response?.data?.message)))
    .finally(() => dispatch(setIsLoading('getMyDevices', false)));
};

export const updateDeviceName = (posId, deviceName, callback) => dispatch => {
  dispatch(setIsLoading('updateDeviceName', true));
  appService
    .get(`/pos/update/${posId}/${deviceName}`)
    .then(({ data }) => {
      if (callback?.success) callback.success(data);
    })
    .catch(error => dispatch(errorAlert(error?.response?.data?.message)))
    .finally(() => dispatch(setIsLoading('updateDeviceName', false)));
};

export const updateDevicePin = (data, callback) => dispatch => {
  dispatch(setIsLoading('updateDevicePin', true));
  appService
    .post('/pos/update-pin', data)
    .then(({ data }) => {
      dispatch(successAlert(data?.message || 'Pin reset successful'));
      if (callback?.success) callback.success(data);
    })
    .catch(error => dispatch(errorAlert(error?.response?.data?.message)))
    .finally(() => dispatch(setIsLoading('updateDevicePin', false)));
};

export const getActivateDeviceSummary = (params,callback) => dispatch => {
  const { pos_type = ''} = params || {};
  dispatch(setIsLoading('getActivateDeviceSummary', true));
  posService
    .get('/activate-summary', {
      params: {
        pos_type
      },
    })
    .then(({ data }) => {
      if (callback?.success) callback.success(data);
    })
    .catch(error => dispatch(errorAlert(error?.response?.data?.message)))
    .finally(() => dispatch(setIsLoading('getActivateDeviceSummary', false)));
};

export const activateDevice = (deviceId, params, callback) => dispatch => {
  const { pos_type = ''} = params || {};
  dispatch(setIsLoading('activateDevice', true));
  posService
    .get(`/activate/${deviceId}`, {
      params: {
        pos_type
      },
    })
    .then(({ data }) => {
      if (callback?.success) callback.success(data);
    })
    .catch(error => dispatch(errorAlert(error?.response?.data?.message)))
    .finally(() => dispatch(setIsLoading('activateDevice', false)));
};
