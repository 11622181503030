import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getActivateDeviceSummary, activateDevice, getMyDevices } from 'src/store/actions/deviceActions';
import { Spinner } from 'src/skins/common/components';
import { MarsModal, MarsButton } from 'src/skins/mars/components/common';
import styled from 'styled-components';
import { formatCurrency } from 'src/utils';

const Wrapper = styled.div`
  .activation-summary {
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      margin-bottom: 0.75rem;

      & > :first-child {
        justify-self: flex-start;
        font-weight: 500;
      }

      & > :last-child {
        justify-self: flex-end;
        text-align: right;
      }
    }
  }
`;

const ActivateDeviceModal = ({ isOpen, closeModal, device }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.DEVICE);
  const [activationSummary, setActivationSummary] = useState(null);

  useEffect(() => {
    dispatch(
      getActivateDeviceSummary(
        { pos_type: device.os },
        {
          success: data => setActivationSummary(data),
          error: () => closeModal(),
        }
      )
    );
  }, []);

  const onActivateDeviceButtonClick = () => {
    dispatch(
      activateDevice(
        device?._id,
        { pos_type: device.os },
        {
          success: () => {
            closeModal();
            dispatch(getMyDevices());
          },
        }
      )
    );
  };

  const displayItem = (name, value) => (
    <div>
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );

  return (
    <MarsModal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <h5 className="color-primary">Activation Summary</h5>

        <br />

        {activationSummary ? (
          <div className="activation-summary">
            {activationSummary.amount && displayItem('Amount: ', formatCurrency(activationSummary.amount || 0, 'NGN'))}
            {activationSummary.walletBalance &&
              displayItem('Wallet balance: ', formatCurrency(activationSummary.walletBalance || 0, 'NGN'))}
            {activationSummary.narration && displayItem('Narration: ', activationSummary.narration)}
          </div>
        ) : (
          <div className="text-center">
            <Spinner />
            <br />
            <div>loading activation summary ...</div>
          </div>
        )}

        <br />
        <br />

        <MarsButton
          type="button"
          text="Activate Device"
          size="large"
          onClick={onActivateDeviceButtonClick}
          isLoading={isLoading.activateDevice}
        />
      </Wrapper>
    </MarsModal>
  );
};

ActivateDeviceModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  device: null,
};

/**
 * ActivateDeviceModal PropType Definition
 * @typedef {Object} ActivateDeviceModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {object} device
 */
ActivateDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  device: PropTypes.object,
};

export { ActivateDeviceModal };
