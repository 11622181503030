import React from 'react';
import styled from 'styled-components';
import { DashboardLayout, InnerPageHeader } from 'src/skins/jupiter/components/layouts';
import { Link } from 'react-router-dom';
import RightChevronIcon from 'src/assets/svgs/icon-right-chevron.svg';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;

  .profile-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-size: 13px;
    line-height: 13px;
    color: ${props => props.theme.color.black};
    text-decoration: none;
    cursor: pointer;
    background-color: ${props => props.theme.color.white};
    border: 1px solid ${props => props.theme.color.primary};
    outline: none;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid ${props => props.theme.color.white};
      transition: 0.3s;
    }

    & > * {
      margin: 0.5rem 0;
    }
  }
`;

export const Profile = () => {
  const profileMenu = [{ title: 'ID Verification', url: '/dashboard/settings/profile/verification' }];

  const profileMenuMap = profileMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="profile-menu-item" key={index} onClick={menu.onClick && menu.onClick}>
        <span>{menu.title || ''}</span>
        <img src={RightChevronIcon} alt="..." />
      </Link>
    );
  });
  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader navigateBackTo="/dashboard/settings">
          <h4>Profile</h4>
        </InnerPageHeader>

        {profileMenuMap}
      </Wrapper>
    </DashboardLayout>
  );
};

export default Profile;
