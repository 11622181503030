import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'src/skins/common/components';

const Wrapper = styled.div`
  .mars--button {
    appearance: none;
    outline: none;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.color.light};
    color: ${props => props.theme.color.mars.text || props.theme.color.text};
    font-size: large;
    font-weight: 600;
    height: 56px;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #000000;
    cursor: pointer;

    &[sizing='small'] {
      font-size: 16px;
      height: 50px;
    }

    &[theme='secondary'] {
      border-color: ${props => props.theme.color.mars.text || props.theme.color.text};
      background: ${props => props.theme.color.mars.text || props.theme.color.text};
      color: ${props => props.theme.color.light};
    }

    &:focus,
    &:hover {
      opacity: 0.85;
    }
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    padding-top: 2px;

    &[sizing='small'] {
      height: 50px;
    }
  }
`;

export const MarsButton = props => {
  const { size, type, text, onClick, isLoading, disabled, theme } = props;
  return (
    <Wrapper>
      {isLoading ? (
        <span className="spinner-wrapper" sizing={size}>
          <Spinner theme="primary" />
        </span>
      ) : (
        <button type={type} sizing={size} className="mars--button" onClick={onClick} theme={theme} disabled={disabled}>
          {text}
        </button>
      )}
    </Wrapper>
  );
};

MarsButton.defaultProps = {
  size: 'large',
  isLoading: false,
  disabled: false,
  className: '',
  type: 'button',
  text: ' ',
  theme: 'primary',
};

/**
 * MarsButton PropType Definition
 * @typedef {Object} MarsButtonProps
 * @property {('small'|'large')} size
 * @property {boolean} isLoading
 * @property {disabled} disabled
 * @property {string} className
 * @property {string} type
 * @property {string} text
 * @property {string} theme
 * @property {Function} onClick
 */
MarsButton.propTypes = {
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.string,
  onClick: PropTypes.func,
};

export default MarsButton;
