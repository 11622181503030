import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1rem;

  & > :first-child {
    margin-right: 0.5rem;

    button {
      background: none;
      border: 0;
      border-radius: 50%;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  & > :last-child {
    width: 100%;
    margin-top: -0.25rem;
  }
`;

/**
 * @description InnerPageHeader Component
 * @param {InnerPageHeaderProps} props
 */
const InnerPageHeader = props => {
  const { children } = props;
  const history = useHistory();
  const { navigateBackTo } = props;
  const goBack = () => {
    if (navigateBackTo) return history.replace(navigateBackTo);
    if (window.history.length > 2) return history.goBack();
  };

  return (
    <Wrapper>
      <div>
        <button onClick={goBack}>
          <Icon size="large" name="back" />
        </button>
      </div>
      <div>{children}</div>
    </Wrapper>
  );
};

InnerPageHeader.defaultProps = {
  navigateBackTo: '',
};

/**
 * InnerPageHeader PropType Definition
 * @typedef {Object} InnerPageHeaderProps
 * @property {String} navigateBackTo
 * @property {*} children
 */
InnerPageHeader.propTypes = {
  navigateBackTo: PropTypes.string,
  children: PropTypes.any,
};

export { InnerPageHeader };
