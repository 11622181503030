module.exports = Object.freeze({
  // App Actions types
  CLEAR_SESSION: 'CLEAR_SESSION',
  APP_IS_LOADING: 'APP_IS_LOADING',
  APP_NOT_LOADING: 'APP_NOT_LOADING',
  SET_APP_IS_ACTIVE: 'SET_APP_IS_ACTIVE',
  SET_BASE_URL: 'SET_BASE_URL',

  //Notification Action Types
  SUCCESS_ALERT: 'SUCCESS_ALERT',
  ERROR_ALERT: 'ERROR_ALERT',
  INFO_ALERT: 'INFO_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',

  // Mmo Actions types
  SET_MMOS: 'SET_MMOS',
  SET_MMO: 'SET_MMO',
  SET_MMO_ACTIONS: 'SET_MMO_ACTIONS',
  SET_MMO_SHORT_CODE: 'SET_MMO_SHORT_CODE',

  // User Actions types
  SET_USER: 'SET_USER',
  SET_USER_ACTIONS: 'SET_USER_ACTIONS',
  SET_USER_WALLET: 'SET_USER_WALLET',
  SET_VIRTUAL_WALLET_REQUESTS: 'SET_VIRTUAL_WALLET_REQUESTS',
  SET_ACCOUNT_IS_VALID: 'SET_ACCOUNT_IS_VALID',
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',

  // Transaction  Action Types
  SET_TRANSACTION_LOADING: 'SET_TRANSACTION_LOADING',
  ADD_TRANSACTIONS: 'ADD_TRANSACTIONS',
  ADD_TRANSACTION: 'ADD_TRANSACTION',
  SET_TRANSACTION_DATE: 'SET_TRANSACTION_DATE',
  RESET_TRANSACTION_DATE: 'RESET_TRANSACTION_DATE',
  SET_TRANSACTION_PAGE: 'SET_TRANSACTION_PAGE',
  SET_TRANSACTION_STATUS: 'SET_TRANSACTION_STATUS',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',

  // Beneficiary Action Types
  SET_BENEFICIARIES: 'SET_BENEFICIARIES',
  SET_BENEFICIARY: 'SET_BENEFICIARY',
  SET_BENEFICIARY_LOADING: 'SET_BENEFICIARY_LOADING',

  // Bills Action Types
  SET_BILLS_LOADING: 'SET_BILLS_LOADING',
  SET_DATA_PLAN: 'SET_DATA_PLAN',
  SET_TV_CABLE_PLAN: 'SET_TV_CABLE_PLAN',
  SET_ELECTRICITY_TYPES: 'SET_ELECTRICITY_TYPES',

  // Collections Action Types
  SET_COLLECTION_LOADING: 'SET_COLLECTION_LOADING',
  SET_COLLECTION_VENDORS: 'SET_COLLECTION_VENDORS',
  SET_COLLECTION_INVOICE: 'SET_COLLECTION_INVOICE',

  // Devices Action Types
  SET_DEVICE_LOADING: 'SET_DEVICE_LOADING',
  SET_MY_DEVICES: 'SET_MY_DEVICES',
  SET_MY_DEVICE: 'SET_MY_DEVICE',

  // Self Care Action Types
  SET_ISSUE: 'SET_ISSUE',
  SET_ISSUES: 'SET_ISSUES',
  RESET_ISSUES: 'RESET_ISSUES',
  SET_ISSUE_ACTIONS: 'SET_ISSUE_ACTIONS',
});
