import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'src/skins/common/components';
import { TextInput } from 'src/skins/jupiter/components/inputs';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { formatToDigits } from 'src/utils/helpers';
import { validateAuthToken } from 'src/store/actions/userActions';

const OtpInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin: 2rem auto 3rem auto;

  input.input {
    text-align: center;
    font-size: x-large;
  }
`;

const AccountVerificationModal = ({ isOpen, closeModal, userData }) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = data => {
    const { otp0, otp1, otp2, otp3, otp4 } = data;
    const payload = {
      otp: +`${otp0}${otp1}${otp2}${otp3}${otp4}`,
      phone: userData.phoneNumber,
      platform: userData.platform,
    };
    dispatch(
      validateAuthToken(payload, success => {
        return success && history.push('/set-password', payload);
      })
    );
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h5 className="color-primary">Enter OTP</h5>

        <br />

        <OtpInputsWrapper>
          <div>
            <TextInput
              type="text"
              name="otp0"
              ref={register}
              inputmode="numeric"
              pattern="[0-9]*"
              required
              onInput={e => {
                e.target.value = formatToDigits(e.target.value, 1);
                if (e.target.checkValidity()) {
                  const nextInput = document.querySelector('input[name="otp1"]');
                  nextInput.focus();
                }
              }}
            />
          </div>
          <div>
            <TextInput
              type="text"
              name="otp1"
              ref={register}
              inputmode="numeric"
              pattern="[0-9]*"
              required
              onInput={e => {
                e.target.value = formatToDigits(e.target.value, 1);
                if (e.target.checkValidity()) {
                  const nextInput = document.querySelector('input[name="otp2"]');
                  nextInput.focus();
                }
              }}
              onKeyUp={e => {
                if (e.which === 8) {
                  const prevInput = document.querySelector('input[name="otp0"]');
                  prevInput.focus();
                }
              }}
            />
          </div>
          <div>
            <TextInput
              type="text"
              name="otp2"
              ref={register}
              inputmode="numeric"
              pattern="[0-9]*"
              required
              onInput={e => {
                e.target.value = formatToDigits(e.target.value, 1);
                if (e.target.checkValidity()) {
                  const nextInput = document.querySelector('input[name="otp3"]');
                  nextInput.focus();
                }
              }}
              onKeyUp={e => {
                if (e.which === 8) {
                  const prevInput = document.querySelector('input[name="otp1"]');
                  prevInput.focus();
                }
              }}
            />
          </div>
          <div>
            <TextInput
              type="text"
              name="otp3"
              ref={register}
              inputmode="numeric"
              pattern="[0-9]*"
              required
              onInput={e => {
                e.target.value = formatToDigits(e.target.value, 1);
                if (e.target.checkValidity()) {
                  const nextInput = document.querySelector('input[name="otp4"]');
                  nextInput.focus();
                }
              }}
              onKeyUp={e => {
                if (e.which === 8) {
                  const prevInput = document.querySelector('input[name="otp2"]');
                  prevInput.focus();
                }
              }}
            />
          </div>
          <div>
            <TextInput
              type="text"
              name="otp4"
              ref={register}
              inputmode="numeric"
              pattern="[0-9]*"
              required
              onInput={e => {
                e.target.value = formatToDigits(e.target.value, 1);
              }}
              onKeyUp={e => {
                if (e.which === 8) {
                  const prevInput = document.querySelector('input[name="otp3"]');
                  prevInput.focus();
                }
              }}
            />
          </div>
        </OtpInputsWrapper>

        <br />
        <br />

        <Button type="submit" text="Submit" size="large" />
      </form>
    </Modal>
  );
};

AccountVerificationModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

/**
 * AccountVerificationModal PropType Definition
 * @typedef {Object} AccountVerificationModalProps
 * @property {boolean} isOpen
 * @property {object} userData
 * @property {Function} closeModal
 */
AccountVerificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

export { AccountVerificationModal };
