import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { VenusModal } from 'src/skins/venus/components/common';
import { Link } from 'react-router-dom';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import iconUserCircle from 'src/skins/venus/assets/svgs/icon-user-circle.svg';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;

  .profile-menu-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 14px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-size: 13px;
    line-height: 13px;
    color: ${props => props.theme.color.black};
    text-decoration: none;
    cursor: pointer;
    background-color: ${props => props.theme.color.white};
    outline: none;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid ${props => props.theme.color.success};
      transition: 0.3s;
    }

    & > * {
      margin: 0.5rem 0;
    }
  }
`;
export const Profile = () => {
  const history = useHistory();
  const profileMenu = [
    { title: 'ID Verification', url: '/dashboard/settings/profile/verification', icon: iconUserCircle },
  ];

  const profileMenuMap = profileMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="profile-menu-item" key={index} onClick={menu.onClick && menu.onClick}>
        <img src={menu.icon} alt="..." />
        <span>{menu.title || ''}</span>
      </Link>
    );
  });

  return (
    <DashboardLayout>
      <VenusModal
        isOpen={true}
        closeModal={() => history.replace('/dashboard/settings')}
        fullheight={true}
        backgroundPage="settings"
      >
        <Wrapper>
          <h4>Profile</h4>
          <br />
          {profileMenuMap}
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default Profile;
