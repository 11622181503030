import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'src/skins/common/components';
import { TextInput } from 'src/skins/mars/components/inputs';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { MarsButton } from 'src/skins/mars/components/common';

const Wrapper = styled.div`
  .divider {
    width: 100%;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .isLoading {
    pointer-events: none;
  }
`;

/**
 * @description TransactionSummaryModal Component
 * @param {RequestBvnModalProps} props
 */
const RequestBvnModal = props => {
  const { isOpen, closeModal, clickIsLoading, handleConfirmTransactionClick } = props;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      bvn: '',
    },
  });

  const onSubmit = data => handleConfirmTransactionClick(data.bvn);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="text-center">Enter BVN</h5>

          <br />
          <br />

          <TextInput
            label="Enter BVN"
            type="text"
            name="bvn"
            placeholder="e.g. 0123456789"
            ref={register({ required: 'BVN is required' })}
            errors={<React.Fragment>{errors.bvn && <p className="error">{errors.bvn.message}</p>}</React.Fragment>}
          />

          <br />

          <MarsButton type="submit" size="large" text="Submit" isLoading={clickIsLoading} />
        </form>
      </Wrapper>
    </Modal>
  );
};

RequestBvnModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  clickIsLoading: false,
  handleConfirmTransactionClick: null,
};

/**
 * Set
 *RequestBvnModal PropType Definition
 * @typedef {Object} RequestBvnModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {boolean} clickIsLoading
 * @property {(pin:string) => {}} handleConfirmTransactionClick
 */
RequestBvnModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  clickIsLoading: PropTypes.bool.isRequired,
  handleConfirmTransactionClick: PropTypes.func.isRequired,
};

export { RequestBvnModal };
