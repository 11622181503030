import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/skins/common/components';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/skins/common/icon';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { getVendors } from 'src/store/actions/collectionActions';

const pageTitle = 'Collection Vendors';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 1rem 0;

    h6 {
      color: ${props => props.theme.color.darkGray};
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .collections-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    max-width: 100%;
    margin: 2rem 0;

    & > button {
      display: inline-block;
      padding: 0;
      background: transparent;
      color: inherit;
      border: 0;
      outline: none;
      font-size: 0.7rem;
      border: 1px dashed rgba(74, 85, 85, 0.2);
      border-radius: 10px;

      & > div {
        box-shadow: none;
        border-radius: 10px;
      }

      &[active],
      &:hover,
      &:focus {
        border-color: transparent;
        background: radial-gradient(86.21% 274.44% at 9.77% 11.24%, #f3f6ff 0%, #ffffff 50%, #f7f9ff 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      }
    }

    .action-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 5.5rem;
      padding: 0.5rem;
      font-size: 0.7rem;

      img {
        height: 2rem;
      }

      &:hover {
        cursor: pointer;

        img {
          opacity: 0.8;
        }
      }
    }
  }

  .tab-menu {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(3, 1fr);
    background-color: #eaedf3;
    border-radius: 10px;

    & > * {
      display: inline-block;
      width: calc(- 0.5rem);
      background: transparent;
      color: ${props => props.theme.color.darkGray};
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 3.5em;
      margin: 0.25rem;
      font-weight: bold;

      &:hover,
      &:focus,
      &[active] {
        cursor: pointer;
        background: linear-gradient(107.36deg, #f3f6ff 3.57%, #ffffff 52.66%, #f7f9ff 101.76%);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .tab-content {
    margin-top: 1rem;
  }
`;

const Collections = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { vendors, isLoading } = useSelector(state => state.COLLECTION);

  useEffect(() => {
    if (vendors.length < 1) {
      dispatch(getVendors());
    }
  }, []);

  // TODO: implement search through vendors
  // const [search, setSearch] = useState('');
  // const [filteredVendors, setFilteredVendors] = useState([]);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="tab-content">
            <div className="collections-grid">
              {isLoading.getVendors && <p>Loading...</p>}
              {!isLoading.getVendors && vendors.length === 0 && <p>no vendors yet, check back later</p>}
              {!isLoading.getVendors &&
                vendors.map((item, index) => (
                  <button key={index} onClick={() => history.replace(`/dashboard/collections/${item.vendorCode}`)}>
                    <Card className="action-card">
                      {item.vendorLogo ? (
                        <img src={item.vendorLogo} alt={item.vendorName} />
                      ) : (
                        <p>{item.vendorName ? item.vendorName.toUpperCase() : item.vendorCode}</p>
                      )}
                    </Card>
                  </button>
                ))}
            </div>
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Collections;
