import React from 'react';
import styled from 'styled-components';
import { DashboardLayout, InnerPageHeader } from 'src/skins/jupiter/components/layouts';
import { Link } from 'react-router-dom';
import RightChevronIcon from 'src/assets/svgs/icon-right-chevron.svg';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;
  .profile-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    border: 0;
    border-bottom: 1px solid ${props => props.theme.color.gray};
    color: ${props => props.theme.color.gray};
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    width: 100%;

    & > * {
      margin: 0.5rem 0;

      &::first-child-child {
        margin-right: 0.5rem;
        font-size: large;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }
`;

export const Profile = () => {
  const profileMenu = [{ title: 'ID Verification', url: '/dashboard/settings/profile/verification' }];

  const profileMenuMap = profileMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="profile-menu-item" key={index} onClick={menu.onClick && menu.onClick}>
        <span>{menu.title || ''}</span>
        <img src={RightChevronIcon} alt="..." />
      </Link>
    );
  });
  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader navigateBackTo="/dashboard/settings">
          <h4>Profile</h4>
        </InnerPageHeader>

        {profileMenuMap}
      </Wrapper>
    </DashboardLayout>
  );
};

export default Profile;
