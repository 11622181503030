import React, { useState } from 'react';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import TransferBeneficiaries from 'src/skins/venus/components/beneficiaries/transfers/TransferBeneficiaries';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  section {
    margin: 0 0 1rem;
  }

  .tab-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > * {
      display: inline-block;
      width: 100%;
      background: transparent;
      color: ${props => props.theme.color.gray};
      border: 1px solid transparent;
      outline: none;
      border-radius: 0;
      height: 4em;
      font-weight: 600;

      &:hover,
      &:focus,
      &[active] {
        color: ${props => props.theme.color.primary};
        border-bottom: 1px solid ${props => props.theme.color.primary};
        cursor: pointer;
      }
    }
  }

  .tab-content {
    margin-top: 1rem;
  }
`;

const Transfer = () => {
  const [method, setMethod] = useState('peer');
  const history = useHistory();

  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="dashboard">
        <Wrapper>
          <section>
            <div className="tab-menu">
              <button active={method === 'peer' ? '' : null} onClick={() => setMethod('peer')}>
                P2P
              </button>
              <button active={method === 'bank' ? '' : null} onClick={() => setMethod('bank')}>
                Bank Transfer
              </button>
            </div>

            <div className="tab-content">
              <TransferBeneficiaries method={method} />
            </div>
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default Transfer;
