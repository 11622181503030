import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatToDigits } from 'src/utils/helpers';
import { Icon } from 'src/skins/common/icon';

const Wrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  input {
    min-width: 1.5rem;
    outline: 0;
    border: 0;
    background: #202021;
    color: #ffffff;
    text-align: center;
    font-size: 1.1rem;
    height: 3rem;
    width: 100%;
    border-radius: 8px;
    padding: 0.75rem 1rem;
  }
`;

/**
 * @description PinInput Component
 * @param {PinInputProps} props
 */
const PinInput = props => {
  const { name, pinLength, value, onChange } = props;
  const [isVisible, setIsVisible] = useState(false);
  const toggleIsVisible = () => setIsVisible(!isVisible);

  const onInputChange = (name, input) => {
    onChange({ ...value, [name]: input });
  };

  return (
    <Wrapper>
      {Array.from(Array(pinLength)).map((_item, index) => (
        <input
          key={`${name}${index}`}
          name={`${name}${index}`}
          type={isVisible ? 'text' : 'password'}
          value={value[index] || ''}
          required={true}
          onChange={e => {
            e.target.value = formatToDigits(e.target.value, 1);
            onInputChange(index, e.target.value);
            if (e.target.value && index < pinLength - 1) {
              const nextName = `input[name="${name}${index + 1}"]`;
              const nextInput = document.querySelector(nextName);
              nextInput.focus();
            }
          }}
          onKeyUp={e => {
            if (e.key === 'Backspace' && index > 0) {
              onInputChange(index, '');
              const prevName = `input[name="${name}${index - 1}"]`;
              const prevInput = document.querySelector(prevName);
              prevInput.focus();
            }
          }}
        />
      ))}
      <Icon size="small" className="cursor_pointer" name={isVisible ? 'eye' : 'eyeOff'} onClick={toggleIsVisible} />
    </Wrapper>
  );
};

PinInput.defaultProps = {
  value: {},
  name: 'pin',
  pinLength: 4,
  onChange: null,
};

/**
 * PinInput PropType Definition
 * @typedef {Object} PinInputProps
 * @property {string} name
 * @property {number} pinLength - default=4
 * @property {object} value
 * @property {(value:object) => {}} onChange
 */
PinInput.propTypes = {
  name: PropTypes.string.isRequired,
  pinLength: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export { PinInput };
