import { SET_DEVICE_LOADING, SET_MY_DEVICES, SET_MY_DEVICE } from 'src/store/types';

const initialState = {
  myDevice: {},
  myDevices: [],
  isLoading: {
    getMyDevices: false,
    updateDeviceName: false,
    updateDevicePin: false,
    getActivateDeviceSummary: false,
    activateDevice: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.action]: action.payload.state,
        },
      };
    case SET_MY_DEVICES:
      return {
        ...state,
        myDevices: action.payload,
      };
    case SET_MY_DEVICE:
      return {
        ...state,
        myDevice: action.payload,
      };
    default:
      return state;
  }
}
