import React from 'react';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import {
  AirtimeBeneficiaries,
  MobileDataBeneficiaries,
  TvCablesBeneficiaries,
  ElectricityBeneficiaries,
} from 'src/skins/pluto/components/beneficiaries/bills';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 2rem 0;
  }
`;

const title = {
  data: 'Buy Mobile Data',
  airtime: 'Buy Airtime',
  dstv: 'Buy Dstv',
  startimes: 'Buy Startimes',
  gotv: 'Buy Gotv',
  ikedc: 'Buy Electricity',
  ekedc: 'Buy Electricity',
  kedco: 'Buy Electricity',
  phed: 'Buy Electricity',
  jed: 'Buy Electricity',
  ibedc: 'Buy Electricity',
  kaedco: 'Buy Electricity',
  aedc: 'Buy Electricity',
};

const BillsBeneficiaries = () => {
  const history = useHistory();
  const billType = history.location.state.type;
  const electricityTypes = useSelector(state => state.BILLS.electricityTypes);

  const discos = electricityTypes.map(type => type.disco);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="chevronLeft" onClick={() => history.push('/dashboard/bills')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{title[billType] || ''}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          {billType === 'airtime' && <AirtimeBeneficiaries />}
          {billType === 'data' && <MobileDataBeneficiaries />}

          {['dstv', 'startimes', 'gotv'].indexOf(billType) > -1 && <TvCablesBeneficiaries />}
          {discos.indexOf(billType) > -1 && <ElectricityBeneficiaries />}
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default BillsBeneficiaries;
