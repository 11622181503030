import React from 'react';
import { Link } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import { VenusModal } from 'src/skins/venus/components/common';
import { RegisterForm, LoginForm, ForgotPasswordForm, SetPasswordForm } from 'src/skins/venus/forms';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import VenusBgEffect from 'src/assets/svgs/venus-bg-effect.svg';

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -1rem;
  padding: 1rem;
  color: ${props => props.theme.color.light};
  background: linear-gradient(
    16.84deg,
    ${props => props.theme.color.primary} -10.65%,
    ${props => props.theme.color.primary} 32.7%,
    ${props => props.theme.color.primary} 65.78%,
    ${props => props.theme.color.secondary} 98.86%
  );

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }

  .register-login-links {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    z-index: 1;

    & > a {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: ${props => props.theme.color.light};
      font-size: large;
      font-weight: 600;
      height: 72px;
      width: 100%;

      &:last-child {
        background: ${props => props.theme.color.light};
        color: ${props => props.theme.color.primary};
        box-shadow: 0px 8px 20px rgba(97, 62, 234, 0.32);
        border-radius: 50px 0 0 0;
      }
    }
  }

  .venus-bg-effect {
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('${VenusBgEffect}');
    background-position: left calc(100% + 55px);
    background-size: 175px 127px;
    background-repeat: no-repeat;
    filter: brightness(0) contrast(1);
    width: 175px;
    height: 127px;
  }
`;

const Auth = () => {
  const { mmo } = useSelector(state => state.MMO);

  return (
    <React.Fragment>
      <PageView>
        <Wrapper>
          <h2>{mmo.name}</h2>
          <p>{mmo.motto}</p>
          <div className="register-login-links">
            <Link to="/auth#register" className="text-decoration-none" style={{ width: '47%' }}>
              Register
            </Link>
            <Link to="/auth#login" className="text-decoration-none" style={{ width: '47%' }}>
              Sign In
            </Link>
          </div>
          <div className="venus-bg-effect"></div>
        </Wrapper>
      </PageView>

      <VenusModal id="register" isOpen={true}>
        <RegisterForm />
      </VenusModal>

      <VenusModal id="login" isOpen={true}>
        <LoginForm />
      </VenusModal>

      <VenusModal id="forgot" isOpen={true}>
        <ForgotPasswordForm />
      </VenusModal>

      <VenusModal id="set-password" isOpen={true}>
        <SetPasswordForm />
      </VenusModal>
    </React.Fragment>
  );
};

export default Auth;
