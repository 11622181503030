import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'src/skins/common/components/Button';
import { TextInput, CheckboxInput, SelectInput } from 'src/skins/mars/components/inputs';
import { TransactionSummaryModal, TransactionStatusModal, TransactionPinModal } from 'src/skins/mars/modals';
import { formatToDigits } from 'src/utils/helpers';
import { getBillSummary, confirmBillTransaction, getTvCablePlan } from 'src/store/actions/billActions';

const buttonText = {
  gotv: 'Buy Gotv Plan',
  dstv: 'Buy Dstv Plan',
  startimes: 'Buy Startimes Plan',
};

export const BuyTvCablesForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionSummary, setTransactionSummary] = useState({});

  const [showTvCableSummaryModal, setShowTvCableSummaryModal] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);

  const { tvCablePlan, isLoading } = useSelector(state => state.BILLS);

  const getDataPlanOptions = type => {
    if (tvCablePlan[type]) {
      return tvCablePlan[type].map(plan => {
        return { value: plan.planId, label: plan.description };
      });
    } else {
      return [];
    }
  };

  const beneficiary = history.location.state || {};
  const { type } = useParams();

  useEffect(() => {
    type && dispatch(getTvCablePlan(type));
  }, [type]);

  const toggleTvCableSummaryModal = () => setShowTvCableSummaryModal(!showTvCableSummaryModal);
  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/bills');
    }
  };

  const getAmount = planId => {
    return +tvCablePlan[type].find(plan => plan.planId === planId).amount;
  };

  const { register, handleSubmit, setValue, clearErrors, watch, control } = useForm({
    defaultValues: {
      type: beneficiary.type || '',
      name: beneficiary.name || '',
      cardNumber: beneficiary.cardNumber || '',
      saveBeneficiary: false,
      dataPlan: '',
    },
  });

  const onSubmit = data => {
    const payload = {
      amount: +data.amount,
      type: type,
      cardNumber: data.cardNumber || beneficiary.cardNumber,
      planId: data.tvCablePlan,
    };

    dispatch(
      getBillSummary(payload, 'tv-cables', data => {
        if (data) {
          setTransactionSummary(data);
          toggleTvCableSummaryModal();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmTvCableClick = pin => {
    const payload = {
      amount: +transactionSummary.amount,
      cardNumber: transactionSummary.cardNumber,
      type: type,
      planId: watch('tvCablePlan'),
      pin,
    };

    if (watch('saveBeneficiary')) {
      payload.saveBeneficiary = watch('saveBeneficiary');
      payload.name = watch('name');
    }

    dispatch(
      confirmBillTransaction(payload, 'tv-cable', (_success, error) => {
        toggleTvCableSummaryModal();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="text"
            label="Card Number"
            name="cardNumber"
            ref={register}
            icon="phone"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            disabled={beneficiary.state}
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 30);
              return e;
            }}
          />
          <Controller
            control={control}
            name="dataPlan"
            render={() => (
              <SelectInput
                label="Plan"
                name="tvCablePlan"
                ref={register}
                icon="bank"
                required
                isClearable={false}
                isLoading={isLoading.getTvCablePlan}
                options={getDataPlanOptions(type)}
                value={watch('tvCablePlan') || null}
                onChange={selectedOption => {
                  setValue('tvCablePlan', selectedOption?.value || '');
                  setValue('amount', getAmount(selectedOption?.value || ''));
                  clearErrors('tvCablePlan');
                }}
              />
            )}
          />
          <TextInput
            type="text"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              let { value } = e.target;
              value = formatToDigits(value);
              e.target.value = value.slice(0, 15);
            }}
          />
          <CheckboxInput
            label="Save as beneficiary"
            name="saveBeneficiary"
            ref={register}
            disabled={beneficiary.state}
          />

          {(watch('saveBeneficiary') || beneficiary.state) && (
            <TextInput
              type="text"
              label="Name"
              name="name"
              ref={register}
              icon="account"
              required
              disabled={beneficiary.state}
            />
          )}
        </div>

        <br />

        <Button
          type="submit"
          text={buttonText[type] || 'Buy Tv Cable Plan'}
          size="large"
          isLoading={isLoading.getBillSummary}
        />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showTvCableSummaryModal}
        closeModal={toggleTvCableSummaryModal}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          closeModal={toggleTransactionPinModalStatus}
          clickIsLoading={isLoading.confirmBillTransaction}
          handleConfirmTransactionClick={handleConfirmTvCableClick}
        />
      )}

      <TransactionStatusModal
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
        status={transactionStatus}
      />
    </>
  );
};

export default BuyTvCablesForm;
