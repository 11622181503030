import React, { useState } from 'react';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { InnerPageHeader } from 'src/skins/venus/components/aggregator';
import { formatCurrency } from 'src/utils';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  .commission-balance-card {
    margin: 1rem 0 2.5rem 0;
  }

  section {
    margin: 1rem 0;
    margin-bottom: 2rem;
  }

  .date-heading {
    display: block;
    margin: 1.25rem 0 0.75rem 0;
  }

  .transaction-details {
    & > * {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      font-size: 1rem;
      border-bottom: 1px solid rgba(196, 196, 196, 0.2);

      & > * {
        width: 100%;
      }

      & > :first-child {
        font-weight: bold;
        max-width: 20rem;
      }

      & > :last-child {
        color: ${props => props.theme.color.primary};
      }

      .color-green,
      [status='delivered'] {
        color: ${props => props.theme.color.pluto.creditColor};
        text-transform: capitalize;
      }

      [status='pending'] {
        color: ${props => props.theme.color.pluto.debitColor};
        text-transform: capitalize;
      }

      [status='cancelled'] {
        color: ${props => props.theme.color.pluto.cancelledColor};
        text-transform: capitalize;
      }
    }
  }
`;

export const AggregatorTransactionDetails = () => {
  const history = useHistory();
  const { id: transactionId } = useParams();
  const [fetchingWalletTransactions, setFetchingWalletTransactions] = useState(true);
  const [transaction] = useState(fakeTransaction);

  setTimeout(() => {
    setFetchingWalletTransactions(false);
  }, 1000);

  // eslint-disable-next-line no-console
  console.log(`find or fetch transaction using TRANSACTION_ID: ${transactionId}`);

  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="settings">
        <Wrapper>
          <InnerPageHeader>
            <h4>Transaction details</h4>
          </InnerPageHeader>

          <br />

          <div className="card card-primary">
            <div className="title">{formatCurrency(transaction.amount || 0, 'NGN')}</div>
          </div>

          <br />

          <section>
            <div>
              {fetchingWalletTransactions === true && <div className="loading-state">fetching transaction...</div>}
              {fetchingWalletTransactions === false && transaction.id && (
                <div className="transaction-details">
                  <div>
                    <span>Sender name:</span>
                    <span>{transaction.accountName || ''}</span>
                  </div>
                  <div>
                    <span>Payment narrative:</span>
                    <span>{transaction.narrative || ''}</span>
                  </div>
                  <div>
                    <span>Status:</span>
                    <span status={transaction.status || ''}>{transaction.status || ''}</span>
                  </div>
                  <div>
                    <span>My commision:</span>
                    <span className="color-green">#{formatCurrency(transaction.amount || 0, '')}.00</span>
                  </div>
                  <div>
                    <span>Transaction date:</span>
                    <span>{moment(transaction.createdAt || '').format('MMM d, Y')}</span>
                  </div>
                  <div>
                    <span>Transaction time:</span>
                    <span>{moment(transaction.createdAt || '').format('h:m:sa z')}</span>
                  </div>
                </div>
              )}
              {fetchingWalletTransactions === false && !transaction.id && (
                <div className="empty-state color-gray">Transaction Not Found</div>
              )}
            </div>
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

const fakeTransaction = {
  id: 'TRANSACTION_ID',
  accountName: 'Omadoye Jedidiah',
  narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
  status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
  cancelled: Math.random() * 10 > 8 ? true : false,
  amount: Math.round(Math.random() * 10) * 1000,
  action: Math.random() * 10 > 5 ? 'credit' : 'debit',
  createdAt: moment(new Date())
    .subtract(Math.random() * 10, 'days')
    .toDate(),
};

export default AggregatorTransactionDetails;
