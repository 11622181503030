import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MarsButton } from 'src/skins/mars/components/common';
import { TextInput } from 'src/skins/mars/components/inputs';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginUser, verifyLoginEmail } from 'src/store/actions/userActions';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [showSend, setShowSend] = useState(true);
  const { accountIsValid, isLoading } = useSelector(state => state.USER);
  const platform = useSelector(state => state.MMO.shortCode);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    email ? setShowSend(true) : setShowSend(false);
  }, [email]);

  const validateEmail = () => {
    if (!email) {
      return setError({ type: 'required' });
    }
    dispatch(verifyLoginEmail({ email, platform }));
    setVerifiedEmail(email);
  };

  const onSubmit = data => {
    if (!(email === verifiedEmail && !!accountIsValid)) {
      return validateEmail();
    }
    data.platform = platform;
    dispatch(loginUser(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h3 style={{ marginBottom: '0.5rem' }}>Sign in</h3>
        <p>Welcome back.</p>
        <p>You have been missed!</p>
      </div>

      <br />

      <div>
        <TextInput
          type="email"
          label="Email Address"
          placeholder="Email Address"
          name="email"
          ref={register({ required: true })}
          icon="mail"
          onInput={e => {
            setEmail(e.target.value);
            return e;
          }}
          endInputName={showSend && 'send'}
          endInputIsLoading={isLoading.verifyLoginEmail}
          onEndInputClick={validateEmail}
          required={true}
          errors={<React.Fragment>{errors.email && <p className="error">Email is required</p>}</React.Fragment>}
        />

        {email === verifiedEmail && !!accountIsValid && (
          <React.Fragment>
            <TextInput
              type="password"
              label="Enter Password"
              placeholder="Password"
              name="password"
              ref={register({ required: true })}
              icon="lock"
              errors={
                <React.Fragment>{errors.password && <p className="error">Password is required</p>}</React.Fragment>
              }
            />

            <MarsButton
              type="submit"
              text="Sign In"
              size="large"
              isLoading={isLoading.loginUser || isLoading.authenticateUser}
            />
          </React.Fragment>
        )}
        {verifiedEmail && !isLoading.verifyLoginEmail && accountIsValid === false && (
          <div>
            <h5>Account is inactive</h5>
            <br />
            <MarsButton
              onClick={() => history.push('/auth#forgot')}
              type="button"
              text="Activate Account"
              size="large"
            />
          </div>
        )}
        {verifiedEmail && !isLoading.verifyLoginEmail && accountIsValid === null && (
          <div>
            <h5 className="color-red">Account not found</h5>
            <br />
            <MarsButton
              onClick={() => history.push('/auth#register')}
              type="button"
              text="Register Account"
              size="large"
            />
          </div>
        )}
      </div>

      <br />

      <div className="color-gray text-center">
        <h6>
          Don’t have an account? <Link to="/auth#register">Sign up here</Link>{' '}
        </h6>
        <br />
        <h6>
          Forgot account access? <Link to="/auth#forgot">Reset password</Link>
        </h6>
      </div>
    </form>
  );
};

export default LoginForm;
