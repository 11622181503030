import React from 'react';
import { Alert } from '@material-ui/lab';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.secondary};
  border-radius: 0.5rem;
`;

const Banner = props => {
  const { children } = props;
  return (
    <Wrapper>
      <Alert severity="error">{children}</Alert>
    </Wrapper>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
};

export default Banner;
