import React from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { InnerPageHeader, NewIssueForm } from 'src/skins/jupiter/components/support';
import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  section {
    margin: 1rem 0;
  }

  section:last-of-type {
    margin: 1rem 0;
  }

  .date-heading {
    display: block;
    margin: 1.25rem 0 0.75rem 0;
  }
`;

export const NewSupport = () => (
  <DashboardLayout>
    <Wrapper>
      <InnerPageHeader>
        <h4>Report New Issue</h4>
      </InnerPageHeader>

      <section>
        <NewIssueForm />
      </section>
    </Wrapper>
  </DashboardLayout>
);

export default NewSupport;
