import React from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { BuyTvCablesForm } from 'src/skins/jupiter/forms';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { Icon } from 'src/skins/common/icon';
import { useHistory, useParams } from 'react-router-dom';

const pageTitles = {
  dstv: 'Buy Dstv',
  startimes: 'Buy Startimes',
  gotv: 'Buy Gotv',
};

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 2rem 0;
  }
`;

const BIllsTvCables = () => {
  const history = useHistory();
  const { type } = useParams();
  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard/bills')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitles[type] || 'Buy Tv Cables'}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <BuyTvCablesForm />
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default BIllsTvCables;
