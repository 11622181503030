import React from 'react';
import { AuthLayout } from 'src/skins/pluto/components/layouts';
import { LoginForm } from 'src/skins/pluto/forms';

const Login = () => {
  return (
    <AuthLayout pageTitle="Welcome Back 👍," description="We are happy to see you again.">
      <LoginForm />
    </AuthLayout>
  );
};

Login.propTypes = {};

export default Login;
