import { APP_IS_LOADING, APP_NOT_LOADING, SET_APP_IS_ACTIVE, SET_BASE_URL } from 'src/store/types';
import { getSingleMmo, setMmoShortCode } from './mmoActions';
import { setCurrentUser } from './userActions';

export const appIsLoading = () => {
  return {
    type: APP_IS_LOADING,
  };
};

export const appNotLoading = () => {
  return {
    type: APP_NOT_LOADING,
  };
};

export const setAppIsActive = state => {
  return {
    type: SET_APP_IS_ACTIVE,
    payload: state,
  };
};

export const setBaseUrl = url => {
  return {
    type: SET_BASE_URL,
    payload: url,
  };
};

export const setShortCode = code => {
  localStorage.setItem('shortCode', code);
  return;
};

export const getShortCode = () => {
  return localStorage.getItem('shortCode');
};

export const setupApp = () => dispatch => {
  // set shortCode to local storage
  const shortCode = getShortCode('shortCode');
  dispatch(setMmoShortCode(shortCode));
  // request for mmo info and save to redux
  dispatch(getSingleMmo(shortCode));
  // verify if user is authenticated
  dispatch(setCurrentUser());
  // dispatch app done loading
  dispatch(appNotLoading());
};
