import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'src/skins/common/components/Button';
import { TextInput, CheckboxInput, SelectInput } from 'src/skins/pluto/components/inputs';
import { formatToDigits, getBankInputOptions } from 'src/utils/helpers';
import { TransactionStatusModal, TransactionSummaryModal, TransactionPinModal } from 'src/skins/pluto/modals';
import { getTransactionSummary, newTransfer, getBankList } from 'src/store/actions/transactionActions';

export const BankTransferForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.MMO.shortCode);
  const isLoading = useSelector(state => state.TRANSACTION.isLoading);

  const [banks, setBanks] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionSummary, setTransactionSummary] = useState({});
  const [showTransferSummaryModal, setShowTransferSummaryModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);

  const beneficiary = history.location.state || {};

  const toggleTransferSummary = () => setShowTransferSummaryModal(!showTransferSummaryModal);
  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  useEffect(() => {
    dispatch(getBankList(data => data && setBanks(data)));
  }, []);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/transfer');
    }
  };

  const { register, handleSubmit, setValue, clearErrors, control, watch } = useForm({
    defaultValues: {
      bank: beneficiary.bank || '',
      name: beneficiary.name || '',
      accountNumber: beneficiary.accountNumber || '',
      saveBeneficiary: false,
    },
  });

  const onSubmit = data => {
    const payload = {
      platform,
      amount: +data.amount,
      accountBank: data.bank || beneficiary.bank,
      accountNumber: data.accountNumber || beneficiary.accountNumber,
    };

    dispatch(
      getTransactionSummary(payload, 'bank', data => {
        if (data) {
          setTransactionSummary(data);
          toggleTransferSummary();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmTransferClick = pin => {
    const payload = {
      platform,
      amount: transactionSummary.amount,
      accountNumber: transactionSummary.accountNumber,
      accountBank: transactionSummary.bank,
      accountName: transactionSummary.name,
      narration: watch('narration'),
      pin,
    };

    if (watch('saveBeneficiary')) {
      payload.saveBeneficiary = watch('saveBeneficiary');
      payload.name = watch('name');
    }

    dispatch(
      newTransfer(payload, 'bank', (_success, error) => {
        toggleTransferSummary();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            control={control}
            name="bank"
            render={() => (
              <SelectInput
                label="Bank Name"
                name="bank"
                ref={register}
                icon="bank"
                required
                isClearable={false}
                isLoading={isLoading.getBankList}
                disabled={beneficiary.state}
                options={getBankInputOptions(banks || [])}
                value={watch('bank') || null}
                onChange={selectedOption => {
                  setValue('bank', selectedOption?.value || '');
                  clearErrors('bank');
                }}
              />
            )}
          />
          <TextInput
            type="text"
            label="Account Number"
            name="accountNumber"
            ref={register}
            icon="accountNumber"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            disabled={beneficiary.state}
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 10);
            }}
          />
          <TextInput
            type="text"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 15);
            }}
          />
          <TextInput type="text" label="Narration" name="narration" ref={register} />
          <CheckboxInput
            label="Save as beneficiary"
            name="saveBeneficiary"
            ref={register}
            disabled={beneficiary.state}
          />
          {(watch('saveBeneficiary') || beneficiary.state) && (
            <TextInput
              type="text"
              label="Name"
              name="name"
              ref={register}
              icon="account"
              required
              disabled={beneficiary.state}
            />
          )}
        </div>

        <br />

        <Button type="submit" text="Transfer money" size="large" isLoading={isLoading.getTransactionSummary} />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showTransferSummaryModal}
        closeModal={toggleTransferSummary}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          clickIsLoading={isLoading.newTransfer}
          closeModal={toggleTransactionPinModalStatus}
          handleConfirmTransactionClick={handleConfirmTransferClick}
        />
      )}

      <TransactionStatusModal
        status={transactionStatus}
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
      />
    </>
  );
};

export default BankTransferForm;
