import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'src/skins/common/components';
import { MarsButton } from 'src/skins/mars/components/common';
import { SelectInput } from 'src/skins/mars/components/inputs';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

const FilterInputsWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;

  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;

    & input {
      max-width: 9rem;
    }
  }
`;

const FilterTransactionsModal = ({ isOpen, closeModal, filterTransactions, myDevices, terminalId }) => {
  const { handleSubmit, control, setValue, getValues, errors, clearErrors } = useForm();
  const onSubmit = data => {
    if (Object.entries(data).length) filterTransactions(data);
  };

  const devicesOptions = myDevices.map(device => ({
    label: device.deviceName,
    value: device._id,
  }));
  devicesOptions.unshift({ label: 'All Devices', value: '' });
  const defaultDevice = devicesOptions.find(option => option.value === terminalId) || '';
  const deviceValue = devicesOptions.find(x => x.value === getValues('terminal_id')) || '';
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <center>
          <h4>Filter by</h4>
        </center>

        <br />

        <FilterInputsWrapper>
          <Controller
            control={control}
            name="terminal_id"
            defaultValue=""
            render={() => (
              <SelectInput
                label="Device"
                name="terminal_id"
                options={devicesOptions}
                onChange={selectedOption => {
                  setValue('terminal_id', selectedOption?.value || '');
                  clearErrors('terminal_id');
                }}
                value={deviceValue || defaultDevice || undefined}
                placeholder="Select device"
                errors={
                  <React.Fragment>
                    {errors.terminal_id && <p className="error">{errors.terminal_id.message}</p>}
                  </React.Fragment>
                }
              />
            )}
          />
        </FilterInputsWrapper>

        <br />

        <MarsButton type="submit" text="Filter" size="large" />
      </form>
    </Modal>
  );
};

FilterTransactionsModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  myDevices: [],
  terminalId: '',
  status: '',
  type: '',
};

/**
 * FilterTransactionsModal PropType Definition
 * @typedef {Object} FilterTransactionsModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {Function} filterTransactions
 * @property {Array} myDevices
 * @property {String} terminalId
 */
FilterTransactionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  filterTransactions: PropTypes.func,
  myDevices: PropTypes.array,
  terminalId: PropTypes.string,
};

export { FilterTransactionsModal };
