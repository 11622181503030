import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/skins/common/components';
import { TextInput } from 'src/skins/jupiter/components/inputs';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData } from 'src/store/actions/collectionActions';

const vendorCode = 'imc';

const InvoiceSearch = () => {
  const dispatch = useDispatch();
  const { invoice, isLoading } = useSelector(state => state.COLLECTION);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      invoiceNumber: invoice.invoiceNumber || '',
    },
  });

  const onSubmit = data => {
    dispatch(getInvoiceData(data.invoiceNumber, vendorCode));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <TextInput
        label="Invoice Number"
        type="text"
        name="invoiceNumber"
        placeholder="Invoice Number"
        ref={register({ required: 'Invoice number is required' })}
        errors={
          <React.Fragment>
            {errors.invoiceNumber && <p className="error">{errors.invoiceNumber.message}</p>}
          </React.Fragment>
        }
        readOnly={!!invoice.invoiceNumber}
      />

      {!invoice.invoiceNumber && (
        <React.Fragment>
          <br />
          <Button type="submit" text="Look Up" size="large" isLoading={isLoading.getInvoiceData} />
        </React.Fragment>
      )}
    </form>
  );
};

export default InvoiceSearch;
