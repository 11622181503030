import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Icon } from 'src/skins/common/icon';
import { VenusButton } from 'src/skins/venus/components/common';
import { useDispatch } from 'react-redux';
import { infoAlert } from 'src/store/actions/alertActions';
import { formatCurrency, copyToClipboard } from 'src/utils';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  width: 100%;
`;

export const TransactionDetails = props => {
  const { transactionDetails } = props;
  const {
    // _id,
    // platform,
    amount,
    // userId,
    card,
    transactionRef,
    walletBalance,
    terminalId,
    type,
    status,
    // platformFee,
    // ikonikFee,
    timeCreated,
    billingType,
    meta,
    // user,
    // email,
    receiver,
    additionalInformation,
    // narration,
    totalCharge,
  } = transactionDetails || {};

  const dispatch = useDispatch();
  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  const displayItem = (name, value) => (
    <div className="data-flex">
      <span className="black bold">{name}</span>
      {value}
    </div>
  );

  return (
    <Wrapper>
      <div className="transaction-details">
        {type && displayItem('Type: ', <span>{type || ''}</span>)}

        {billingType && displayItem('Billing Type: ', <span>{billingType || ''}</span>)}

        {timeCreated &&
          displayItem('Date: ', <span>{timeCreated ? moment(timeCreated).format('Do MMM, YYYY') : ''}</span>)}

        {timeCreated && displayItem('Time: ', <span>{timeCreated ? moment(timeCreated).format('h:mm a') : ''}</span>)}

        {status &&
          displayItem(
            'Status: ',
            <span className={status.includes('SUCCESS') ? 'green' : 'red'}>
              {status ? status.replace(/\b(\S)/g, t => t.toUpperCase()).replace(/_/g, ' ') : ''}
            </span>
          )}

        {amount &&
          displayItem(
            'Amount: ',
            <span className={amount >= 0 ? 'green' : 'red'}>
              {amount ? formatCurrency(amount).replace('.00', '') : ''}
            </span>
          )}

        {totalCharge &&
          displayItem('Charges: ', <span>{totalCharge ? formatCurrency(totalCharge).replace('.00', '') : ''}</span>)}

        {card && displayItem('Card: ', <span>{card || ''}</span>)}

        {meta && meta.accountBank && displayItem('Bank Account: ', <span>{meta.accountBank || ''}</span>)}
        {meta && meta.accountNumber && displayItem('Account Number: ', <span>{meta.accountNumber || ''}</span>)}
        {meta && meta.meterNumber && displayItem('Metre Number: ', <span>{meta.meterNumber || ''}</span>)}
        {meta && meta.cardNumber && displayItem('Card Number: ', <span>{meta.cardNumber || ''}</span>)}
        {meta && meta.productCode && displayItem('Plan ', <span>{meta.productCode || ''}</span>)}
        {additionalInformation &&
          additionalInformation.token &&
          displayItem('Token ', <span>{additionalInformation.token || ''}</span>)}
        {additionalInformation &&
          additionalInformation.units &&
          displayItem('Units ', <span>{additionalInformation.units || ''}</span>)}

        {receiver && displayItem('Account Name: ', <span>{receiver || ''}</span>)}

        {walletBalance &&
          displayItem(
            'Wallet Balance: ',
            <span>{walletBalance ? formatCurrency(walletBalance).replace('.00', '') : ''}</span>
          )}
        {terminalId && displayItem('Terminal ID ', <span>{terminalId || ''}</span>)}

        {transactionRef &&
          displayItem(
            'Transaction Reference: ',
            <span>
              <span>{transactionRef || ''}</span>
              <span className="copy-wrapper">
                <Icon name="copy" size="small" onClick={() => copyToClipboard('transactionRef', copiedAlert)} />
              </span>
            </span>
          )}

        {transactionRef && (
          <input
            id={'transactionRef'}
            type="text"
            value={transactionRef || ''}
            onChange={null}
            readOnly
            style={hiddenInputStyle}
          />
        )}

        {acceptedIssueTypeCategory[type] && transactionRef && amount && (
          <React.Fragment>
            <br />
            <br />
            <br />

            <Link
              to={`/dashboard/support/new?category=${acceptedIssueTypeCategory[type]}&transactionRef=${transactionRef}&amount=${amount}`}
              as="button"
            >
              <VenusButton size="large" text="+ Report An Issue" />
            </Link>
          </React.Fragment>
        )}

        {transactionRef && (
          <React.Fragment>
            <br />
            <a href={`print-receipt:${transactionRef}`} role="button" as="button">
              <VenusButton size="large" text="Print Receipt" />
            </a>

            <br />
            <a href={`share-receipt:${transactionRef}`} role="button" as="button">
              <VenusButton size="large" text="Share Receipt" />
            </a>
          </React.Fragment>
        )}
      </div>
    </Wrapper>
  );
};

const acceptedIssueTypeCategory = {
  PEER: 'TRANSFER',
  BILL: 'BILLS',
  WALLET_TOPUP: 'WALLET',
  CASHOUT: 'TRANSFER',
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '-2rem' };

TransactionDetails.defaultPropTypes = {
  transactionDetails: {},
};

/**
 * BillTransactionDetails PropType Definition
 * @typedef {Object} BillTransactionDetailsProps
 * @property {Object} transactionDetails
 */
TransactionDetails.propTypes = {
  transactionDetails: PropTypes.object.isRequired,
};

export default TransactionDetails;
