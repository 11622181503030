import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/skins/common/components';
import { TextInput } from 'src/skins/pluto/components/inputs';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceData, confirmInvoice, getCollectionSummary } from 'src/store/actions/collectionActions';
import { CollectionSummaryModal, TransactionStatusModal, TransactionPinModal } from 'src/skins/pluto/modals';
import InvoiceSearch from './InvoiceSearch';

const vendorCode = 'imc';

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

const ImcCollectionForm = () => {
  const [showCollectionSummaryModal, setShowCollectionSummaryModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);

  const [summary, setSummary] = useState({});
  const [transactionStatus, setTransactionStatus] = useState('');

  const toggleCollectionSummaryModal = () => setShowCollectionSummaryModal(!showCollectionSummaryModal);

  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/collections');
    }
  };

  useEffect(() => {
    dispatch(setInvoiceData({}));
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const { invoice, isLoading } = useSelector(state => state.COLLECTION);

  const { register, handleSubmit, setValue, errors, reset } = useForm({
    defaultValues: {
      name: '',
      description: '',
      amount: '',
    },
  });

  useEffect(() => {
    invoice.invoiceNumber && setValue('invoiceNumber', invoice.invoiceNumber);
    invoice.name && setValue('name', invoice.name);
    invoice.description && setValue('description', invoice.description);
    invoice.amount && setValue('amount', invoice.amount);
  }, [invoice]);

  const onSubmit = data => {
    if (invoice.invoiceData) {
      invoice.invoiceData = invoice.invoiceData.map(dataInput => ({
        ...dataInput,
        value: data[dataInput.name] || '',
      }));
    }
    dispatch(setInvoiceData({ ...invoice, amount: data.amount }));

    const summaryPayload = {
      amount: +data.amount,
      invoiceNumber: invoice.invoiceNumber,
      vendorCode,
    };

    dispatch(
      getCollectionSummary(summaryPayload, data => {
        setSummary({ ...invoice, charges: data.charges });
        toggleCollectionSummaryModal();
        return;
      })
    );
  };

  const onConfirmCollectionClick = pin => {
    const data = { vendorCode, pin };
    dispatch(
      confirmInvoice(data, (_success, error) => {
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        dispatch(setInvoiceData({}));
        reset({});
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <Wrapper>
      <InvoiceSearch />
      {invoice.invoiceNumber && (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div style={{ height: 'fit-content', overflow: 'hidden' }}>
            <TextInput
              label="Invoice Name"
              type="text"
              name="name"
              placeholder="Invoice Name"
              ref={register({ required: 'Invoice name is required' })}
              errors={<React.Fragment>{errors.name && <p className="error">{errors.name.message}</p>}</React.Fragment>}
              readOnly={!!invoice.name}
            />

            <TextInput
              label="Invoice Description"
              type="text"
              name="description"
              placeholder="Invoice Description"
              ref={register({ required: 'Invoice description is required' })}
              errors={
                <React.Fragment>
                  {errors.description && <p className="error">{errors.description.message}</p>}
                </React.Fragment>
              }
              readOnly={!!invoice.description}
            />

            <TextInput
              label="Invoice Amount"
              type="text"
              name="amount"
              placeholder="Invoice Amount"
              ref={register({ required: 'Invoice amount is required' })}
              errors={
                <React.Fragment>{errors.amount && <p className="error">{errors.amount.message}</p>}</React.Fragment>
              }
              inputmode="numeric"
              readOnly={!!invoice.amount}
            />

            {invoice.invoiceData &&
              invoice.invoiceData.map((dataInput, index) => (
                <TextInput
                  key={index}
                  label={dataInput.label}
                  type={dataInput.inputType}
                  name={dataInput.name}
                  placeholder={dataInput.label}
                  defaultValue={dataInput.value || ''}
                  ref={register({ required: `${dataInput.label} is required` })}
                  errors={
                    <React.Fragment>
                      {errors[dataInput.name] && <p className="error">{errors[dataInput.name].message}</p>}
                    </React.Fragment>
                  }
                />
              ))}

            <br />

            <Button type="submit" text="Proceed" size="large" isLoading={isLoading?.getCollectionSummary} />
          </div>
        </form>
      )}

      <CollectionSummaryModal
        invoice={summary}
        isOpen={showCollectionSummaryModal}
        closeModal={toggleCollectionSummaryModal}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      <TransactionPinModal
        isOpen={transactionPinModalStatus}
        clickIsLoading={isLoading?.confirmInvoice}
        closeModal={toggleTransactionPinModalStatus}
        handleConfirmTransactionClick={onConfirmCollectionClick}
      />

      <TransactionStatusModal
        status={transactionStatus}
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
      />
    </Wrapper>
  );
};

export { ImcCollectionForm };
