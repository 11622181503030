import React from 'react';
import { AuthLayout } from 'src/skins/pluto/components/layouts';
import { ForgotPasswordForm } from 'src/skins/pluto/forms';

const ForgotPassword = () => {
  return (
    <AuthLayout pageTitle="Forgot Password" description="Don’t feel bad, it happens to the best of us">
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
