import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TransactionHistoryListItem } from './TransactionHistoryListItem';
import { Icon } from 'src/skins/common/icon';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 80px - 40px - 4rem);
  overflow: hidden;

  .transaction-history-content {
    height: 100%;
    overflow-y: auto;

    &[recent-transactions] {
      max-height: calc(100vh - 80px - 40px - 540px);
    }
  }

  .loading-state,
  .empty-state {
    display: flex;
    height: 15vh;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    a {
      color: ${props => props.theme.color.primary};
    }
  }

  .data-state {
    margin: 1rem auto;
  }

  .pagination {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;

    .select-page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid transparent;
      border-radius: 8px;
      text-align: center;
      position: relative;
      z-index: 1;
      height: 3rem;
      background: #202021;
      color: ${props => props.theme.color.white};

      select {
        width: 100%;
        height: 100%;
        font-size: 1.4em;
        appearance: none;
        outline: none;
        border: 0;
        border-radius: 8px;
        padding: 0 1rem;
        z-index: 1;
        background: transparent;
        color: ${props => props.theme.color.white};
      }

      .select-icon {
        transform: rotate(90deg);
        position: absolute;
        right: 1rem;
        z-index: -1;
      }
    }

    .button {
      font-size: 1.2em;
    }
  }
`;

export const TransactionHistoryContent = ({ recentTransactions, noOfPages, page, setPage, clearFilters }) => {
  const { transactions, isLoading } = useSelector(state => state.TRANSACTION);

  const previousPageClick = () => {
    page > 1 && setPage(page - 1);
  };

  const nextPageClick = () => {
    page < noOfPages && setPage(page + 1);
  };

  return (
    <Wrapper>
      <div className="transaction-history-content" recent-transactions={recentTransactions ? '' : null}>
        {isLoading.getTransactions && <div className="loading-state">fetching transactions...</div>}
        {!isLoading.getTransactions && transactions.data.length > 0 && (
          <div className="data-state">
            {transactions.data.map((item, index) => (
              <TransactionHistoryListItem transaction={item} key={index} />
            ))}
          </div>
        )}
        {!isLoading.getTransactions && transactions.data.length === 0 && (
          <div className="empty-state color-gray">
            <div>No Transactions Found</div>
            {!recentTransactions && clearFilters && (
              <div>
                <Link to="#" as="button" onClick={clearFilters}>
                  Clear Filters
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {!isLoading.getTransactions && transactions.data.length > 0 && recentTransactions && (
        <React.Fragment>
          <br />
          <div className="text-right">
            <Link to="/dashboard/transactions" as="button">
              View more ⮕
            </Link>
          </div>
        </React.Fragment>
      )}

      {!isLoading.getTransactions && !recentTransactions && (
        <div className="pagination">
          <center>
            <button type="button" className="mars--button-circle rotate-180" onClick={previousPageClick}>
              ⮕
            </button>
          </center>
          <div className="select-page">
            <select name="page" value={page} onChange={e => setPage(+e.target.value)}>
              {new Array(noOfPages).fill(0).map((page, index) => (
                <option value={index + 1} key={index + 1}>
                  {`${index + 1} of ${noOfPages} ${noOfPages > 1 ? 'Pages' : 'Page'}`}
                </option>
              ))}
            </select>
            <div className="select-icon">
              <Icon size="small" name="chevronRight" />
            </div>
          </div>
          <center>
            <button type="button" className="mars--button-circle" onClick={nextPageClick}>
              ⮕
            </button>
          </center>
        </div>
      )}
    </Wrapper>
  );
};

TransactionHistoryContent.defaultProps = {
  recentTransactions: false,
  noOfPages: 1,
  page: 1,
};

/**
 * TransactionHistoryContent PropType Definition
 * @typedef {Object} TransactionHistoryContentProps
 * @property {boolean} recentTransactions
 * @property {Number} noOfPages
 * @property {Number} page
 * @property {Function} setPage
 * @property {Function} clearFilters
 */
TransactionHistoryContent.propTypes = {
  recentTransactions: PropTypes.bool,
  noOfPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  clearFilters: PropTypes.func,
};

export default TransactionHistoryContent;
