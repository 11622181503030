import React from 'react';
import { AuthLayout } from 'src/skins/pluto/components/layouts';
import { SetPasswordForm } from 'src/skins/pluto/forms';

const SetPassword = () => {
  return (
    <AuthLayout pageTitle="Set Account Password" description="Please use  secure password">
      <SetPasswordForm />
    </AuthLayout>
  );
};

SetPassword.propTypes = {};

export default SetPassword;
