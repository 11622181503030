import React, { useState } from 'react';
import { MarsButton } from 'src/skins/mars/components/common';
import { TextInput } from 'src/skins/mars/components/inputs';
import { formatToDigits } from 'src/utils/helpers';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  TransactionStatusModal,
  TransactionSummaryModal,
  TransactionPinModal,
  TransactionOtpModal,
} from 'src/skins/mars/modals';
import { useSelector, useDispatch } from 'react-redux';
import { getThirdPartyWithdrawalSummary, requestThirdPartyWithdrawal } from 'src/store/actions/transactionActions';

export const ThirdPartyForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.TRANSACTION.isLoading);

  const [transactionSummary, setTransactionSummary] = useState({});
  const [otp, setOtp] = useState('');
  const [showTransferSummaryModal, setShowTransferSummaryModal] = useState(false);
  const [transactionOtpModalStatus, setTransactionOtpModalStatus] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');

  const toggleTransferSummary = () => setShowTransferSummaryModal(!showTransferSummaryModal);
  const toggleTransactionOtpModalStatus = () => setTransactionOtpModalStatus(!transactionOtpModalStatus);
  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    setTransactionPinModalStatus(false);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard');
    }
  };

  const { register, handleSubmit, getValues } = useForm();

  const onSubmit = data => {
    const payload = {
      amount: data.amount,
      customerIuwi: data.customerIuwi,
    };

    dispatch(
      getThirdPartyWithdrawalSummary(payload, data => {
        if (data) {
          setTransactionSummary(data);
          toggleTransferSummary();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmOtpClick = otp => {
    setOtp(otp);
    toggleTransactionOtpModalStatus(); // closes otp modal
    toggleTransactionPinModalStatus(); // opens pin modal
    // TODO: Verify OTP before presentinng Pin modal
  };

  const handleConfirmTransferClick = pin => {
    const amount = getValues('amount');
    const customerIuwi = getValues('customerIuwi');
    const payload = {
      amount,
      customerIuwi,
      otp,
      pin,
    };

    dispatch(
      requestThirdPartyWithdrawal(payload, (_success, error) => {
        toggleTransferSummary();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="text"
            placeholder="Customer Wallet ID"
            label="Customer Wallet ID"
            name="customerIuwi"
            ref={register}
            icon="mail"
            required
          />
          <TextInput
            type="text"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 15);
              return e;
            }}
          />
        </div>

        <br />

        <MarsButton
          size="large"
          type="submit"
          text="Withdraw money"
          isLoading={isLoading.getThirdPartyWithdrawalSummary}
        />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showTransferSummaryModal}
        closeModal={toggleTransferSummary}
        handleConfirmTransferClick={toggleTransactionOtpModalStatus}
        beneficiaryAsBenefactor
      />

      {transactionOtpModalStatus && (
        <TransactionOtpModal
          isOpen={transactionOtpModalStatus}
          closeModal={toggleTransactionOtpModalStatus}
          handleConfirmTransactionClick={handleConfirmOtpClick}
        />
      )}

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          clickIsLoading={isLoading.requestThirdPartyWithdrawal}
          closeModal={toggleTransactionPinModalStatus}
          handleConfirmTransactionClick={handleConfirmTransferClick}
        />
      )}

      <TransactionStatusModal
        status={transactionStatus}
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
      />
    </>
  );
};

export default ThirdPartyForm;
