import React, { useState } from 'react';
import styled from 'styled-components';
import { DashboardLayout, InnerPageHeader } from 'src/skins/jupiter/components/layouts';
import { Button } from 'src/skins/common/components';
import { RadioInput, PhotoUpload } from 'src/skins/jupiter/components/inputs';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;

  .mb-5 {
    margin-bottom: 5px;
    font-weight: 100;
    color: ${props => props.theme.color.darkGray};
  }
`;

export const IdVerification = () => {
  const agentIdentificationOptions = [
    { value: 'driversLicense', label: 'Driver\'s License' },
    { value: 'nin', label: 'NIN' },
    { value: 'internationalPassport', label: 'International Passport' },
  ];
  const [agentIdentification, setAgentIdentification] = useState(agentIdentificationOptions[0].value);

  const [idImages, setIdImages] = useState(null);
  const [utilityBill, setUtilityBill] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // eslint-disable-next-line no-console
    console.log('submitted form data: ', Object.fromEntries(formData));
  };

  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader navigateBackTo="/dashboard/settings/profile">
          <h4>ID Verification</h4>
        </InnerPageHeader>

        <form onSubmit={handleSubmit} autoComplete="off">
          <p className="mb-5">Proof of Identification</p>
          <RadioInput
            options={agentIdentificationOptions}
            value={agentIdentification}
            onChange={setAgentIdentification}
            name="proofOfId"
          />
          <br />
          <PhotoUpload
            name="idImage"
            files={idImages}
            onChange={e => setIdImages(e.target.files)}
            placeholder="Upload ID"
          />
          <br />
          <br />
          <p className="mb-5">Proof of Address</p>
          <PhotoUpload
            name="utilityBill"
            files={utilityBill}
            onChange={e => setUtilityBill(e.target.files)}
            placeholder="Upload Utility Bill"
          />
          <br />
          <br />
          <Button type="submit" text="Submit" size="large" />
        </form>
      </Wrapper>
    </DashboardLayout>
  );
};

export default IdVerification;
