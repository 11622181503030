import React from 'react';
import { Modal as AppModal } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalWrapper = styled(AppModal)`
  border: none;
`;

const Wrapper = styled.div`
  .modal {
    position: absolute;
    background-color: ${props => props.theme.color.light};
    border: none;
    max-width: 20rem;
    min-width: 20rem;
    padding: 1.5rem;
    border-radius: 8px;
  }
`;

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
  };
}

/**
 * @description Modal Component
 * @param {ModalProps} props
 */
const Modal = props => {
  const { isOpen, closeModal, children } = props;
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <ModalWrapper open={isOpen} onClose={closeModal} disableBackdropClick={false}>
      <Wrapper>
        <div style={modalStyle} className="modal">
          {children}
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

Modal.defaultProps = {
  isOpen: false,
};

/**
 * Modal PropType Definition
 * @typedef {Object} ModalProps
 * @property {React} children
 * @property {boolean} isOpen
 * @property {Function} closeModal
 */
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export { Modal };
