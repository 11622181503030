export const copyToClipboard = (elementId, callback = null) => {
  const inputElementToCopy = document.getElementById(elementId);
  if (!inputElementToCopy) return;
  inputElementToCopy.select();
  document.execCommand('copy');
  // expects copiedAlert as callback which takes the value of text just copied
  if (callback) callback(inputElementToCopy.value);
};

export default copyToClipboard;
