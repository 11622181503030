import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { VenusModal, VenusButton } from 'src/skins/venus/components/common';
import { RadioInput, PhotoUpload } from 'src/skins/venus/components/inputs';
import { DashboardLayout } from 'src/skins/venus/components/layouts';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;

  .mb-5 {
    margin-bottom: 5px;
    font-weight: 100;
    color: ${props => props.theme.color.darkGray};
  }
`;

export const IdVerification = () => {
  const history = useHistory();

  const agentIdentificationOptions = [
    { value: 'driversLicense', label: 'Driver\'s License' },
    { value: 'nin', label: 'NIN' },
    { value: 'internationalPassport', label: 'International Passport' },
  ];
  const [agentIdentification, setAgentIdentification] = useState(agentIdentificationOptions[1].value);

  const [idImages, setIdImages] = useState(null);
  const [utilityBill, setUtilityBill] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // eslint-disable-next-line no-console
    console.log('submitted form data: ', Object.fromEntries(formData));
  };

  return (
    <DashboardLayout>
      <VenusModal
        isOpen={true}
        closeModal={() => history.replace('/dashboard/settings/profile')}
        fullheight={true}
        backgroundPage="settings"
      >
        <Wrapper>
          <form onSubmit={handleSubmit} autoComplete="off">
            <h4>ID Verification</h4>
            <br />
            <p className="mb-5">Proof of Identification</p>

            <RadioInput
              name="proofOfId"
              options={agentIdentificationOptions}
              value={agentIdentification}
              onChange={setAgentIdentification}
            />
            <br />
            <p className="mb-5">ID</p>
            <PhotoUpload
              name="idImage"
              files={idImages}
              onChange={e => setIdImages(e.target.files)}
              placeholder="Upload ID"
            />
            <br />
            <hr style={{ margin: '0.5em -28px' }} />
            <br />
            <br />
            <p className="mb-5">Proof of Address</p>
            <PhotoUpload
              name="utilityBill"
              files={utilityBill}
              onChange={e => setUtilityBill(e.target.files)}
              placeholder="Upload Utility Bill"
            />
            <br />
            <br />
            <VenusButton type="submit" text="Submit" size="large" />
          </form>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default IdVerification;
