import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { VenusButton } from 'src/skins/venus/components/common';
import { TextInput } from 'src/skins/venus/components/inputs';
import TvCablesBeneficiaryItem from './beneficiaryItems/TvCablesBeneficiaryItem';
import { getTvCablesBeneficiaries } from 'src/store/actions/beneficiaryActions';

const Wrapper = styled.div`
  .loading-state,
  .empty-state {
    display: flex;
    height: 15vh;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
  }
`;

const buttonText = {
  dstv: 'New Dstv Beneficiary',
  startimes: 'New Startimes Beneficiary',
  gotv: 'New Gotv Beneficiary',
};

const TvCablesBeneficiaries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const billType = history.location.state.type;

  const [search, setSearch] = useState('');

  const [currentBeneficiaries, setCurrentBeneficiaries] = useState({});
  const { beneficiaries, isLoading } = useSelector(state => state.BENEFICIARY);

  useEffect(() => {
    dispatch(getTvCablesBeneficiaries(billType));
  }, [billType]);

  useEffect(() => {
    !isLoading.getBeneficiaries && setCurrentBeneficiaries(beneficiaries);
  }, [isLoading.getBeneficiaries]);

  const filterBeneficiaries = search => {
    if (search.length === 0) return setCurrentBeneficiaries(beneficiaries);

    const foundByName = beneficiaries.filter(x => x.name.toLowerCase().includes(search.trim().toLowerCase()));
    const foundByCardNumber = beneficiaries.filter(x =>
      String(x.cardNumber).toLowerCase().includes(search.trim().toLowerCase())
    );

    const result = Array.from(new Set([...foundByName, ...foundByCardNumber]));

    setCurrentBeneficiaries(result);
  };

  return (
    <Wrapper>
      <div>
        <TextInput
          type="text"
          label="Search saved beneficiary"
          placeholder="Enter beneficiary name"
          name="search"
          icon="search"
          required
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            filterBeneficiaries(e.target.value);
          }}
        />
      </div>

      <br />

      {isLoading.getBeneficiaries && <div className="loading-state">fetching beneficiaries...</div>}
      {!isLoading.getBeneficiaries && currentBeneficiaries.length > 0 && (
        <>
          {currentBeneficiaries.map((item, index) => (
            <TvCablesBeneficiaryItem
              beneficiary={item}
              key={index}
              onClick={() =>
                history.push(`/dashboard/bills/tv-cables/${billType}`, {
                  name: item.name,
                  cardNumber: item.cardNumber,
                  type: item.type,
                  state: true,
                })
              }
            />
          ))}
        </>
      )}
      {!isLoading.getBeneficiaries && currentBeneficiaries.length === 0 && (
        <div className="empty-state color-gray">No Beneficiaries Found</div>
      )}

      <br />

      <VenusButton
        size="large"
        text={buttonText[billType] || 'New Tv Cable Beneficiary'}
        onClick={() => history.push(`/dashboard/bills/tv-cables/${billType}`)}
      />
    </Wrapper>
  );
};

export { TvCablesBeneficiaries };
