import React, { useState } from 'react';
import { Button } from 'src/skins/common/components';
import { TextInput, SelectInput } from 'src/skins/pluto/components/inputs';
import { useForm, Controller } from 'react-hook-form';
import { AccountVerificationModal } from 'src/skins/pluto/modals';
import { getBankInputOptions, formatToDigits, formatDate } from 'src/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { createUser } from 'src/store/actions/userActions';

export const RegisterForm = () => {
  const [userData, setUserData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const platform = useSelector(state => state.MMO.shortCode);
  const isLoading = useSelector(state => state.USER.isLoading);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      accountBank: '',
    },
  });

  const onSubmit = data => {
    data.dob = formatDate(data.dob);
    data.platform = platform;
    data.phoneNumber = +data.phoneNumber;
    dispatch(createUser(data, toggleModal));
    data && setUserData(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="text"
            label="First Name"
            name="firstName"
            ref={register({ required: true })}
            icon="account"
            errors={
              <React.Fragment>{errors.firstName && <p className="error">First name is required</p>}</React.Fragment>
            }
          />

          <TextInput
            type="text"
            label="Last Name"
            name="lastName"
            ref={register({ required: true })}
            icon="account"
            errors={
              <React.Fragment>{errors.lastName && <p className="error">Last name is required</p>}</React.Fragment>
            }
          />
          <TextInput
            type="email"
            label="Email Address"
            name="email"
            ref={register({ required: true })}
            icon="mail"
            errors={<React.Fragment>{errors.email && <p className="error">Email is required</p>}</React.Fragment>}
          />
          <TextInput
            type="text"
            label="Home Address"
            name="homeAddress"
            ref={register({ required: true })}
            icon="locationOn"
            errors={
              <React.Fragment>{errors.homeAddress && <p className="error">Address is required</p>}</React.Fragment>
            }
          />
          <TextInput
            type="tel"
            label="Phone Number"
            name="phoneNumber"
            ref={register({ required: true })}
            icon="phone"
            inputmode="numeric"
            pattern="[0-9]*"
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 11);
            }}
            errors={
              <React.Fragment>{errors.phoneNumber && <p className="error">Phone number is required</p>}</React.Fragment>
            }
          />
          <TextInput
            type="date"
            label="Date Of Birth"
            name="dob"
            ref={register({ required: true })}
            icon="calendar"
            errors={<React.Fragment>{errors.dob && <p className="error">Date of birth is required</p>}</React.Fragment>}
          />
          <TextInput
            type="text"
            label="Account Number"
            name="accountNumber"
            ref={register({ required: true })}
            icon="accountNumber"
            inputmode="numeric"
            pattern="[0-9]*"
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 10);
            }}
            errors={
              <React.Fragment>
                {errors.accountNumber && <p className="error">Account Number is required</p>}
              </React.Fragment>
            }
          />
          <Controller
            control={control}
            name="accountBank"
            defaultValue=""
            rules={{ required: true }}
            render={() => (
              <SelectInput
                label="Bank Name"
                name="accountBank"
                icon="bank"
                options={getBankInputOptions()}
                onChange={selectedOption => {
                  setValue('accountBank', selectedOption?.value || '');
                  clearErrors('accountBank');
                }}
                errors={
                  <React.Fragment>
                    {errors.accountBank && <p className="error">Bank Name is required</p>}
                  </React.Fragment>
                }
              />
            )}
          />
        </div>

        <br />

        <Button type="submit" text="Next" size="large" isLoading={isLoading.createUser} />
      </form>

      <AccountVerificationModal userData={userData} isOpen={modalIsOpen} closeModal={toggleModal} />
    </>
  );
};

export default RegisterForm;
