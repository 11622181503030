import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'src/skins/common/components';
import styled from 'styled-components';
import { formatCurrency } from 'src/utils';

const Wrapper = styled.div`
  position: relative;
  margin: -1.5rem;

  .transfer-summary-title {
    color: ${props => props.theme.color.primary};
    font-weight: 500;
    padding: 1.5rem;
  }

  .transfer-summary {
    margin: -1rem 0 1.25rem 0;
    padding: 0 1.5rem;
    color: ${props => props.theme.color.black};

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      margin-bottom: 0.75rem;

      & > :first-child {
        justify-self: flex-start;
        font-weight: 500;
      }

      & > :last-child {
        justify-self: flex-end;
        text-align: right;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  button {
    width: 100%;
    height: 4em;
    background: transparent;
    color: ${props => props.theme.color.primary};
    font-size: 1.2rem;
    border: 0;
    outline: none;
    appearance: none;
    cursor: pointer;
  }
  .isLoading {
    pointer-events: none;
  }
`;

/**
 * @description TransactionSummaryModal Component
 * @param {TransactionSummaryModalProps} props
 */
const TransactionSummaryModal = props => {
  const { isOpen, closeModal, transfer, clickIsLoading, handleConfirmTransferClick, beneficiaryAsBenefactor } = props;

  const displayItem = (name, value) => (
    <div>
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <h5 className="transfer-summary-title">Transaction Summary</h5>

        <br />

        <div className="transfer-summary">
          {transfer.description && displayItem('Description: ', transfer.description)}
          {beneficiaryAsBenefactor ? (
            <>
              {transfer.name && displayItem('Benefactor Name: ', transfer.name)}
              {transfer.beneficiary && displayItem('Benefactor Name: ', transfer.beneficiary)}
            </>
          ) : (
            <>
              {transfer.name && displayItem('Beneficiary Name: ', transfer.name)}
              {transfer.beneficiary && displayItem('Beneficiary Name: ', transfer.beneficiary)}
            </>
          )}
          {transfer.phone && displayItem('Phone Number: ', transfer.phone)}
          {transfer.cardNumber && displayItem('Card Number: ', transfer.cardNumber)}
          {transfer.meterNumber && displayItem('Meter Number: ', transfer.meterNumber)}
          {transfer.meterType && displayItem('Meter Type: ', transfer.meterType.toUpperCase())}
          {transfer.disco && displayItem('Disco: ', transfer.disco.toUpperCase())}
          {transfer.network && displayItem('Network: ', transfer.network)}
          {transfer.service && displayItem('Service: ', transfer.service.toUpperCase())}
          {beneficiaryAsBenefactor ? (
            <>
              {transfer.bank && displayItem('Benefactor Bank: ', transfer.bank)}
              {transfer.accountNumber && displayItem('Benefactor Account Number: ', transfer.accountNumber)}
              {transfer.transferId && displayItem('Benefactor TransferID: ', transfer.transferId)}
            </>
          ) : (
            <>
              {transfer.bank && displayItem('Beneficiary Bank: ', transfer.bank)}
              {transfer.accountNumber && displayItem('Beneficiary Account Number: ', transfer.accountNumber)}
              {transfer.transferId && displayItem('Beneficiary TransferID: ', transfer.transferId)}
            </>
          )}
          {transfer.amount && displayItem('Amount: ', formatCurrency(transfer.amount || 0, 'NGN'))}
          {transfer.totalCharges && displayItem('Total Charges: ', formatCurrency(transfer.totalCharges || 0, 'NGN'))}
          {transfer.walletBalance &&
            displayItem('Wallet Balance: ', formatCurrency(transfer.walletBalance || 0, 'NGN'))}
          {transfer.charges && displayItem('Transfer Charge: ', formatCurrency(transfer.charges || 0, 'NGN'))}
          {transfer.convenienceCharge &&
            displayItem('Charges: ', formatCurrency(transfer.convenienceCharge || 0, 'NGN'))}
        </div>

        <div className="divider"></div>

        <button onClick={handleConfirmTransferClick} className={clickIsLoading ? 'isLoading' : ''}>
          {clickIsLoading ? <Spinner theme="primary" /> : 'Confirm Transaction'}
        </button>
      </Wrapper>
    </Modal>
  );
};

TransactionSummaryModal.defaultProps = {
  transfer: {},
  isOpen: false,
  closeModal: null,
  clickIsLoading: false,
  handleConfirmTransferClick: null,
  beneficiaryAsBenefactor: false,
};

/**
 * TransactionSummaryModal PropType Definition
 * @typedef {Object} TransactionSummaryModalProps
 * @property {boolean} isOpen
 * @property {boolean} clickIsLoading
 * @property {Function} closeModal
 * @property {Object} transfer
 * @property {Function} handleConfirmTransferClick
 * @property {boolean} beneficiaryAsBenefactor
 */
TransactionSummaryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  transfer: PropTypes.object.isRequired,
  clickIsLoading: PropTypes.bool,
  handleConfirmTransferClick: PropTypes.func.isRequired,
  beneficiaryAsBenefactor: PropTypes.bool,
};

export { TransactionSummaryModal };
