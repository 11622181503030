import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// import { formatCurrency } from 'src/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.color.gray};
  color: ${props => props.theme.color.gray};
  padding: 0.5rem;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    border: 1px solid ${props => props.theme.color.primary};
    cursor: pointer;

    .transaction-image {
      background-color: ${props => props.theme.color.primary};
    }
  }

  .transaction-image {
    margin: 0 0.5rem;
    min-width: 2rem;
    min-height: 2rem;
    background-color: #727eae;
    border-radius: 50%;
  }

  .transaction-summary {
    margin: 0 0.5rem;
    width: calc(100% - 4rem);
  }

  .data-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & :first-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1.5rem;
    }

    & :last-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: auto;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
    }
  }

  .bold {
    font-weight: 600;
  }

  .black {
    color: ${props => props.theme.color.black};
  }

  .green {
    color: ${props => props.theme.color.success};
  }

  .red {
    color: ${props => props.theme.color.danger};
  }
`;

export const TransactionHistoryListItem = props => {
  const { transaction } = props;
  const { transactionRef, narration, amount, timeCreated, status } = transaction;

  const history = useHistory();
  const gotoTransactionDetailsPage = () => {
    history.push(`/dashboard/transactions/${transactionRef}`);
  };

  return (
    <Wrapper onClick={gotoTransactionDetailsPage}>
      <div className="transaction-image"></div>
      <div className="transaction-summary">
        <div className="data-flex bold">
          <span className="black">{narration || ''}</span>
          <span className={amount >= 0 ? 'green' : 'red'}>{amount}</span>
        </div>
        <div className="data-flex">
          <span>{timeCreated ? moment(timeCreated).format('D-MM-YYYY h:mm a') : ''}</span>
          <span className={status.includes('SUCCESS') ? 'green' : 'red'}>
            {status ? status.replace(/\b(\S)/g, t => t.toUpperCase()).replace(/_/g, ' ') : ''}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

TransactionHistoryListItem.defaultPropTypes = {
  transaction: {},
};

/**
 * TransactionHistoryListItem PropType Definition
 * @typedef {Object} TransactionHistoryListItemProps
 * @property {Object} transaction
 */
TransactionHistoryListItem.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionHistoryListItem;
