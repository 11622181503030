import clients, { setServiceHeader, deleteServiceHeader } from 'src/clients';
import {
  SET_USER_ACTIONS,
  SET_ACCOUNT_IS_VALID,
  SET_USER,
  SET_IS_AUTHENTICATED,
  CLEAR_SESSION,
  SET_USER_WALLET,
  SET_VIRTUAL_WALLET_REQUESTS,
} from 'src/store/types';
import { errorAlert, successAlert } from './alertActions';
import { appNotLoading } from './appActions';

const { appService, otpService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_USER_ACTIONS,
    payload: { action, state },
  };
};

export const setAuthToken = token => {
  if (token) {
    localStorage.setItem('gat', token);
    setServiceHeader(token);
  } else {
    localStorage.removeItem('gat');
    deleteServiceHeader(token);
  }
};

/**
 * Redux Action to create User
 * @param {object} newUser
 * @param {string} newUser.firstName
 * @param {string} newUser.lastName
 * @param {string} newUser.email
 * @param {string} newUser.homeAddress
 * @param {string} newUser.accountNumber
 * @param {string} newUser.accountBank
 * @param {string} newUser.platform
 * @param {'YYYY-MM-DD'} newUser.dob
 * @param {Number} newUser.phoneNumber
 * @param {() => {}} callback
 */
export const createUser = (newUser, callback) => dispatch => {
  dispatch(setIsLoading('createUser', true));
  appService
    .post('/users', newUser)
    .then(res => {
      dispatch(successAlert(res.data.message));
      dispatch(setIsLoading('createUser', false));
      callback();
    })
    .catch(err => {
      dispatch(setIsLoading('createUser', false));
      dispatch(errorAlert(err.response.data.message));
    });
};

/**
 *
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.platform
 */
export const verifyLoginEmail = data => dispatch => {
  dispatch(setIsLoading('verifyLoginEmail', true));
  dispatch({ type: SET_ACCOUNT_IS_VALID, payload: null });
  appService
    .post('/users/is-verified', data)
    .then(res => {
      dispatch({ type: SET_ACCOUNT_IS_VALID, payload: res.data.isVerified });
      dispatch(setIsLoading('verifyLoginEmail', false));
    })
    .catch(err => {
      dispatch(errorAlert(err?.response?.data?.message || 'Something went Wrong'));
      dispatch(setIsLoading('verifyLoginEmail', false));
    });
};

/**
 *
 * @param {object} tokenData
 * @param {string} tokenData.phone
 * @param {string} tokenData.platform
 * @param {(success, error) => any} callback
 */
export const validateAuthToken = (tokenData, callback) => dispatch => {
  dispatch(setIsLoading('validateAuthToken', true));
  otpService
    .post('/verify', tokenData)
    .then(res => {
      dispatch(successAlert(res.data.message));
      callback && callback(res.data.message, false);
      dispatch(setIsLoading('validateAuthToken', false));
    })
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      callback && callback(false, err.response.data);
      dispatch(setIsLoading('validateAuthToken', false));
    });
};

export const setCurrentUser = () => dispatch => {
  const token = localStorage.getItem('gat');
  if (token) {
    setAuthToken(token);
    dispatch(authenticateUser());
  }
};

const setUserWallet = wallet => {
  return {
    type: SET_USER_WALLET,
    payload: wallet,
  };
};

const setVirtualWalletRequests = virtualWalletRequests => {
  return {
    type: SET_VIRTUAL_WALLET_REQUESTS,
    payload: virtualWalletRequests,
  };
};

export const getUserWallet = () => dispatch => {
  dispatch(setIsLoading('getUserWallet', true));
  appService
    .get('/users/wallet')
    .then(res => {
      dispatch(setUserWallet(res.data));
      dispatch(setIsLoading('getUserWallet', false));
    })
    .catch(() => {
      dispatch(errorAlert('Something went wrong. Refresh App'));
      dispatch(setIsLoading('getUserWallet', false));
    });
};

export const createVirtualWallet = (postData, callbacks) => dispatch => {
  dispatch(setIsLoading('createVirtualWallet', postData?.vendor || true));
  appService
    .post('/users/create-virtual-wallet', postData)
    .then(res => {
      if (res?.data?.wallet) {
        dispatch(setUserWallet(res.data.wallet));
        dispatch(successAlert(res.data.message));
        if (callbacks?.success) callbacks.success();
      } else {
        dispatch(errorAlert(res?.data?.message));
        if (callbacks?.error) callbacks.error();
      }
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        dispatch(errorAlert(err?.response?.data?.message));
      }
    }).finally(() => dispatch(setIsLoading('createVirtualWallet', false)));
};

export const getVirtualWalletRequests = () => dispatch => {
  dispatch(setIsLoading('getVirtualWalletRequests', true));
  appService
    .get('/users/virtual-wallet-requests')
    .then(res => dispatch(setVirtualWalletRequests(res?.data?.data || [])))
    .catch(() => null)
    .finally(() => dispatch(setIsLoading('getVirtualWalletRequests', false)));
};

export const convertUserToAgent = () => dispatch => {
  dispatch(setIsLoading('convertUserToAgent', true));
  appService
    .get('/users/switch')
    .then(res => dispatch(successAlert(res.data.message)))
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      dispatch(setIsLoading('convertUserToAgent', false));
    });
};

/**
 * Authenticates a user
 */
const authenticateUser = () => dispatch => {
  dispatch(setIsLoading('authenticateUser', true));
  appService
    .get('/users/me')
    .then(res => {
      dispatch({ type: SET_USER, payload: res.data });
      dispatch({ type: SET_IS_AUTHENTICATED, payload: true });
      dispatch(setIsLoading('authenticateUser', false));
      dispatch(appNotLoading());
    })
    .catch(() => dispatch(setIsLoading('authenticateUser', false)));
};

/**
 *
 * @param {object} data
 * @param {number} data.otp
 * @param {string} data.password
 * @param {string} data.platform
 * @param {number} data.phoneNumber
 */
export const setPassword = data => dispatch => {
  dispatch(setIsLoading('setPassword', true));
  appService
    .post('/users/set-password', data)
    .then(res => {
      setAuthToken(res.data.gat);
      dispatch(successAlert(res.data.message));
      dispatch(authenticateUser());
      dispatch(setIsLoading('setPassword', false));
    })
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      dispatch(setIsLoading('setPassword', false));
    });
};

/**
 * Redux Action to login a user
 * @param {object} data
 * @param {string} user.pin
 * @param {string} user.password
 * @param {string} user.platform
 */
export const loginUser = user => dispatch => {
  dispatch(setIsLoading('loginUser', true));
  appService
    .post('/users/authenticate', user)
    .then(res => {
      setAuthToken(res.data.gat);
      dispatch(authenticateUser());
      dispatch(setIsLoading('loginUser', false));
    })
    .catch(err => {
      dispatch(setIsLoading('loginUser', false));
      dispatch(errorAlert(err?.response?.data?.message || 'Authentication failed'));
    });
};

/**
 * Set user transaction pin
 * @param {object} data
 * @param {string} date.pin
 * @param {string} date.password
 * @param {(true) => any} callback
 */
export const setTransactionPin = (data, callback) => dispatch => {
  dispatch(setIsLoading('setTransactionPin', true));
  appService
    .post('/users/create-pin', data)
    .then(res => {
      dispatch(successAlert(res.data.message));
      callback(res.data.message, null);
      dispatch(setIsLoading('setTransactionPin', false));
    })
    .catch(err => {
      dispatch(setIsLoading('setTransactionPin', false));
      dispatch(errorAlert(err.response.data.message));
    });
};

/**
 * Redux Action to initiate a Password Reset
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.platform
 * @param {(success, error) => any} callback
 */
export const requestForgot = (phoneNumber, callback) => dispatch => {
  dispatch(setIsLoading('requestForgot', true));
  otpService
    .get(`phone/${phoneNumber}`)
    .then(res => {
      dispatch(successAlert(res.data.message));
      callback && callback(res.data.message, false);
      dispatch(setIsLoading('requestForgot', false));
    })
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      callback && callback(false, err.response.data.message);
      dispatch(setIsLoading('requestForgot', false));
    });
};

export const logoutUser = () => dispatch => {
  const authToken = localStorage.getItem('gat');
  if (authToken) {
    appService
      .get('/users/logout')
      .then(() => {
        setAuthToken(false);
        dispatch({ type: CLEAR_SESSION });
        dispatch(appNotLoading());
      })
      .catch(() => {
        setAuthToken(false);
        dispatch({ type: CLEAR_SESSION });
        dispatch(appNotLoading());
      });
  } else {
    setAuthToken(false);
    dispatch({ type: CLEAR_SESSION });
    dispatch(appNotLoading());
  }
};

/**
 * Goto Yawo
 */
export const gotoYawo = closeModal => {
  const gat = localStorage.getItem('gat');
  window.location = `${process.env.REACT_APP_YAWO_APP_URL}?gat=${gat}`;
  if (closeModal) closeModal();
};
