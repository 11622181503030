import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'src/skins/common/components';
import { TextInput } from 'src/skins/jupiter/components/inputs';
import ElectricityBeneficiaryItem from './beneficiaryItems/ElectricityBeneficiaryItem';
import { getElectricityBeneficiaries } from 'src/store/actions/beneficiaryActions';

const Wrapper = styled.div`
  .loading-state,
  .empty-state {
    display: flex;
    height: 15vh;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
  }

  .transfer-divider {
    border: 2px solid rgba(166, 170, 180, 0.2);
    margin: 1rem 0;
  }
`;

const ElectricityBeneficiaries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const billType = history.location.state.type;

  const [search, setSearch] = useState('');

  const [currentBeneficiaries, setCurrentBeneficiaries] = useState({});
  const { beneficiaries, isLoading } = useSelector(state => state.BENEFICIARY);

  useEffect(() => {
    dispatch(getElectricityBeneficiaries());
  }, []);

  useEffect(() => {
    !isLoading.getBeneficiaries && setCurrentBeneficiaries(beneficiaries);
  }, [isLoading.getBeneficiaries]);

  const filterBeneficiaries = search => {
    if (search.length === 0) return setCurrentBeneficiaries(beneficiaries);

    const foundByName = beneficiaries.filter(x => x.name.toLowerCase().includes(search.trim().toLowerCase()));
    const foundByMeterNumber = beneficiaries.filter(x =>
      String(x.meterNumber).toLowerCase().includes(search.trim().toLowerCase())
    );

    const foundByMeterType = beneficiaries.filter(x =>
      String(x.meterType).toLowerCase().includes(search.trim().toLowerCase())
    );

    const result = Array.from(new Set([...foundByName, ...foundByMeterNumber, ...foundByMeterType]));

    setCurrentBeneficiaries(result);
  };

  return (
    <Wrapper>
      <Button
        size="large"
        text="New Electricity Beneficiary"
        onClick={() => history.push(`/dashboard/bills/electricity/${billType}`)}
      />

      <div className="transfer-divider"></div>

      <div>
        <TextInput
          type="text"
          placeholder="Search saved beneficiary"
          name="search"
          icon="search"
          required
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            filterBeneficiaries(e.target.value);
          }}
        />
      </div>

      <br />

      {isLoading.getBeneficiaries && <div className="loading-state">fetching beneficiaries...</div>}
      {!isLoading.getBeneficiaries && currentBeneficiaries.length > 0 && (
        <>
          {currentBeneficiaries.map((item, index) => (
            <ElectricityBeneficiaryItem
              beneficiary={item}
              key={index}
              onClick={() =>
                history.push(`/dashboard/bills/electricity/${billType}`, {
                  name: item.name,
                  meterType: item.meterType,
                  meterNumber: item.meterNumber,
                  type: item.type,
                  state: true,
                })
              }
            />
          ))}
        </>
      )}
      {!isLoading.getBeneficiaries && currentBeneficiaries.length === 0 && (
        <div className="empty-state color-gray">No Beneficiaries Found</div>
      )}
    </Wrapper>
  );
};

export { ElectricityBeneficiaries };
