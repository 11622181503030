import React, { useState } from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import TransferBeneficiaries from 'src/skins/jupiter/components/beneficiaries/transfers/TransferBeneficiaries';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/skins/common/icon';

const pageTitle = 'Transfer';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 1rem 0;
  }

  .tab-menu {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(2, 1fr);
    background-color: #eaedf3;
    border-radius: 10px;

    & > * {
      display: inline-block;
      width: calc(- 0.5rem);
      background: transparent;
      color: ${props => props.theme.color.darkGray};
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 3.5em;
      margin: 0.25rem;
      font-weight: bold;

      &:hover,
      &:focus,
      &[active] {
        cursor: pointer;
        background: linear-gradient(107.36deg, #f3f6ff 3.57%, #ffffff 52.66%, #f7f9ff 101.76%);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .tab-content {
    margin-top: 1rem;
  }
`;

const Transfer = () => {
  const [method, setMethod] = useState('peer');
  const history = useHistory();

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="tab-menu">
            <button active={method === 'peer' ? '' : null} onClick={() => setMethod('peer')}>
              P2P
            </button>
            <button active={method === 'bank' ? '' : null} onClick={() => setMethod('bank')}>
              Bank Transfer
            </button>
          </div>

          <div className="tab-content">
            <TransferBeneficiaries method={method} />
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Transfer;
