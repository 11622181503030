import clients from 'src/clients';
import { SET_ISSUE, SET_ISSUES, RESET_ISSUES, SET_ISSUE_ACTIONS } from 'src/store/types';
import { errorAlert, successAlert } from './alertActions';

const { appService, mediaService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_ISSUE_ACTIONS,
    payload: { action, state },
  };
};

const setIssues = issuesObj => {
  return { type: SET_ISSUES, payload: issuesObj };
};

export const resetIssues = () => {
  return { type: RESET_ISSUES };
};

export const setIssue = issue => {
  return { type: SET_ISSUE, payload: issue };
};

export const getAllIssues = params => dispatch => {
  const { limit = 10, page = 1, setIssuesCallback = null } = params || {};
  dispatch(setIsLoading('getAllIssues', true));
  appService
    .get('/mmos/self-care', { params: { limit, page } })
    .then(({ data }) => {
      dispatch(setIssues(data));
      if (setIssuesCallback) setIssuesCallback(data.data || []);
      dispatch(setIsLoading('getAllIssues', false));
    })
    .catch(() => {
      dispatch(errorAlert('Server error. Please contact Admin'));
      dispatch(setIsLoading('getAllIssues', false));
    });
};

export const getSingleIssue = issue_id => dispatch => {
  dispatch(setIsLoading('getSingleIssue', true));
  appService
    .get(`/mmos/self-care/${issue_id}`)
    .then(res => {
      dispatch(setIssue(res.data));
      dispatch(setIsLoading('getSingleIssue', false));
    })
    .catch(() => {
      dispatch(errorAlert('Technical Error'));
      dispatch(setIsLoading('getSingleIssue', false));
    });
};

export const uploadIssueImage = formData => {
  mediaService
    .post('/issues/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => {
      void data;
    })
    .catch(error => {
      void error;
    });
};

export const createNewIssue = (formData, callback) => dispatch => {
  dispatch(setIsLoading('createNewIssue', true));
  appService
    .post('/mmos/self-care', formData)
    .then(({ data }) => {
      dispatch(successAlert(data.message || 'Issue Successfully Submitted'));
      const { _id: issueId } = data.data || {};
      if (issueId && formData.image) {
        const imageFormData = new FormData();
        imageFormData.append('issueId', issueId);
        imageFormData.append('image', formData.image[0], formData.image[0].filename);
        uploadIssueImage(imageFormData);
      }
      if (callback) callback();
    })
    .catch(() => {
      dispatch(errorAlert('Technical Error'));
    })
    .finally(() => {
      dispatch(setIsLoading('createNewIssue', false));
    });
};
