import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionDetails } from 'src/store/actions/transactionActions';
import { useHistory, useParams } from 'react-router-dom';
import { TransactionDetails } from './TransactionDetails';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px - 40px - 4rem);
  overflow: hidden;

  .transaction-history-content {
    height: 100%;
    overflow-y: auto;
  }

  .loading-state,
  .empty-state {
    display: flex;
    height: 15vh;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
  }

  .data-state {
    margin: 2rem auto;
  }

  .data-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;

    & > :first-child {
      display: inline-block;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1.5rem;
    }

    & > :last-child {
      display: inline-block;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: auto;
      text-align: right;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
    }
  }

  .bold {
    font-weight: 600;
  }

  .black {
    color: ${props => props.theme.color.black};
  }

  .green {
    color: ${props => props.theme.color.success};
  }

  .red {
    color: ${props => props.theme.color.mars?.danger || props.theme.color.danger};
  }

  .copy-wrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
`;

export const TransactionHistoryDetailsContent = () => {
  const history = useHistory();
  const { ref: transactionRef } = useParams() || {};
  if (!transactionRef) history.replace('/dashboard/transactions');

  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.TRANSACTION);

  const [transactionDetails, setTransactionDetails] = useState({});

  useEffect(() => {
    dispatch(
      getTransactionDetails(transactionRef, data => {
        if (data) setTransactionDetails(data);
      })
    );
  }, []);

  return (
    <Wrapper>
      <div className="transaction-history-content">
        {isLoading.getTransactionDetails && <div className="loading-state">fetching transaction details...</div>}
        {!isLoading.getTransactionDetails && transactionDetails.type && (
          <div className="data-state">
            <TransactionDetails transactionDetails={transactionDetails} />
          </div>
        )}
        {!isLoading.getTransactionDetails && !transactionDetails.type && (
          <div className="empty-state color-gray">No Transaction Details Found</div>
        )}
      </div>
    </Wrapper>
  );
};

export default TransactionHistoryDetailsContent;
