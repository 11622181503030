import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'src/skins/common/components';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  justify-content: center;
  .buttons {
    display: flex;
    justify-content: space-between;
  }
`;

const ConfirmActionModal = ({ isOpen, closeModal, actionTitle, actionMessage, onAccept, onDecline, isLoading }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <h4>{actionTitle}</h4>
        <p>{actionMessage}</p>
        <div className="buttons">
          <Button onClick={onAccept} text="Accept" isLoading={isLoading} />
          <Button onClick={onDecline} text="Close" theme="failure" />
        </div>
      </Wrapper>
    </Modal>
  );
};

ConfirmActionModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  actionTitle: '',
  actionMessage: '',
  onAccept: () => {},
  onDecline: () => {},
  isLoading: false,
};

/**
 * ConfirmActionModal PropType Definition
 * @typedef {Object} ConfirmActionModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 */
ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  actionTitle: PropTypes.string.isRequired,
  actionMessage: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export { ConfirmActionModal };
