import React, { useState } from 'react';
import { Button } from 'src/skins/common/components/Button';
import { TextInput, CheckboxInput } from 'src/skins/pluto/components/inputs';
import { formatToDigits } from 'src/utils/helpers';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { TransactionStatusModal, TransactionSummaryModal, TransactionPinModal } from 'src/skins/pluto/modals';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionSummary, newTransfer } from 'src/store/actions/transactionActions';

export const P2PTransferForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.MMO.shortCode);
  const isLoading = useSelector(state => state.TRANSACTION.isLoading);

  const [transactionSummary, setTransactionSummary] = useState({});
  const [showTransferSummaryModal, setShowTransferSummaryModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');

  const beneficiary = history.location.state || {};

  const toggleTransferSummary = () => setShowTransferSummaryModal(!showTransferSummaryModal);
  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/transfer');
    }
  };

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      iuwi: beneficiary.iuwi || '',
      name: beneficiary.name || '',
      saveBeneficiary: false,
    },
  });

  const onSubmit = data => {
    const payload = {
      platform,
      amount: +data.amount,
      receiverIuwi: data.iuwi || beneficiary.iuwi,
    };

    dispatch(
      getTransactionSummary(payload, 'peer', data => {
        if (data) {
          setTransactionSummary(data);
          toggleTransferSummary();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmTransferClick = pin => {
    const payload = {
      platform,
      amount: transactionSummary.amount,
      receiverIuwi: transactionSummary.transferId,
      pin,
    };

    if (watch('saveBeneficiary')) {
      payload.saveBeneficiary = watch('saveBeneficiary');
      payload.name = watch('name');
    }

    dispatch(
      newTransfer(payload, 'peer', (_success, error) => {
        toggleTransferSummary();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="text"
            label="Transfer ID"
            name="iuwi"
            ref={register}
            icon="mail"
            required
            disabled={beneficiary.state}
          />
          <TextInput
            type="text"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 15);
              return e;
            }}
          />
          <CheckboxInput
            label="Save as beneficiary"
            name="saveBeneficiary"
            ref={register}
            disabled={beneficiary.state}
          />
          {(watch('saveBeneficiary') || beneficiary.state) && (
            <TextInput
              type="text"
              label="Name"
              name="name"
              ref={register}
              icon="account"
              required
              disabled={beneficiary.state}
            />
          )}
        </div>

        <br />

        <Button size="large" type="submit" text="Transfer money" isLoading={isLoading.getTransactionSummary} />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showTransferSummaryModal}
        closeModal={toggleTransferSummary}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          clickIsLoading={isLoading.newTransfer}
          closeModal={toggleTransactionPinModalStatus}
          handleConfirmTransactionClick={handleConfirmTransferClick}
        />
      )}

      <TransactionStatusModal
        status={transactionStatus}
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
      />
    </>
  );
};

export default P2PTransferForm;
