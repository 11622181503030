import {
  SET_USER,
  SET_USER_ACTIONS,
  SET_ACCOUNT_IS_VALID,
  SET_IS_AUTHENTICATED,
  SET_USER_WALLET,
  SET_VIRTUAL_WALLET_REQUESTS,
} from 'src/store/types';

const initialState = {
  accountIsValid: null,
  isAuthenticated: false,
  user: {},
  wallet: {},
  virtualWalletRequests: [],
  isLoading: {
    state: false,
    loginUser: false,
    createUser: false,
    logoutUser: false,
    setPassword: false,
    getUserWallet: false,
    getVirtualWalletRequests: false,
    requestForgot: false,
    verifyLoginEmail: false,
    setTransactionPin: false,
    validateAuthToken: false,
    convertUserToAgent: false,
    createVirtualWallet: false,
    authenticateUser: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_USER_WALLET:
      return { ...state, wallet: action.payload };
    case SET_VIRTUAL_WALLET_REQUESTS:
      return { ...state, virtualWalletRequests: action.payload };
    case SET_USER_ACTIONS:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          state: action.payload.state,
          [action.payload.action]: action.payload.state,
        },
      };
    case SET_ACCOUNT_IS_VALID:
      return { ...state, accountIsValid: action.payload };
    case SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
}
