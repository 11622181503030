import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, isAuth, ...rest }) => {
  const user = useSelector(state => state.USER);

  return (
    <Route
      {...rest}
      render={props =>
        user.isAuthenticated === isAuth ? (
          <Component {...props} />
        ) : user.isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/auth" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  isAuth: true,
};

export { PrivateRoute };
