import React, { useLayoutEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import ReactSelect from 'react-select';
import { reactSelectTheme } from 'src/utils';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  position: relative;

  .select_section {
    height: 2.5rem;
    width: 100%;
    padding: 0rem 0.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
    outline: 0px;
    border-bottom: 1px solid ${props => props.theme.color.gray};
    color: ${props => props.theme.color.primary};
    transition: ease 0.5s all;
  }
  .select_icon {
    margin-top: 0.2rem;
    background: transparent;
    color: ${props => props.theme.color.gray};
    transition: ease 0.5s all;
    border: none;
    appearance: none;
    outline: none;
  }
  .select-wrapper {
    position: relative;
    height: 2.5rem;
    width: 100%;
  }
  .select-input {
    height: 2rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: 0 !important;
    outline: none !important;
    opacity: 0;
  }
  .select {
    height: 2rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    [class$='control'] {
      background: transparent;
      border: 0 !important;
      box-shadow: none !important;
    }

    [class$='ValueContainer'] {
      padding-left: 0;
      font-size: 1rem;
    }

    [class$='indicatorSeparator']:last-of-type {
      background-color: transparent;
    }

    [class$='indicatorContainer']:last-of-type {
      padding-right: 0;
    }

    [class$='menu'] {
      z-index: 2;
    }

    input#react-select-3-input {
      margin-left: 0;
      width: 100%;
      font-size: 1rem;
      min-width: 100% !important;
    }

    & * {
      outline: none !important;
    }
  }
  .label {
    color: ${props => props.theme.color.gray};
    min-width: max-content;
    font-size: 1rem;
    transform: translate(0.1rem, 1.9rem);
    display: inline-block;
    font-size: 400;
    transition: ease 0.5s all;
    z-index: -1;
    height: 22px;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & > div[with-icon] {
    .select_section {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: max-content 1fr;
      align-items: center;
    }

    .label {
      transform: translate(3.1rem, 1.9rem);
    }
  }

  & > div[show-label-on-top] {
    .select_section {
      border-bottom: 1px solid ${props => props.theme.color.primary};
    }
    .select_icon {
      color: ${props => props.theme.color.primary};
    }
    .label {
      font-size: 1em;
      transform: translate(0, 0);
      min-height: 16px;
      color: ${props => props.theme.color.primary};
    }
  }

  & > div[disabled] {
    opacity: 0.55;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;

/**
 * @description SelectInput Component
 * @param {SelectInputProps} props
 */
const SelectInput = forwardRef((props, ref) => {
  const mmo = useSelector(state => state.MMO.mmo);

  const {
    label,
    name,
    icon,
    required,
    disabled,
    onFocus,
    onBlur,
    placeholder,
    value,
    onChange,
    options,
    isClearable,
    isLoading,
    errors,
  } = props;

  const [showLabelOnTop, setShowLabelOnTop] = useState(false);
  const handleInputFocus = (onFocusCallback, e) => {
    setShowLabelOnTop(true);
    if (onFocusCallback) onFocusCallback(e);
  };
  const handleInputBlur = (onBlurCallback, e) => {
    if (e.target.value || e.target.checkValidity()) return;
    setShowLabelOnTop(false);
    if (onBlurCallback) onBlurCallback(e);
  };

  useLayoutEffect(() => {
    const thisInput = document.querySelector(`input[name="${props.name}"]`);
    if (thisInput) {
      if (thisInput.value || thisInput.checkValidity()) setShowLabelOnTop(true);
    }
  });

  return (
    <Wrapper>
      <div show-label-on-top={showLabelOnTop ? '' : null} with-icon={icon ? '' : null} disabled={disabled}>
        {label && <label className="label">{label}</label>}
        <div className="select_section" tabIndex="-1">
          {icon && (
            <span className="select_icon">
              <Icon size="small" name={icon} />
            </span>
          )}
          <div className="select-wrapper">
            {required && <input className="select-input" type="text" name={name} ref={ref} required />}
            <ReactSelect
              className="select"
              name={name}
              placeholder={placeholder}
              onFocus={e => handleInputFocus(onFocus, e)}
              onBlur={e => handleInputBlur(onBlur, e)}
              value={typeof value === 'string' ? { label: value, value } : value}
              onChange={onChange}
              options={options}
              theme={theme => reactSelectTheme(theme, mmo.primaryColor)}
              isClearable={isClearable}
              isLoading={isLoading}
              styles={{
                option: base => ({
                  ...base,
                  border: '0',
                  height: '100%',
                }),
              }}
            />
          </div>
        </div>
        {errors && errors}
      </div>
    </Wrapper>
  );
});

SelectInput.displayName = 'SelectInput';

SelectInput.defaultProps = {
  type: 'text',
  placeholder: '',
  options: [],
  isClearable: true,
  isLoading: false,
};

/**
 * SelectInput PropType Definition
 * @typedef {Object} SelectInputProps
 * @property {String} label
 * @property {String} name
 * @property {String} icon
 * @property {Object} value
 * @property {Function} onChange
 * @property {Function} onFocus
 * @property {Function} onBlur
 * @property {Boolean} required
 * @property {Boolean} disabled
 * @property {Boolean} isClearable
 * @property {Boolean} isLoading
 * @property {String} placeholder
 * @property {Array} options
 * @property {React} errors
 */
SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.any,
};

export { SelectInput };
