import {
  SET_TRANSACTION_LOADING,
  ADD_TRANSACTIONS,
  ADD_TRANSACTION,
  CLEAR_TRANSACTIONS,
  SET_TRANSACTION_DATE,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTION_STATUS,
  RESET_TRANSACTION_DATE,
} from 'src/store/types';

const initialState = {
  transactions: { data: [], page: 1 },
  transaction: {},
  isLoading: {
    state: false,
    newTransfer: false,
    getBankList: false,
    getTransaction: false,
    getTransactions: false,
    getTransactionSummary: false,
    getTransactionDetails: false,
    requestThirdPartyWithdrawal: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TRANSACTION_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.action]: action.payload.state,
        },
      };
    case ADD_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case ADD_TRANSACTION:
      return { ...state, transaction: action.payload };
    case CLEAR_TRANSACTIONS:
      return { ...state, transactions: initialState.transactions };
    case SET_TRANSACTION_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_TRANSACTION_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          start_date: null,
          end_date: null,
        },
      };
    case SET_TRANSACTION_PAGE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          page: action.payload,
        },
      };
    case SET_TRANSACTION_STATUS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          status: action.payload,
        },
      };
    default:
      return state;
  }
}
