import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';

const Wrapper = styled.div`
  background-color: #0d0d0d;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &.light {
    background-color: ${props => props.theme.color.light};
    color: ${props => props.theme.color.mars.text};
  }

  main {
    height: 100%;
    overflow-y: auto;
    padding: 1rem 1rem 0 1rem;
  }

  nav {
    width: 100%;
    background-color: #0d0d0d;
    box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 20px 20px 0px 0px;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      margin: 0;

      li {
        a {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          background: transparent;
          color: #ffffff;
          /* color: ${props => props.theme.color.gray}; */
          font-weight: 600;
          text-decoration: none;
          margin: 1rem;
          outline: none;
          line-height: 100%;
          border-radius: 8px;

          &:focus,
          &.active {
            background: #ffffff;
            /* color: ${props => props.theme.color.primary}; */
            color: #000000;
          }

          &:hover {
            /* color: ${props => props.theme.color.primary}; */
            background: #ffffff;
            color: #000000;
          }

          span:last-child {
            font-size: 10px;
          }
        }

        /* &.always-active {
					a {
					}
				} */
      }
    }
  }
`;

const DashboardLayout = ({ children, light }) => {
  return (
    <React.Fragment>
      <PageView className="no-overflow no-padding">
        <Wrapper className={`${light ? 'light' : ''}`}>
          <main>{children}</main>

          <nav>
            <ul>
              <li>
                <NavLink to="/dashboard" exact activeClassName="active">
                  <Icon size="small" name="home" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/wallet" exact activeClassName="active">
                  <Icon size="small" name="wallet" />
                </NavLink>
              </li>
              <li className="always-active">
                <Link to="#new">
                  <Icon size="large" name="plus" />
                </Link>
              </li>
              <li>
                <NavLink to="/dashboard/transactions" activeClassName="active">
                  <Icon size="small" name="list" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/settings" exact activeClassName="active">
                  <Icon size="small" name="settings" />
                </NavLink>
              </li>
            </ul>
          </nav>
        </Wrapper>
      </PageView>
    </React.Fragment>
  );
};

DashboardLayout.defaultProps = {
  children: null,
  light: false,
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
  light: PropTypes.bool,
};

export { DashboardLayout };
