import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import MarsOnboarding1 from 'src/skins/mars/assets/svgs/mars-onboarding-1.svg';
import MarsOnboarding2 from 'src/skins/mars/assets/svgs/mars-onboarding-2.svg';
import MarsOnboarding3 from 'src/skins/mars/assets/svgs/mars-onboarding-3.svg';

const Wrapper = styled.div`
  margin-bottom: 1rem;

  #onboarding-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    transition: all ease 0.5s;
    margin-bottom: 2rem;

    & > div {
      min-width: 100%;
      scroll-snap-align: center;
      transition: all ease 0.5s;

      img {
        width: 80%;
      }
    }
  }

  #onboarding-slider-nav {
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 6px;
      border-radius: 12px;
      background-color: #f4bebe;
      transition: all ease 0.5s;
      cursor: pointer;

      &.active {
        background-color: #ea7e7e;
        pointer-events: none;
        width: 48px;
      }
    }
  }
`;

const OnboardingSlider = () => {
  const { mmo } = useSelector(state => state.MMO);
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollToSlideIndex = e => {
    const newActiveIndex = parseInt(e.target.dataset.index);
    const slider = document.querySelector('#onboarding-slider');
    const sliderItems = document.querySelectorAll('#onboarding-slider > *');
    const sliderItem = sliderItems[newActiveIndex];
    if (sliderItem) {
      slider.scrollLeft = (newActiveIndex / sliderItems.length) * (slider.offsetWidth * sliderItems.length);
    }
  };

  const handleScroll = e => {
    const slider = document.querySelector('#onboarding-slider');
    const sliderItems = document.querySelectorAll('#onboarding-slider > *');
    const newActiveIndex = Math.round(
      (e.target.scrollLeft * sliderItems.length) / (slider.offsetWidth * sliderItems.length),
      0
    );
    setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    const slider = document.querySelector('#onboarding-slider');
    slider.onscroll = handleScroll;
    return () => {
      slider.onscroll = null;
    };
  }, []);

  return (
    <Wrapper>
      <div id="onboarding-slider">
        <div>
          <img src={MarsOnboarding1} alt="..." />
          <div>
            <h3 className="color-black">
              Perform your
              <br />
              basic transactions
            </h3>
            <br />
            <p className="text-normal">
              Enjoy your daily transactions
              <br />
              transfer, withdraw and Pay bills easily.
            </p>
          </div>
        </div>

        <div>
          <img src={MarsOnboarding2} alt="..." />
          <div>
            <h3 className="color-black">
              More to offer
              <br />
              with {mmo.name}
            </h3>
            <br />
            <p className="text-normal">
              Do more transaction like
              <br />
              buying Insurance, getting loans, etc.
            </p>
          </div>
        </div>

        <div>
          <img src={MarsOnboarding3} alt="..." />
          <div>
            <h3 className="color-black">
              Fast, easy
              <br />
              and secure
            </h3>
            <br />
            <p className="text-normal">
              Do your transaction as
              <br />
              fast as you want with confidence.
            </p>
          </div>
        </div>
      </div>

      <div id="onboarding-slider-nav">
        <span role="button" onClick={scrollToSlideIndex} data-index="0" className={activeIndex === 0 ? 'active' : ''} />
        <span role="button" onClick={scrollToSlideIndex} data-index="1" className={activeIndex === 1 ? 'active' : ''} />
        <span role="button" onClick={scrollToSlideIndex} data-index="2" className={activeIndex === 2 ? 'active' : ''} />
      </div>
    </Wrapper>
  );
};

export default OnboardingSlider;
