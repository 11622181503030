import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import { FilterTransactionsModal, TransactionHistoryContent } from 'src/skins/venus/components/transactions';
import { useSelector, useDispatch } from 'react-redux';
import { getMyDevices } from 'src/store/actions/deviceActions';
import { getTransactions } from 'src/store/actions/transactionActions';
import { useQuery } from 'src/utils';

const pageTitle = 'History';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  section {
    margin: 1rem 0;
  }

  .header-buttons {
    display: flex;
    align-items: center;
  }

  .filter-button {
    border: 0;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(85, 81, 81, 0.15);
    display: grid;
    place-items: center;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    background: #ffffff;

    &:focus {
      box-shadow: none;
    }
  }

  button.download-statement {
    border: 1px solid ${props => props.theme.color.primary};
    color: ${props => props.theme.color.primary};
    border-radius: 5px;
    outline: none;
    appearance: none;
    background: transparent;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
  }

  button.icon-wrapper {
    border: 0;
    outline: none;
    appearance: none;
    background: transparent;
    margin-left: 0.5rem;
  }
`;

const TransactionHistory = ({ recentTransactions }) => {
  const dispatch = useDispatch();
  const { myDevices } = useSelector(state => state.DEVICE);
  useEffect(() => {
    dispatch(getMyDevices());
  }, []);

  const [noOfPages, setNoOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [terminalId, setTerminalId] = useState(useQuery('terminal_id') || '');
  const clearFilters = () => {
    setPage(1);
    setTerminalId('');
  };
  useEffect(() => {
    const otherQueries = { terminal_id: terminalId || undefined };
    dispatch(getTransactions(page, otherQueries));
  }, [page, terminalId]);

  const { transactions } = useSelector(state => state.TRANSACTION);
  useEffect(() => {
    setNoOfPages(Math.ceil(transactions.count / transactions.limit));
  }, [transactions]);

  const [showFilterTransactionsModal, setShowFilterTransactionsModal] = useState(false);
  const openFilterTransactionsModal = () => setShowFilterTransactionsModal(true);
  const closeFilterTransactionsModal = () => setShowFilterTransactionsModal(false);
  const filterTransactions = formData => {
    closeFilterTransactionsModal();
    const { terminal_id: terminalId } = formData || {};
    setTerminalId(terminalId);
  };

  if (recentTransactions) {
    return (
      <TransactionHistoryContent
        recentTransactions={recentTransactions}
        noOfPages={noOfPages}
        page={page}
        setPage={setPage}
        terminalId={terminalId}
        clearFilters={terminalId ? clearFilters : undefined}
      />
    );
  }

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <h2>{pageTitle}</h2>
          <span className="header-buttons">
            {/* TODO: Download Transactions Statement of Account */}
            {/* <button className="download-statement">Download</button> */}
            {/* <button className="icon-wrapper">
							<span className="color-gray">
								<Icon size="small" name="search" />
							</span>
						</button> */}
            <button className="filter-button" type="button" onClick={openFilterTransactionsModal}>
              <Icon name="filter" size="small" />
            </button>
          </span>
        </div>

        <section>
          <TransactionHistoryContent
            recentTransactions={recentTransactions}
            noOfPages={noOfPages}
            page={page}
            setPage={setPage}
            terminalId={terminalId}
            clearFilters={terminalId ? clearFilters : undefined}
          />
        </section>

        <FilterTransactionsModal
          isOpen={showFilterTransactionsModal}
          closeModal={closeFilterTransactionsModal}
          filterTransactions={filterTransactions}
          myDevices={myDevices}
          terminalId={terminalId}
        />
      </Wrapper>
    </DashboardLayout>
  );
};

TransactionHistory.defaultProps = {
  recentTransactions: false,
};

/**
 * TransactionHistory PropType Definition
 * @typedef {Object} TransactionHistoryProps
 * @property {boolean} recentTransactions
 */
TransactionHistory.propTypes = {
  recentTransactions: PropTypes.bool,
};

export default TransactionHistory;
