import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/skins/common/components';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { useHistory } from 'react-router-dom';
import { billsPageData, phcnImages } from 'src/utils/data';
import { Icon } from 'src/skins/common/icon';
import {
  AirtimeBeneficiaries,
  MobileDataBeneficiaries,
  TvCablesBeneficiaries,
  ElectricityBeneficiaries,
} from 'src/skins/jupiter/components/beneficiaries/bills';

import UserIcon from 'src/assets/svgs/icon-user.svg';
import { getElectricityTypes } from 'src/store/actions/billActions';

const pageTitle = 'Bills payment';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 1rem 0;

    h6 {
      color: ${props => props.theme.color.darkGray};
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .bills-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    max-width: 100%;
    margin: 2rem 0;

    & > button {
      display: inline-block;
      padding: 0;
      background: transparent;
      color: inherit;
      border: 0;
      outline: none;
      font-size: 0.7rem;
      border: 1px dashed rgba(74, 85, 85, 0.2);
      border-radius: 10px;

      & > div {
        box-shadow: none;
        border-radius: 10px;
      }

      &[active],
      &:hover,
      &:focus {
        border-color: transparent;
        background: radial-gradient(86.21% 274.44% at 9.77% 11.24%, #f3f6ff 0%, #ffffff 50%, #f7f9ff 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      }
    }

    .action-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 5.5rem;
      padding: 0.5rem;
      font-size: 0.7rem;

      img {
        height: 2rem;
      }

      &:hover {
        cursor: pointer;

        img {
          opacity: 0.8;
        }
      }
    }
  }

  .tab-menu {
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(3, 1fr);
    background-color: #eaedf3;
    border-radius: 10px;

    & > * {
      display: inline-block;
      width: calc(- 0.5rem);
      background: transparent;
      color: ${props => props.theme.color.darkGray};
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 3.5em;
      margin: 0.25rem;
      font-weight: bold;

      &:hover,
      &:focus,
      &[active] {
        cursor: pointer;
        background: linear-gradient(107.36deg, #f3f6ff 3.57%, #ffffff 52.66%, #f7f9ff 101.76%);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .tab-content {
    margin-top: 1rem;
  }
`;

const Bills = () => {
  const [method, setMethod] = useState('recharge');
  const history = useHistory();
  const dispatch = useDispatch();
  const { electricityTypes, isLoading } = useSelector(state => state.BILLS);

  useEffect(() => {
    if (electricityTypes.length < 1) {
      dispatch(getElectricityTypes());
    }
  }, []);

  const resetHistoryStateType = () => {
    history.replace('/dashboard/bills', { type: '' });
  };
  const billType = history.location?.state?.type || '';
  const discos = electricityTypes.map(type => type.disco);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="tab-menu">
            <button
              active={method === 'recharge' ? '' : null}
              onClick={() => {
                setMethod('recharge');
                resetHistoryStateType();
              }}
            >
              Recharge
            </button>
            <button
              active={method === 'tv cable' ? '' : null}
              onClick={() => {
                setMethod('tv cable');
                resetHistoryStateType();
              }}
            >
              TV Cable
            </button>
            <button
              active={method === 'electricity' ? '' : null}
              onClick={() => {
                setMethod('electricity');
                resetHistoryStateType();
              }}
            >
              Electricity
            </button>
          </div>

          <div className="tab-content">
            {method === 'recharge' && (
              <div className="bills-grid">
                {billsPageData.recharge.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => history.replace('/dashboard/bills', { type: item.type })}
                    active={item.type === billType ? '' : null}
                  >
                    <Card className="action-card">
                      {item.imgSrc ? <img src={item.imgSrc} alt={item.name} /> : <>{item.name}</>}
                      <br />
                      <div>{item.name}</div>
                    </Card>
                  </button>
                ))}
              </div>
            )}

            {method === 'tv cable' && (
              <div className="bills-grid">
                {billsPageData.tvcables.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => history.replace('/dashboard/bills', { type: item.type })}
                    active={item.type === billType ? '' : null}
                  >
                    <Card className="action-card">
                      {item.imgSrc ? <img src={item.imgSrc} alt={item.name} /> : <>{item.name}</>}
                    </Card>
                  </button>
                ))}
              </div>
            )}

            {method === 'electricity' && (
              <div className="bills-grid">
                {isLoading.getElectricityTypes && <p>Loading...</p>}
                {!isLoading.getElectricityTypes &&
                  electricityTypes.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => history.replace('/dashboard/bills', { type: item.disco })}
                      active={item.type === billType ? '' : null}
                    >
                      <Card className="action-card">
                        {phcnImages[item.disco] ? (
                          <img src={phcnImages[item.disco]} alt={item.disco} />
                        ) : (
                          <p>{item.disco.toUpperCase()}</p>
                        )}
                      </Card>
                    </button>
                  ))}
              </div>
            )}
          </div>
        </section>

        <section>
          {billType === 'airtime' && <AirtimeBeneficiaries />}
          {billType === 'data' && <MobileDataBeneficiaries />}

          {['dstv', 'startimes', 'gotv'].indexOf(billType) > -1 && <TvCablesBeneficiaries />}
          {discos.indexOf(billType) > -1 && <ElectricityBeneficiaries />}
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Bills;
