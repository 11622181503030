import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CameraIcon from 'src/assets/svgs/camera.svg';

const PhotoUploadContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.color.white};
  border: 1px solid ${props => props.theme.color.gray};
  padding: 13px 16px;
  position: relative;
  width: 100%;
  border-radius: 10px;
  height: 43px;
  display: flex;

  &:focus-within {
    border: 1px solid ${props => props.theme.color.primary};
  }

  img {
    width: 20px;
    height: 18px;
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .fileInput__name {
    margin: 0;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 100;
    line-height: 15px;
    color: ${props => props.theme.color.jupiter.text}87;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 30px;
    overflow: hidden;
    white-space: nowrap;
  }

  &[disabled] {
    opacity: 0.55;
    pointer-events: none;
  }
`;

export const PhotoUpload = props => {
  const { files, placeholder = '', ...rest } = props;
  const file = files?.[0];

  return (
    <PhotoUploadContainer {...rest}>
      <input type="file" accept="image/*" {...props} />
      <p className="fileInput__name">{file ? file.name : placeholder}</p>
      <img alt="upload photo" src={CameraIcon} />
    </PhotoUploadContainer>
  );
};

PhotoUpload.propTypes = {
  files: PropTypes.any,
  placeholder: PropTypes.string,
};

export default PhotoUpload;
