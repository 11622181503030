import React, { useState } from 'react';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import { Button } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';
import { InnerPageHeader, TransactionItem, FilterTransactionsModal } from 'src/skins/pluto/components/aggregator';
import styled from 'styled-components';
import moment from 'moment';
import LODASH from 'lodash';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  .hi-username {
    display: flex;
    align-items: center;
    h5 {
      font-weight: 600;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;
    }
  }

  .search-form {
    display: flex;
    align-items: center;

    & > :first-child {
      width: 100%;
    }

    & > :last-child {
      width: 40px;
      height: 36px;
      margin-left: 1rem;

      button {
        border: 0;
        outline: none;
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(85, 81, 81, 0.15);
        display: grid;
        place-items: center;
        padding-top: 0.5rem;
        cursor: pointer;
        background: #ffffff;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .search-input {
    &.input-group {
      position: relative;
    }

    .input-icon {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;

      & svg {
        max-width: 20px;
        color: ${props => props.theme.color.pluto.lightGray};
      }
    }

    input {
      width: 100%;
      height: 2.5rem;
      border: 1px solid ${props => props.theme.color.pluto.lighterGray};
      padding: 0.5rem;
      padding-left: 2rem;
      padding-right: 1rem;
      border-radius: 5px;
      outline: none;

      &:hover {
        border: 1px solid ${props => props.theme.color.pluto.lightGray};
      }

      &:focus {
        border: 1px solid ${props => props.theme.color.primary};
      }

      &::placeholder {
        color: 1px solid ${props => props.theme.color.pluto.lightGray};
      }
    }
  }

  section {
    margin: 1rem 0;
  }

  section:last-of-type {
    margin: 1rem 0;
    margin-bottom: 3rem;
  }

  .date-heading {
    display: block;
    margin: 1.25rem 0 0.75rem 0;
  }

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & button {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    & > :first-child {
      margin-right: 1rem;
    }
  }
`;

export const AggregatorTransactions = () => {
  const [search, setSearch] = useState('');
  const [showFilterTransactionsModal, setShowFilterTransactionsModal] = useState(false);
  const openFilterTransactionsModal = () => setShowFilterTransactionsModal(true);
  const closeFilterTransactionsModal = () => setShowFilterTransactionsModal(false);
  const [fetchingTransactions, setFetchingTransactions] = useState(true);
  const [transactions, setTransactions] = useState(fakeTransactions);

  const filterTransactions = formData => {
    closeFilterTransactionsModal();

    let { search, status, narrative } = formData;
    search = String(search);
    status = String(status);
    narrative = String(narrative);
    if (search.length === 0) return setTransactions(fakeTransactions);

    const foundByName = fakeTransactions.filter(x => x.accountName.toLowerCase().includes(search.trim().toLowerCase()));
    const foundByAmount = fakeTransactions.filter(x =>
      String(x.amount).toLowerCase().includes(search.trim().toLowerCase())
    );
    const foundByCreatedAt = fakeTransactions.filter(x => {
      return String(x.createdAt).toLowerCase().includes(search.trim().toLowerCase());
    });
    const foundByStatus = fakeTransactions.filter(x => {
      return String(x.status).toLowerCase().includes(status.trim().toLowerCase());
    });
    const foundByNarrative = fakeTransactions.filter(x => {
      return String(x.narrative).toLowerCase().includes(narrative.trim().toLowerCase());
    });

    let setArray = [];
    if (search) setArray = [...foundByName, ...foundByAmount, ...foundByCreatedAt];
    if (status) setArray = [...foundByStatus];
    if (narrative) setArray = [...foundByNarrative];

    const result = Array.from(new Set(setArray));

    setTransactions(result);
  };

  setTimeout(() => {
    setFetchingTransactions(false);
  }, 1000);

  const sortedTransactions = LODASH.sortBy(transactions, 'createdAt');
  const reverseSortedTransactions = LODASH.reverse(sortedTransactions);
  const mappedTransactions = reverseSortedTransactions.map((transaction, index, transactions) => {
    const { createdAt } = transaction || {};
    const currentDate = createdAt;
    const previousDate = transactions[index - 1] ? transactions[index - 1].createdAt : null;
    const today = new Date();
    const yesterday = moment().subtract(1, 'days').toDate();

    if (
      (!previousDate && moment(currentDate).isSame(today, 'day')) ||
      moment(currentDate).diff(previousDate) >= moment(today).diff(yesterday) ||
      moment(currentDate).diff(previousDate) <= moment(yesterday).diff(today)
    ) {
      return (
        <div key={index}>
          <div className="date-heading">
            {moment(currentDate).isSame(today, 'day') ? (
              <h6>Today</h6>
            ) : moment(currentDate).isSame(yesterday, 'day') ? (
              <h6>Yesterday</h6>
            ) : (
              <h6>{createdAt && moment(createdAt).format('MMMM D, YYYY')}</h6>
            )}
          </div>
          <TransactionItem transaction={transaction} />
        </div>
      );
    }

    return <TransactionItem key={index} transaction={transaction} />;
  });

  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader>
          <h4>Transactions</h4>
        </InnerPageHeader>

        <form
          className="search-form"
          onSubmit={e => {
            e.preventDefault();
            filterTransactions({ search });
          }}
          noValidate
        >
          <div className="search-input input-group">
            <span className="input-icon">
              <Icon name="search" size="small" />
            </span>
            <input
              type="search"
              name="search"
              placeholder="Search transactions"
              required
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                filterTransactions({
                  search: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <button type="button" onClick={openFilterTransactionsModal}>
              <Icon name="filter" size="small" />
            </button>
          </div>
        </form>

        <section>
          {fetchingTransactions === true && <div className="loading-state">fetching transactions...</div>}
          {fetchingTransactions === false && transactions.length > 0 && <>{mappedTransactions}</>}
          {fetchingTransactions === false && transactions.length === 0 && (
            <div className="empty-state color-gray">No Transactions Found</div>
          )}
        </section>

        <section>
          {fetchingTransactions === false && transactions.length > 0 && (
            <div className="pagination-wrapper">
              <Button type="button" text="Previous" size="large" />
              <Button type="button" text="Next" size="large" />
            </div>
          )}
        </section>

        <FilterTransactionsModal
          isOpen={showFilterTransactionsModal}
          closeModal={closeFilterTransactionsModal}
          filterTransactions={filterTransactions}
        />
      </Wrapper>
    </DashboardLayout>
  );
};

const fakeTransactions = [
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: Math.random() * 10 > 5 ? 'Bank Transfer' : 'Recharge Card',
    status: Math.random() * 10 > 8 ? 'delivered' : Math.random() * 10 > 8 ? 'pending' : 'cancelled',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
];

export default AggregatorTransactions;
