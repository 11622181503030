import React from 'react';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { BankTransferForm } from 'src/skins/mars/forms';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MarsModal } from 'src/skins/mars/components/common';

const Wrapper = styled.div`
  section {
    margin: 0 0 2rem 0;
  }
`;

const BankTransfer = () => {
  const history = useHistory();
  return (
    <DashboardLayout>
      <MarsModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="dashboard">
        <Wrapper>
          <section>
            <BankTransferForm />
          </section>
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

export default BankTransfer;
