import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MarsModal } from 'src/skins/mars/components/common';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { TermsContent } from 'src/skins/common/components';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;
`;

export const Terms = () => {
  const history = useHistory();

  return (
    <DashboardLayout>
      <MarsModal
        isOpen={true}
        closeModal={() => history.replace('/dashboard/settings')}
        fullheight={true}
        backgroundPage="settings"
      >
        <Wrapper>
          <h4>Terms of use</h4>

          <br />

          <TermsContent />
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

export default Terms;
