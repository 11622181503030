import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  &[disabled] {
    opacity: 0.55;
    pointer-events: none;
  }

  label {
    position: relative;
    width: max-content;
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  .radio--styled {
    position: relative;
    border: 2px solid ${props => props.theme.color.success};
    border-radius: 100%;
    width: 20px;
    height: 20px;
    transition: border 0.25s linear;
  }

  .radio--styled::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.25s linear;
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    z-index: 6;
    width: 20px;
    height: 20px;
    left: -5px;
    top: -3px;
    cursor: pointer;
  }

  input[type='radio']:checked ~ .radio--styled::before {
    background: ${props => props.theme.color.success};
  }

  input[type='radio']:checked ~ .radio--styled {
    border: 2px solid ${props => props.theme.color.success};
  }

  &[disabled] {
    opacity: 0.55;
    pointer-events: none;
  }
`;

export const RadioInput = props => {
  const {
    direction = 'column',
    options = [],
    value: defaultValue = '',
    disabled = false,
    name,
    onChange = () => null,
  } = props;
  const [radioValue, setRadioValue] = useState(defaultValue);
  const handleChange = async e => {
    setRadioValue(() => {
      onChange(e.target.value);
      return e.target.value;
    });
  };

  return (
    <RadioContainer style={{ flexDirection: direction }} disabled={disabled}>
      {options.map(({ label, value }) => (
        <label key={value}>
          <input
            type="radio"
            name={name}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            checked={radioValue === value}
          />
          <div className="radio--styled"></div>
          <span className="input__label">{label}</span>
        </label>
      ))}
    </RadioContainer>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  direction: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
};

export default RadioInput;
