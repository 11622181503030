import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { Button } from 'src/skins/common/components';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import RightChevronIcon from 'src/assets/svgs/icon-right-chevron.svg';
// import AggregatorUserIcon from 'src/assets/svgs/icon-aggregator-user.svg';
import FacebookShareIcon from 'src/assets/svgs/icon-share-via-facebook.svg';
import WhatsAppShareIcon from 'src/assets/svgs/icon-share-via-whatsapp.svg';
import TwitterShareIcon from 'src/assets/svgs/icon-share-via-twitter.svg';
import EmailShareIcon from 'src/assets/svgs/icon-share-via-email.svg';
import { AggregatorRequestModal, ConfirmActionModal, SetTransactionPinModal } from 'src/skins/mars/modals';
import { logoutUser, convertUserToAgent } from 'src/store/actions/userActions';
import { infoAlert } from 'src/store/actions/alertActions';

const pageTitle = 'Settings';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  section {
    margin: 1rem 0;
  }

  .settings-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    border: 0;
    border-bottom: 1px solid #202021;
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    width: 100%;

    & > * {
      margin: 0.5rem 0;

      & > :first-child {
        margin-right: 0.5rem;
        color: #ffffff;
        font-size: large;
        font-weight: 500;
        text-align: left;
      }

      & > :last-child {
        margin-top: 0.5rem;
      }
    }

    img {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const Settings = () => {
  const dispatch = useDispatch();
  const [aggregatorStatus, setAggregatorStatus] = useState('');
  const [showAggregatorRequestModal, setShowAggregatorRequestModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transactionPinModalState, setTransactionPinModalState] = useState(false);

  const accountType = useSelector(state => state.USER.user.accountType);

  const toggleConfirm = () => setShowConfirmModal(!showConfirmModal);
  const toggleTransactionPinModal = () => setTransactionPinModalState(!transactionPinModalState);

  const requestToBeAnAggregator = () => {
    setAggregatorStatus('sent');
    setTimeout(() => {
      setAggregatorStatus('pending');
    }, 10000);
  };

  // const openAggregatorRequestModal = () => setShowAggregatorRequestModal(true);
  const closeAggregatorRequestModal = () => {
    setShowAggregatorRequestModal(false);
    if (aggregatorStatus === 'pending') {
      return setAggregatorStatus('successful');
    }
    setAggregatorStatus('pending');
  };

  const settingsPageMenu = [
    { title: 'Profile', url: '/dashboard/settings/profile', desc: 'View your profile' },
    {
      title: 'Set Transaction Pin',
      url: '#',
      onClick: toggleTransactionPinModal,
      desc: 'Set your transaction pin',
    },
    {
      title: 'Privacy policy',
      url: '/dashboard/settings/privacy',
      desc: 'View the “Privacy and policy” of the app',
    },
    {
      title: 'Terms of use',
      url: '/dashboard/settings/terms',
      desc: 'View the “terms of use” of the app',
    },
    { title: 'Support', url: '/dashboard/support', desc: 'Report your transaction issues' },
  ];

  const settingsPageMenuMap = settingsPageMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="settings-menu-item" key={index} onClick={menu.onClick && menu.onClick}>
        <div>
          <div>{menu.title || ''}</div>
          <div>{menu.desc || ''}</div>
        </div>
        <img src={RightChevronIcon} alt="..." />
      </Link>
    );
  });

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <h2>{pageTitle}</h2>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="settings-menu">{settingsPageMenuMap}</div>

          <SetTransactionPinModal isOpen={transactionPinModalState} closeModal={toggleTransactionPinModal} />

          <br />

          <div className="flex-space-between">
            {accountType === 'user' && (
              <span className="color-primary pointer" onClick={toggleConfirm}>
                Switch to agent account
              </span>
            )}
            {accountType === 'agent' && (
              <span className="color-primary" onClick={toggleConfirm}>
                User is an agent.
              </span>
            )}
            <Button size="small" text="Log Out" onClick={() => dispatch(logoutUser())} />
          </div>

          <ConfirmActionModal
            isOpen={showConfirmModal}
            actionTitle="Confirm Agent Switch"
            actionMessage="This action cannot be reversed"
            onAccept={() => {
              dispatch(convertUserToAgent());
              toggleConfirm();
            }}
            onDecline={toggleConfirm}
            closeModal={() => null}
          />

          <br />

          <div className="settings-menu">
            {aggregatorStatus === 'successful' ? (
              <Link
                to="/dashboard/settings"
                className="settings-menu-item"
                onClick={() => dispatch(infoAlert('Coming soon'))}
              >
                <div>
                  <div>Aggregator</div>
                  <div>Aggregator Account</div>
                </div>
                <img src={RightChevronIcon} alt="..." />
              </Link>
            ) : (
              <button className="settings-menu-item" onClick={() => dispatch(infoAlert('Coming soon'))}>
                <div>
                  <div>Aggregator</div>
                  <div>Aggregator Account</div>
                </div>
                <img src={RightChevronIcon} alt="..." />
              </button>
            )}
          </div>

          <AggregatorRequestModal
            isOpen={showAggregatorRequestModal}
            closeModal={closeAggregatorRequestModal}
            status={aggregatorStatus}
          >
            {aggregatorStatus === '' && (
              <>
                <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                  You need to request to be an Aggregator
                </h5>
                <br />
                <Button size="large" text="Request Now" onClick={requestToBeAnAggregator} />
              </>
            )}
            {aggregatorStatus === 'sent' && (
              <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                Request Sent
              </h5>
            )}
            {aggregatorStatus === 'pending' && (
              <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                Request Pending
              </h5>
            )}
          </AggregatorRequestModal>

          <br />

          <div style={{ marginBottom: '0.5rem' }}>
            <h5 className="color-primary">or invite via</h5>
          </div>

          <div>
            <a href="http://facebok.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
              <img src={FacebookShareIcon} alt="..." width="40px" height="40px" />
            </a>
            <a href="http://whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
              <img src={WhatsAppShareIcon} alt="..." width="40px" height="40px" />
            </a>
            <a href="http://twitter.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
              <img src={TwitterShareIcon} alt="..." width="40px" height="40px" />
            </a>
            <a href="mailto:" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
              <img src={EmailShareIcon} alt="..." width="40px" height="40px" />
            </a>
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Settings;
