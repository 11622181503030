import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.light};
  color: ${props => props.theme.color.jupiter.text};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  main {
    height: 100%;
    overflow-y: auto;
    padding: 1rem 1rem 0 1rem;
  }

  nav {
    width: 100%;
    background-color: ${props => props.theme.color.light};

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      margin: 0 1rem;

      li {
        position: relative;

        a {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          background: transparent;
          color: ${props => props.theme.color.jupiter.text || props.theme.color.text}76;
          font-weight: 600;
          text-decoration: none;
          margin: 1rem;
          outline: none;
          line-height: 100%;

          &:focus,
          &.active {
            color: ${props => props.theme.color.jupiter.text || props.theme.color.text};
          }

          &:hover {
            color: ${props => props.theme.color.jupiter.text || props.theme.color.text};
          }

          span:last-child {
            font-size: 10px;
            display: none;
          }

          .icon.small {
            font-size: 1.3rem;
          }
        }

        &.always-active {
          position: relative;
          width: 4.5rem;
          height: 2.5rem;
          height: 2.75rem;
          z-index: 1;
          background: #ffffff;

          a {
            top: 0;
            left: 0;
            margin-top: 0.5rem;
            margin-left: 2.25rem;
            transform: translate(-50%, -50%);
            background-size: contain;
            background: linear-gradient(
              16.84deg,
              ${props => props.theme.color.primary} -10.65%,
              ${props => props.theme.color.primary} 32.7%,
              ${props => props.theme.color.primary} 65.78%,
              ${props => props.theme.color.secondary} 98.86%
            );
            color: ${props => props.theme.color.light};
            width: 3.75rem;
            height: 3.75rem;
            border-radius: 50%;
            box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.2);
          }
        }

        &.after-always-active,
        &.before-always-active {
          position: relative;
          z-index: 0;

          &:before {
            position: absolute;
            content: '';
            background: #e8ebf2;
            width: 100%;
            height: 2.75rem;
            top: 20%;
            z-index: -1;
          }
        }

        &.before-always-active {
          padding-right: 1rem;

          &:before {
            border-radius: 0 0 40px 0;
            right: 0;
          }
        }

        &.after-always-active {
          padding-left: 1rem;

          &:before {
            border-radius: 0 0 0 40px;
            left: 0;
          }
        }

        &:last-child,
        &:first-child {
          position: relative;
          z-index: 0;

          &:before {
            position: absolute;
            content: '';
            background: #e8ebf2;
            width: calc(100% + 1.5rem);
            height: 2.75rem;
            top: 20%;
            z-index: -1;
          }
        }

        &:first-child {
          a {
            margin-right: -0.5rem;
          }

          &:before {
            border-radius: 20px 0 0 20px;
            left: 0;
          }
        }

        &:last-child {
          position: relative;
          z-index: 0;

          a {
            margin-left: -0.5rem;
          }

          &:before {
            border-radius: 0 20px 20px 0;
            right: 0;
          }
        }
      }
    }
  }
`;

const DashboardLayout = ({ children }) => {
  return (
    <PageView className="no-overflow no-padding">
      <Wrapper>
        <main>{children}</main>

        <nav>
          <ul>
            <li>
              <NavLink to="/dashboard" exact activeClassName="active">
                <Icon size="small" name="home" />
                <span>Overview</span>
              </NavLink>
            </li>
            <li className="before-always-active">
              <NavLink to="/dashboard/wallet" exact activeClassName="active">
                <Icon size="small" name="wallet" />
                <span>Wallet</span>
              </NavLink>
            </li>
            <li className="always-active">
              <Link to="#new">
                <Icon size="large" name="plus" />
              </Link>
            </li>
            <li className="after-always-active">
              <NavLink to="/dashboard/transactions" activeClassName="active">
                <Icon size="small" name="list" />
                <span>History</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/settings" exact activeClassName="active">
                <Icon size="small" name="settings" />
                <span>Settings</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </Wrapper>
    </PageView>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export { DashboardLayout };
