import React from 'react';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import { BankTransferForm } from 'src/skins/pluto/forms';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';

const pageTitle = 'Bank Transfer';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 2rem 0;
  }
`;

const BankTransfer = () => {
  const history = useHistory();
  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="chevronLeft" onClick={() => history.push('/dashboard/transfer')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <BankTransferForm />
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default BankTransfer;
