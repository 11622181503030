import React, { useState, useEffect } from 'react';
import { PageView } from 'src/skins/common/components';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Button } from 'src/skins/common/components';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMmos } from 'src/store/actions/mmoActions';

const Wrapper = styled.div`
  padding-top: 5rem;
  display: grid;
  grid-gap: 5rem;
  .mmo_select {
    display: grid;
    grid-gap: 1rem;
  }
  .proceed {
    text-decoration: none;
    display: grid;
    justify-content: end;
  }
`;

const sortMmoOptions = mmos => {
  if (!mmos) {
    return [];
  }
  return mmos.map(mmo => {
    return { label: mmo.name, value: mmo.shortCode };
  });
};

const Home = () => {
  const [mmo, setMmo] = useState('');
  const dispatch = useDispatch();
  const { isLoading, mmos } = useSelector(state => state.MMO);

  useEffect(() => {
    if (mmos.length < 1) {
      dispatch(getAllMmos());
    }
  }, []);

  return (
    <PageView>
      <Wrapper>
        <h1>Ikonik</h1>
        <div className="mmo_select">
          <p>Select a Mobile Money Operator</p>
          <Select
            isLoading={isLoading.getAllMmos}
            isSearchable={true}
            options={sortMmoOptions(mmos)}
            onChange={e => setMmo(e.value)}
          />
          <Link to={`/app/${mmo}`} className="proceed">
            <Button text="Proceed to App" />
          </Link>
        </div>
      </Wrapper>
    </PageView>
  );
};

export default Home;
