import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// import { MarsModal } from 'src/skins/mars/components/common';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { Link } from 'react-router-dom';
import RightChevronIcon from 'src/skins/mars/assets/svgs/icon-arrow.svg';
import UserIcon from 'src/skins/mars/assets/svgs/icon-user.svg';
import { Icon } from '../../../../common/icon';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;

  .profile-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 15px;
    border: 1px solid #f5f5f7;
    color: ${props => props.theme.color.black};
    text-decoration: none;
    cursor: pointer;
    background-color: #f5f5f7;
    outline: none;
    border-radius: 8px;
    width: 100%;
    height: 78px;

    &:hover {
      border: 1px solid ${props => props.theme.color.gray};
      transition: 0.3s;
    }

    & > * {
      margin: 0.5rem 0;

      &::first-child-child {
        margin-right: 0.5rem;
        font-size: large;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  .header {
    display: flex;
  }

  .lhs {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      background: ${props => props.theme.color.white};
      border-radius: 8px;
      padding: 10px;
    }
  }
`;

export const Profile = () => {
  const history = useHistory();

  const profileMenu = [{ title: 'ID Verification', url: '/dashboard/settings/profile/verification', icon: UserIcon }];

  const profileMenuMap = profileMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="profile-menu-item" key={index} onClick={menu.onClick && menu.onClick}>
        <div className="lhs">
          <img src={UserIcon} alt="..." />
          <span>{menu.title || ''}</span>
        </div>
        <img src={RightChevronIcon} alt="..." />
      </Link>
    );
  });

  return (
    <DashboardLayout light>
      <Wrapper>
        <div className="header">
          <Icon name="back" onClick={() => history.push('/dashboard/settings')} />
          <h4 style={{ marginLeft: '0.5rem' }}>My Profile</h4>
        </div>

        <br />

        {profileMenuMap}
      </Wrapper>
    </DashboardLayout>
  );
};

export default Profile;
