import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { Button } from 'src/skins/common/components';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import AggregatorUserIcon from 'src/assets/svgs/icon-aggregator-user.svg';
import FacebookShareIcon from 'src/assets/svgs/icon-share-via-facebook.svg';
import WhatsAppShareIcon from 'src/assets/svgs/icon-share-via-whatsapp.svg';
import TwitterShareIcon from 'src/assets/svgs/icon-share-via-twitter.svg';
import EmailShareIcon from 'src/assets/svgs/icon-share-via-email.svg';
import { AggregatorRequestModal, ConfirmActionModal, SetTransactionPinModal } from 'src/skins/venus/modals';
import { logoutUser, convertUserToAgent } from 'src/store/actions/userActions';
import { infoAlert } from 'src/store/actions/alertActions';

import iconUserCircle from 'src/skins/venus/assets/svgs/icon-user-circle.svg';
import iconExclamationCircle from 'src/skins/venus/assets/svgs/icon-exclamation-circle.svg';
import iconBell from 'src/skins/venus/assets/svgs/icon-bell.svg';

const pageTitle = 'Settings';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  section {
    margin: 1rem 0;
  }

  .settings-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    border: 0;
    border-bottom: 1px solid ${props => props.theme.color.gray};
    color: ${props => props.theme.color.gray};
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    width: 100%;

    & > * {
      margin: 0.5rem 0;

      &::first-child-child {
        margin-right: 0.5rem;
        font-size: large;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  .settings-menu-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }

  .settings-menu-grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 5.5rem;
    padding: 0.5rem;
    font-size: 0.7rem;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    color: ${props => props.theme.color.venus.text || props.theme.color.text};
    text-decoration: none;
    line-height: 120%;

    img {
      height: 1.5rem;
      margin-bottom: 0.25rem;
    }

    &:hover {
      cursor: pointer;

      img {
        opacity: 0.8;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
`;

const Settings = () => {
  const dispatch = useDispatch();
  const [aggregatorStatus, setAggregatorStatus] = useState('');
  const [showAggregatorRequestModal, setShowAggregatorRequestModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transactionPinModalState, setTransactionPinModalState] = useState(false);

  const accountType = useSelector(state => state.USER.user.accountType);

  const toggleConfirm = () => setShowConfirmModal(!showConfirmModal);
  const toggleTransactionPinModal = () => setTransactionPinModalState(!transactionPinModalState);

  const requestToBeAnAggregator = () => {
    setAggregatorStatus('sent');
    setTimeout(() => {
      setAggregatorStatus('pending');
    }, 10000);
  };

  // const openAggregatorRequestModal = () => setShowAggregatorRequestModal(true);
  const closeAggregatorRequestModal = () => {
    setShowAggregatorRequestModal(false);
    if (aggregatorStatus === 'pending') {
      return setAggregatorStatus('successful');
    }
    setAggregatorStatus('pending');
  };

  const settingsPageMenu = [
    { title: 'Profile', url: '/dashboard/settings/profile', imgSrc: iconUserCircle },
    {
      title: 'Set Transaction Pin',
      url: '#',
      imgSrc: iconExclamationCircle,
      onClick: toggleTransactionPinModal,
    },
    {
      title: 'Support',
      url: '/dashboard/support',
      imgSrc: iconBell,
    },
    { title: 'Privacy policy', url: '/dashboard/settings/privacy', imgSrc: iconBell },
    { title: 'Terms of use', url: '/dashboard/settings/terms', imgSrc: iconExclamationCircle },
  ];

  const settingsPageMenuMap = settingsPageMenu.map((menu, index) => {
    return (
      <Link to={menu.url || '#'} className="settings-menu-grid-item" key={index} onClick={menu.onClick && menu.onClick}>
        <div className="action-card">
          {menu.imgSrc ? <img src={menu.imgSrc} alt={menu.name} /> : <>{menu.name}</>}
          <br />
          <div>{menu.title || ''}</div>
        </div>
      </Link>
    );
  });

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <h2>{pageTitle}</h2>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="settings-menu-grid">{settingsPageMenuMap}</div>

          <SetTransactionPinModal isOpen={transactionPinModalState} closeModal={toggleTransactionPinModal} />

          <br />

          <div className="flex-space-between">
            {accountType === 'user' && (
              <span className="color-red pointer" onClick={toggleConfirm}>
                Switch to agent account
              </span>
            )}
            {accountType === 'agent' && <span className="color-red">User is an agent.</span>}
            <Button size="small" text="Log Out" onClick={() => dispatch(logoutUser())} />
          </div>

          <ConfirmActionModal
            isOpen={showConfirmModal}
            actionTitle="Confirm Agent Switch"
            actionMessage="This action cannot be reversed"
            onAccept={() => {
              dispatch(convertUserToAgent());
              toggleConfirm();
            }}
            onDecline={toggleConfirm}
            closeModal={() => null}
          />

          <br />

          <div className="settings-menu-grid">
            {aggregatorStatus === 'successful' ? (
              <Link
                to="/dashboard/settings"
                className="settings-menu-grid-item"
                onClick={() => dispatch(infoAlert('Coming soon'))}
              >
                <div className="action-card">
                  <img src={AggregatorUserIcon} alt="..." />
                  <br />
                  <div>Aggregator Account</div>
                </div>
              </Link>
            ) : (
              <a className="settings-menu-grid-item" onClick={() => dispatch(infoAlert('Coming soon'))}>
                <div className="action-card">
                  <img src={AggregatorUserIcon} alt="..." />
                  <br />
                  <div>Aggregator Account</div>
                </div>
              </a>
            )}
          </div>

          <AggregatorRequestModal
            isOpen={showAggregatorRequestModal}
            closeModal={closeAggregatorRequestModal}
            status={aggregatorStatus}
          >
            {aggregatorStatus === '' && (
              <>
                <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                  You need to request to be an Aggregator
                </h5>
                <br />
                <Button size="large" text="Request Now" onClick={requestToBeAnAggregator} />
              </>
            )}
            {aggregatorStatus === 'sent' && (
              <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                Request Sent
              </h5>
            )}
            {aggregatorStatus === 'pending' && (
              <h5 className="color-primary" style={{ fontWeight: 'normal' }}>
                Request Pending
              </h5>
            )}
          </AggregatorRequestModal>

          <br />

          <div style={{ marginBottom: '0.5rem' }}>
            <div className="text-sm">Or Invite Via</div>
          </div>

          <div>
            {socialLinks.map((link, index) => (
              <a key={index} href={link.href} target="_blank" rel="noopener noreferrer" style={{ marginRight: '1rem' }}>
                <img src={link.imgSrc} alt="..." width="40px" height="40px" />
              </a>
            ))}
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

const socialLinks = [
  {
    href: 'http://facebook.com',
    imgSrc: FacebookShareIcon,
  },
  {
    href: 'http://whatsapp.com',
    imgSrc: WhatsAppShareIcon,
  },
  {
    href: 'http://twitter.com',
    imgSrc: TwitterShareIcon,
  },
  {
    href: 'mailto:',
    imgSrc: EmailShareIcon,
  },
];

export default Settings;
