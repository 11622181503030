import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'src/skins/common/components';
import TransferBeneficiaryItem from './TransferBeneficiaryItem';
import { TextInput } from 'src/skins/pluto/components/inputs';
import styled from 'styled-components';
import { getBeneficiaries } from 'src/store/actions/beneficiaryActions';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
  .loading-state,
  .empty-state {
    display: flex;
    height: 15vh;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
  }
`;

export const TransferBeneficiaries = props => {
  const { method } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentBeneficiaries, setCurrentBeneficiaries] = useState({});
  const { beneficiaries, isLoading } = useSelector(state => state.BENEFICIARY);

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getBeneficiaries(method));
  }, [method]);

  useEffect(() => {
    !isLoading.getBeneficiaries && setCurrentBeneficiaries(beneficiaries);
  }, [isLoading.getBeneficiaries]);

  const filterBeneficiaries = search => {
    if (search.length === 0) return setCurrentBeneficiaries(beneficiaries);

    const foundByName = beneficiaries.filter(x => x.name.toLowerCase().includes(search.trim().toLowerCase()));

    let foundByTransferId = [];
    let foundByBankName = [];
    let foundByAccountNumber = [];
    if (method === 'peer') {
      foundByTransferId = beneficiaries.filter(x => x.iuwi.toLowerCase().includes(search.trim().toLowerCase()));
    } else {
      foundByBankName = beneficiaries.filter(x => x.bank.toLowerCase().includes(search.trim().toLowerCase()));

      foundByAccountNumber = beneficiaries.filter(x =>
        x.accountNumber.toLowerCase().includes(search.trim().toLowerCase())
      );
    }

    const result = Array.from(
      new Set([...foundByName, ...foundByBankName, ...foundByAccountNumber, ...foundByTransferId])
    );

    setCurrentBeneficiaries(result);
  };

  return (
    <Wrapper>
      <div>
        <TextInput
          type="text"
          label="Search saved beneficiary"
          name="search"
          icon="search"
          required
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            filterBeneficiaries(e.target.value);
          }}
        />
      </div>

      <br />

      {isLoading.getBeneficiaries && <div className="loading-state">fetching beneficiaries...</div>}
      {!isLoading.getBeneficiaries && currentBeneficiaries.length > 0 && (
        <>
          {currentBeneficiaries.map((item, index) => (
            <TransferBeneficiaryItem beneficiary={item} key={index} method={method} />
          ))}
        </>
      )}
      {!isLoading.getBeneficiaries && beneficiaries.length === 0 && (
        <div className="empty-state color-gray">No Beneficiaries Found</div>
      )}

      <br />

      <Button
        size="large"
        text={`New ${method === 'peer' ? 'P2P' : 'Bank'} Transfer`}
        onClick={() => history.push(`/dashboard/transfer/${method}`)}
      />
    </Wrapper>
  );
};

TransferBeneficiaries.defaultProps = {
  method: '',
};

/**
 * BeneficiariesContent PropType Definition
 * @typedef {Object} BeneficiariesContentProps
 * @property {String} method
 */
TransferBeneficiaries.propTypes = {
  method: PropTypes.string.isRequired,
};

export default TransferBeneficiaries;
