/**
 * Format Currency
 *
 * @param {Number} amount
 * @param {String} [currency=null]
 * @returns
 */
export const formatCurrency = (amount, currency = 'NGN') => {
  if (currency) {
    const NumberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
    return NumberFormat.format(amount || 0);
  }

  return new Intl.NumberFormat().format(amount || 0);
};

export default formatCurrency;
