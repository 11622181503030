import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getShortCode, setAppIsActive } from 'src/store/actions/appActions';
import { logoutUser } from 'src/store/actions/userActions';
import SplashScreen from './common/views/SplashScreen';
import { PrivateRoute } from './common/components';
import Setup from './common/views/Setup';
import Home from './common/views/Home';
import { plutoRoutes } from './pluto';
import { venusRoutes } from './venus';
import { jupiterRoutes } from './jupiter';
import { marsRoutes } from './mars';
import { TEST_THEME } from 'src/theme/themeVariables';

const key = {
  pluto: plutoRoutes,
  venus: venusRoutes,
  jupiter: jupiterRoutes,
  mars: marsRoutes,
};

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.APP);
  const [skin, setSkin] = useState('');
  const mmo = useSelector(state => state.MMO.mmo);
  const pathname = window.location.pathname;
  const shortCode = getShortCode();

  useEffect(() => {
    if (!isLoading) {
      setSkin(TEST_THEME || mmo.skin);
      dispatch(logoutUser());
    }
  }, [isLoading, mmo]);

  useEffect(() => {
    if (!isMobile && pathname !== '/') {
      window.location.href = '/';
    }

    if (isMobile && !pathname.includes('/app/')) {
      window.location.href = `/app/${shortCode}`;
    }
  }, []);

  if (process.env.NODE_ENV !== 'development') {
    useEffect(() => {
      let logoutTimeout;
      document.addEventListener('visibilitychange', () => {
        const status = document.hidden;
        dispatch(setAppIsActive(!status));
        if (status) {
          logoutTimeout = setTimeout(() => {
            dispatch(logoutUser());
          }, 30000);
        } else {
          clearTimeout(logoutTimeout);
        }
      });
    }, [status]);
  }

  const routes = key[skin];

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/app/:platform" component={Setup} />
        <Route exact path="/splash" component={SplashScreen} />
        {!isLoading &&
          skin &&
          routes.map(route => {
            return (
              <PrivateRoute
                exact
                path={route.path}
                key={route.name}
                component={route.component}
                isAuth={route.isAuth}
              />
            );
          })}
      </Switch>
    </Router>
  );
};

export default AppRoutes;
