import React, { useState } from 'react';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { LoginForm, RegisterForm } from 'src/skins/jupiter/forms';

const Wrapper = styled.div`
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logo_div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .logo {
    width: 40%;
  }
  .auth_state {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .auth_item {
    text-align: center;
    color: ${props => props.theme.color.primary}BB;
    cursor: pointer;
  }
  .auth_selected {
    border-bottom: 2px solid ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.primary};
  }
  .forms {
    padding-top: 1rem;
    margin-bottom: 3rem;
  }
`;

const Auth = () => {
  const { mmo } = useSelector(state => state.MMO);
  const [selected, setSelected] = useState('login');
  return (
    <PageView>
      <Wrapper>
        <div className="logo_div">
          <img src={mmo.logo} alt="Village Assist" className="logo" />
        </div>
        <div className="auth_state">
          <h5 className={`auth_item ${selected === 'login' && 'auth_selected'}`} onClick={() => setSelected('login')}>
            Login
          </h5>
          <h5
            className={`auth_item ${selected === 'register' && 'auth_selected'}`}
            onClick={() => setSelected('register')}
          >
            Register
          </h5>
        </div>
        <div className="forms">
          {selected === 'login' && <LoginForm />}
          {selected === 'register' && <RegisterForm />}
        </div>
      </Wrapper>
    </PageView>
  );
};

export default Auth;
