import { SET_BENEFICIARY_LOADING, SET_BENEFICIARIES } from 'src/store/types';

const initialState = {
  beneficiaries: [],
  isLoading: {
    state: false,
    getBeneficiaries: false,
    addBeneficiary: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BENEFICIARIES:
      return { ...state, beneficiaries: action.payload };
    case SET_BENEFICIARY_LOADING:
      return {
        ...state,
        isLoading: {
          state: action.payload.state,
          [action.payload.action]: action.payload.state,
        },
      };
    default:
      return state;
  }
}
