import React from 'react';
import PropTypes from 'prop-types';
import { VenusModal } from 'src/skins/venus/components/common';
import styled from 'styled-components';

const Wrapper = styled.div`
  .transaction-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .transaction-status-icon {
    position: relative;
    margin: 1rem auto 2rem auto;

    &:before {
      display: block;
      content: '';
      width: 8rem;
      height: 8rem;
      border: 1px solid ${props => props.theme.color.primary};
      border-radius: 50%;
    }

    &[status='pending'] {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% + 0.125rem);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 0.5rem;
        background-color: #fbd305;
      }
    }

    &[status='failed'] {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% + 0.125rem);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 0.5rem;
        background-color: #fbd305;
      }
    }

    &[status='successful'] {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 1rem;
        left: 3rem;
        width: 2rem;
        height: 5rem;
        border: solid #1de331;
        border-width: 0 0.5rem 0.5rem 0;
        transform: rotate(45deg);
      }
    }
  }

  .transaction-status-text {
    color: ${props => props.theme.color.primary};
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

const TransactionStatusModal = ({ isOpen, closeModal, status }) => {
  return (
    <VenusModal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <center className="transaction-status">
          <div className="transaction-status-icon" status={status}></div>
          <div className="transaction-status-text">Transaction {status.replace(/\b(\S)/g, t => t.toUpperCase())}</div>
        </center>
      </Wrapper>
    </VenusModal>
  );
};

TransactionStatusModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  status: 'successful',
};

/**
 * TransactionStatusModal PropType Definition
 * @typedef {Object} TransactionStatusModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {('successful'|'pending'|'failed')} status
 */
TransactionStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export { TransactionStatusModal };
