import React, { useState } from 'react';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import TransferBeneficiaries from 'src/skins/pluto/components/beneficiaries/transfers/TransferBeneficiaries';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/skins/common/icon';

const pageTitle = 'Transfer';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 1rem 0;
  }

  .tab-menu {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    & > * {
      display: inline-block;
      width: 100%;
      background: transparent;
      color: ${props => props.theme.color.gray};
      border: 1px solid ${props => props.theme.color.gray};
      outline: none;
      border-radius: 8px;
      height: 4em;
      font-weight: 600;

      &:hover,
      &:focus,
      &[active] {
        background: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.light};
        border-color: ${props => props.theme.color.primary};
        cursor: pointer;
      }
    }
  }

  .tab-content {
    margin-top: 1rem;
  }
`;

const Transfer = () => {
  const [method, setMethod] = useState('peer');
  const history = useHistory();

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="chevronLeft" onClick={() => history.push('/dashboard')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <div className="tab-menu">
            <button active={method === 'peer' ? '' : null} onClick={() => setMethod('peer')}>
              P2P
            </button>
            <button active={method === 'bank' ? '' : null} onClick={() => setMethod('bank')}>
              Bank Transfer
            </button>
          </div>

          <div className="tab-content">
            <TransferBeneficiaries method={method} />
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Transfer;
