import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';
/* import FacebookIcon from 'src/assets/svgs/icon-facebook.svg';
import GoogleIcon from 'src/assets/svgs/icon-google.svg';
import TwitterIcon from 'src/assets/svgs/icon-twitter.svg'; */

const LoginPageWrapper = styled.div`
  .forgot-password {
    text-align: right;
  }

  .has-link,
  .forgot-password {
    a {
      margin-bottom: 1rem;
      color: ${props => props.theme.color.primary};

      &:focus,
      &:hover {
        color: ${props => props.theme.color.info};
      }
    }

    * {
      display: inline-block;
    }
  }

  .divider {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      border-top: 1px solid ${props => props.theme.color.gray};
      display: block;
      width: 100%;
      position: absolute;
      top: 50%;
      z-index: -1;
    }

    &:after {
      content: attr(data-text);
      text-align: center;
      display: block;
      width: fit-content;
      margin: 0 auto;
      font-size: 1.1rem;
      background-color: ${props => props.theme.color.light};
      color: ${props => props.theme.color.gray};
      padding: 0.2rem 0.8rem;
    }
  }

  .social-links {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    grid-template-columns: repeat(3, 2rem);
    margin-bottom: 1rem;

    a {
      display: inline-flex;
      background: ${props => props.theme.color.primary};
      color: ${props => props.theme.color.light};
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      line-height: 100%;

      img {
        height: 50%;
      }

      &:hover {
        background: ${props => props.theme.color.info};
      }
    }
  }
`;

const WelcomeBannerWrapper = styled.div`
  background: linear-gradient(
    180deg,
    ${props => props.theme.color.primary} 0%,
    ${props => props.theme.color.secondary} 100%
  );
  color: ${props => props.theme.color.light};
  padding: 2.2rem 1rem;
  margin: -1rem -1rem 1rem -1rem;

  p {
    margin-top: 0.8em;
    font-weight: 400;
    font-size: 0.85rem;
  }
  .icon {
    cursor: pointer;
  }
`;

const AuthLayout = ({ children, isRegistering, pageTitle, description }) => {
  const history = useHistory();
  return (
    <PageView>
      <LoginPageWrapper>
        <WelcomeBannerWrapper>
          <Icon name="chevronLeft" className="icon" onClick={() => history.push('/auth')} />
          <h3>{pageTitle}</h3>
          <p>{description}</p>
        </WelcomeBannerWrapper>

        {children}

        <br />

        <div className="divider" data-text="or" />

        {/* 		<div className="social-links">
					<a href="http://google.com" target="_blank" rel="noopener noreferrer">
						<img src={GoogleIcon} alt="GL" />
					</a>
					<a href="http://facebook.com" target="_blank" rel="noopener noreferrer">
						<img src={FacebookIcon} alt="FB" />
					</a>
					<a href="http://twitter.com" target="_blank" rel="noopener noreferrer">
						<img src={TwitterIcon} alt="TW" />
					</a>
				</div> */}

        <br />

        <center className="has-link">
          {isRegistering ? (
            <h6>
              Already have an account? <Link to="/login">Sign in</Link>{' '}
            </h6>
          ) : (
            <h6>
              Don’t have an account? <Link to="/register">Sign up here</Link>{' '}
            </h6>
          )}
          <br />
          <h6>
            Forgot account access? <Link to="/forgot">Reset password</Link>
          </h6>
        </center>
      </LoginPageWrapper>
    </PageView>
  );
};

AuthLayout.defaultProps = {
  pageTitle: '',
  description: '',
  children: null,
  isRegistering: false,
};

AuthLayout.propTypes = {
  pageTitle: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  isRegistering: PropTypes.bool,
};

export { AuthLayout };
