import React, { useLayoutEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';

const Wrapper = styled.div`
  padding-bottom: 0.5rem;
  .input_section {
    height: 3rem;
    width: 100%;
    padding: 0;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
    outline: 0px;
    color: ${props => props.theme.color.primary};
    transition: ease 0.5s all;
    margin-bottom: 0.1rem;
  }
  .input_icon {
    background: transparent;
    color: ${props => props.theme.color.gray};
    transition: ease 0.5s all;
    border: none;
    appearance: none;
    outline: none;
  }
  .input-wrapper {
    margin-top: -0.2rem;
    position: relative;
    height: 3rem;
    width: 100%;
  }
  .input {
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    outline: none;
    font-size: 1.1rem;
    background: #202021;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    z-index: 1;

    &::placeholder {
      color: ${props => props.theme.color.mars.gray || props.theme.color.gray};
    }

    /* &:focus {
			border-color: ${props => props.theme.color.primary};
		} */
  }
  .input + .input_icon {
    position: absolute;
    top: 0rem;
    right: 0.5rem;
    z-index: 1;
    height: 3rem;
    color: #a5a5a5;

    /* .spinner.primary {
			color: #A5A5A5;
		} */
  }
  .input_icon {
    color: ${props => props.theme.color.primary};
  }
  .label {
    color: ${props => props.theme.color.mars.gray || props.theme.color.gray};
    min-width: max-content;
    font-size: 1em;
    transform: translate(0, 0);
    display: inline-block;
    font-size: 400;
    transition: ease 0.5s all;
    z-index: -1;
    height: 22px;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & > div[readOnly] {
    opacity: 0.75;
    filter: grayscale(100%);
  }
  & > div[disabled] {
    opacity: 0.55;
    filter: grayscale(100%);
  }
  .cursor_pointer {
    cursor: pointer;
  }

  .helper-text {
    color: ${props => props.theme.color.gray};
    font-size: small;
    margin-top: 0.25rem;
  }
`;

/**
 * @description TextInput Component
 * @param {TextInputProps} props
 */
const TextInput = forwardRef((props, ref) => {
  const {
    type,
    label,
    name,
    required,
    disabled,
    readOnly,
    onFocus,
    onInput,
    onBlur,
    onKeyUp,
    placeholder,
    inputmode,
    value,
    onChange,
    endInputName,
    endInputIsLoading,
    onEndInputClick,
    errors,
    helperText,
    accept,
  } = props;

  const [showLabelOnTop, setShowLabelOnTop] = useState(false);
  const handleInputFocus = (onFocusCallback, e) => {
    setShowLabelOnTop(true);
    if (onFocusCallback) onFocusCallback(e);
  };
  const handleInputBlur = (onBlurCallback, e) => {
    if (e.target.value || e.target.checkValidity()) return;
    setShowLabelOnTop(false);
    if (onBlurCallback) onBlurCallback(e);
  };

  useLayoutEffect(() => {
    const thisInput = document.querySelector(`input[name="${props.name}"]`);
    if (thisInput.value || thisInput.checkValidity()) setShowLabelOnTop(true);
  });

  const finalType = type === 'date' && !showLabelOnTop ? 'text' : type;

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const togglePasswordVisibility = () => setPasswordIsVisible(!passwordIsVisible);

  return (
    <Wrapper>
      <div disabled={disabled} readOnly={readOnly}>
        {label && <label className="label">{label}</label>}
        <div className="input_section" tabIndex="-1">
          <div className="input-wrapper">
            {onChange ? (
              <input
                className="input"
                type={finalType}
                name={name}
                placeholder={placeholder}
                inputMode={inputmode}
                onInput={onInput}
                onFocus={e => handleInputFocus(onFocus, e)}
                onBlur={e => handleInputBlur(onBlur, e)}
                onKeyUp={onKeyUp}
                ref={ref}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                value={value}
                onChange={onChange}
                accept={accept}
              />
            ) : (
              <input
                className="input"
                type={passwordIsVisible ? 'text' : finalType}
                name={name}
                placeholder={placeholder}
                inputMode={inputmode}
                onInput={onInput}
                onFocus={e => handleInputFocus(onFocus, e)}
                onBlur={e => handleInputBlur(onBlur, e)}
                onKeyUp={onKeyUp}
                ref={ref}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                accept={accept}
              />
            )}
            {type === 'password' && (
              <button type="button" className="input_icon" onClick={togglePasswordVisibility}>
                <Icon size="small" className="cursor_pointer" name={passwordIsVisible ? 'eyeOff' : 'eye'} />
              </button>
            )}
            {endInputName && endInputIsLoading ? (
              <button type="button" className="input_icon">
                <Spinner className="input_icon" />
              </button>
            ) : (
              endInputName && (
                <button type="button" className="input_icon" onClick={onEndInputClick}>
                  <Icon size="small" name={endInputName} className="cursor_pointer" />
                </button>
              )
            )}
          </div>
        </div>
        {errors && errors}
        {helperText && <p className="helper-text">{helperText}</p>}
      </div>
    </Wrapper>
  );
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  type: 'text',
  placeholder: '',
};

/**
 * TextInput PropType Definition
 * @typedef {Object} TextInputProps
 * @property {String} type
 * @property {String} label
 * @property {String} name
 * @property {String} value
 * @property {Function} onChange
 * @property {Function} onFocus
 * @property {Function} onInput
 * @property {Function} onBlur
 * @property {Function} onKeyUp
 * @property {Boolean} required
 * @property {Boolean} disabled
 * @property {Boolean} readOnly
 * @property {String} placeholder
 * @property {String} inputmode
 * @property {React} errors
 * @property {String} endInputName
 * @property {bool} endInputIsLoading
 * @property {Function} onEndInputClick
 * @property {String} helperText
 * @property {String} accept
 */
TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  inputmode: PropTypes.string,
  errors: PropTypes.any,
  endInputName: PropTypes.any,
  endInputIsLoading: PropTypes.bool,
  onEndInputClick: PropTypes.func,
  helperText: PropTypes.string,
  accept: PropTypes.string,
};

export { TextInput };
