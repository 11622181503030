import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PageView, Spinner } from 'src/skins/common/components';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5%;
  .banner_div {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .logo {
    width: 65%;
  }
  .description {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

const SplashScreen = () => {
  const { isLoading, mmo } = useSelector(state => state.MMO);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/auth');
    }, 4000);
  }, []);

  return (
    <PageView background="light">
      <Wrapper>
        {isLoading.getSingleMmo ? (
          <Spinner />
        ) : (
          <div className="banner_div">
            <img src={mmo.logo} alt={mmo.name} className="logo" />
            <div className="description">
              <h5>{mmo.name}</h5>
              {!mmo.blacklisted && <small>Powered by Ikonik.ca</small>}
            </div>
          </div>
        )}
      </Wrapper>
    </PageView>
  );
};

SplashScreen.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SplashScreen;
