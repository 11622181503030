import { combineReducers } from 'redux';
import { CLEAR_SESSION } from 'src/store/types';

import appReducer from './appReducer';
import mmoReducer from './mmoReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';
import transactionReducer from './transactionReducer';
import beneficiaryReducer from './beneficiaryReducer';
import billsReducer from './billReducer';
import collectionReducer from './collectionReducer';
import deviceReducer from './deviceReducer';
import selfcareReducer from './selfcareReducer';

const reducers = combineReducers({
  APP: appReducer,
  ALERT: alertReducer,
  MMO: mmoReducer,
  USER: userReducer,
  TRANSACTION: transactionReducer,
  BENEFICIARY: beneficiaryReducer,
  BILLS: billsReducer,
  COLLECTION: collectionReducer,
  DEVICE: deviceReducer,
  SELFCARE: selfcareReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  let mmo, app;
  if (action.type === CLEAR_SESSION) {
    mmo = state.MMO;
    app = state.APP;
    state = undefined;
    state = { MMO: mmo, APP: app };
  }
  return reducers(state, action);
};

export default rootReducer;
