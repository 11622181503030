import React from 'react';
import { Link } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import { MarsModal } from 'src/skins/mars/components/common';
import { RegisterForm, LoginForm, ForgotPasswordForm, SetPasswordForm } from 'src/skins/mars/forms';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import OnboardingSlider from 'src/skins/mars/components/common/OnboardingSlider';

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: rgba(13, 13, 13, 0.5);

  & > * {
    padding: 1rem 0;
    text-align: center;
  }

  .mt-01 {
    margin-top: 0.1rem;
  }

  .register-login-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > a {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      background: ${props => props.theme.color.light};
      color: ${props => props.theme.color.mars.text || props.theme.color.text};
      font-size: large;
      font-weight: 600;
      height: 56px;
      width: 100%;
      border-radius: 5px;
      border: 2px solid #000000;

      &:last-child {
        border-color: ${props => props.theme.color.mars.text || props.theme.color.text};
        background: ${props => props.theme.color.mars.text || props.theme.color.text};
        color: ${props => props.theme.color.light};
      }
    }
  }
`;

const Auth = () => {
  const { mmo } = useSelector(state => state.MMO);

  return (
    <React.Fragment>
      <PageView>
        <Wrapper>
          <div>
            <h4 className="color-black">{mmo.name}</h4>
            <p className="mt-01">{mmo.motto}</p>
          </div>

          <OnboardingSlider />

          <div className="register-login-links">
            <Link to="/auth#login" className="text-decoration-none" style={{ width: '47%' }}>
              Sign In
            </Link>
            <Link to="/auth#register" className="text-decoration-none" style={{ width: '47%' }}>
              Register
            </Link>
          </div>
        </Wrapper>
      </PageView>

      <MarsModal id="register" isOpen={true}>
        <RegisterForm />
      </MarsModal>

      <MarsModal id="login" isOpen={true}>
        <LoginForm />
      </MarsModal>

      <MarsModal id="forgot" isOpen={true}>
        <ForgotPasswordForm />
      </MarsModal>

      <MarsModal id="set-password" isOpen={true}>
        <SetPasswordForm />
      </MarsModal>
    </React.Fragment>
  );
};

export default Auth;
