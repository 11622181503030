import { SET_ISSUE, SET_ISSUES, RESET_ISSUES, SET_ISSUE_ACTIONS } from 'src/store/types';

const initialIssuesObj = {
  data: [],
  page: 1,
  limit: 10,
  count: 0,
  openCount: 0,
  closedCount: 0,
  resolvedCount: 0,
};

const initialState = {
  issues: initialIssuesObj,
  issue: {},
  isLoading: {
    state: false,
    getAllIssues: false,
    getSingleIssue: true,
    creatingIssue: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ISSUES:
      return { ...state, issues: action.payload };
    case RESET_ISSUES:
      return { ...state, issues: initialIssuesObj };
    case SET_ISSUE:
      return { ...state, issue: action.payload };
    case SET_ISSUE_ACTIONS:
      return {
        ...state,
        isLoading: {
          state: action.payload.state,
          [action.payload.action]: action.payload.state,
        },
      };
    default:
      return state;
  }
}
