import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'src/skins/common/components/Button';
import { TextInput, CheckboxInput, SelectInput } from 'src/skins/jupiter/components/inputs';
import { TransactionSummaryModal, TransactionStatusModal, TransactionPinModal } from 'src/skins/jupiter/modals';
import { formatToDigits } from 'src/utils/helpers';
import { getBillSummary, confirmBillTransaction } from 'src/store/actions/billActions';

export const BuyElectricityForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionSummary, setTransactionSummary] = useState({});

  const [showElectricitySummaryModal, setShowElectricitySummaryModal] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);

  const { isLoading } = useSelector(state => state.BILLS);

  const beneficiary = history.location.state || {};
  const { type } = useParams();

  const toggleElectricitySummaryModal = () => setShowElectricitySummaryModal(!showElectricitySummaryModal);
  const toggleTransactionPinModalStatus = () => setTransactionPinModalStatus(!transactionPinModalStatus);

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/bills');
    }
  };

  const { register, handleSubmit, watch, setValue, clearErrors, control } = useForm({
    defaultValues: {
      type: beneficiary.type || '',
      name: beneficiary.name || '',
      meterNumber: beneficiary.meterNumber || '',
      meterType: beneficiary.meterType || '',
      saveBeneficiary: false,
    },
  });

  const onSubmit = data => {
    const payload = {
      amount: +data.amount,
      disco: type,
      meterNumber: data.meterNumber || beneficiary.meterNumber,
      meterType: data.meterType || beneficiary.meterType,
    };

    dispatch(
      getBillSummary(payload, 'electricity', data => {
        if (data) {
          setTransactionSummary(data);
          toggleElectricitySummaryModal();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmElectricityClick = pin => {
    const payload = {
      amount: +transactionSummary.amount,
      meterNumber: transactionSummary.meterNumber,
      meterType: transactionSummary.meterType,
      disco: type,
      pin,
    };

    if (watch('saveBeneficiary')) {
      payload.saveBeneficiary = watch('saveBeneficiary');
      payload.name = watch('name');
    }

    dispatch(
      confirmBillTransaction(payload, 'electricity', (_success, error) => {
        toggleElectricitySummaryModal();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="text"
            placeholder="Meter Number"
            label="Meter Number"
            name="meterNumber"
            ref={register}
            icon="phone"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            disabled={beneficiary.state}
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 30);
              return e;
            }}
          />
          <Controller
            control={control}
            name="meterType"
            render={() => (
              <SelectInput
                placeholder="Meter Type"
                label="Meter Type"
                name="meterType"
                ref={register}
                icon="bank"
                required
                isClearable={false}
                options={[
                  { value: 'prepaid', label: 'Prepaid' },
                  { value: 'postpaid', label: 'Postpaid' },
                ]}
                value={watch('meterType') || null}
                onChange={selectedOption => {
                  setValue('meterType', selectedOption?.value || '');
                  clearErrors('meterType');
                }}
              />
            )}
          />
          <TextInput
            type="text"
            placeholder="Amount"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              let { value } = e.target;
              value = formatToDigits(value);
              e.target.value = value.slice(0, 15);
            }}
          />
          <CheckboxInput
            label="Save as beneficiary"
            name="saveBeneficiary"
            ref={register}
            disabled={beneficiary.state}
          />

          {(watch('saveBeneficiary') || beneficiary.state) && (
            <TextInput
              type="text"
              placeholder="Beneficiary Name"
              label="Name"
              name="name"
              ref={register}
              icon="account"
              required
              disabled={beneficiary.state}
            />
          )}
        </div>

        <br />

        <Button type="submit" text="Buy Electricity Plan" size="large" isLoading={isLoading.getBillSummary} />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showElectricitySummaryModal}
        closeModal={toggleElectricitySummaryModal}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          closeModal={toggleTransactionPinModalStatus}
          clickIsLoading={isLoading.confirmBillTransaction}
          handleConfirmTransactionClick={handleConfirmElectricityClick}
        />
      )}

      <TransactionStatusModal
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
        status={transactionStatus}
      />
    </>
  );
};

export default BuyElectricityForm;
