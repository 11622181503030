import clients from 'src/clients';
import { SET_BENEFICIARY_LOADING, SET_BENEFICIARIES } from 'src/store/types';
import { errorAlert } from './alertActions';

const { appService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_BENEFICIARY_LOADING,
    payload: { action, state },
  };
};

const setBeneficiaries = transactions => {
  return { type: SET_BENEFICIARIES, payload: transactions };
};

export const getBeneficiaries = type => dispatch => {
  dispatch(setIsLoading('getBeneficiaries', true));
  appService
    .get(`/users/beneficiaries/${type}`)
    .then(res => {
      dispatch(setBeneficiaries(res.data.data));
      dispatch(setIsLoading('getBeneficiaries', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getBeneficiaries', false));
    });
};

export const getTvCablesBeneficiaries = type => dispatch => {
  dispatch(setIsLoading('getBeneficiaries', true));
  appService
    .get(`/users/beneficiaries/tv-cable/${type}`)
    .then(res => {
      dispatch(setBeneficiaries(res.data.data));
      dispatch(setIsLoading('getBeneficiaries', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getBeneficiaries', false));
    });
};

export const getElectricityBeneficiaries = () => dispatch => {
  dispatch(setIsLoading('getBeneficiaries', true));
  appService
    .get('/users/beneficiaries/electricity')
    .then(res => {
      dispatch(setBeneficiaries(res.data.data));
      dispatch(setIsLoading('getBeneficiaries', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getBeneficiaries', false));
    });
};
