import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/skins/common/components';
import { TextInput } from 'src/skins/jupiter/components/inputs';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, verifyLoginEmail } from 'src/store/actions/userActions';

const Wrapper = styled.div`
  .forgot_div {
    display: grid;
    justify-content: flex-end;
  }
  .forgot {
    width: max-content;
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

export const LoginForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const [showSend, setShowSend] = useState(true);
  const { accountIsValid, isLoading } = useSelector(state => state.USER);
  const platform = useSelector(state => state.MMO.shortCode);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    email ? setShowSend(true) : setShowSend(false);
  }, [email]);

  const validateEmail = () => {
    if (!email) {
      return setError({ type: 'required' });
    }
    dispatch(verifyLoginEmail({ email, platform }));
    setVerifiedEmail(email);
  };

  const onSubmit = data => {
    if (!(email === verifiedEmail && !!accountIsValid)) {
      return validateEmail();
    }
    data.platform = platform;
    dispatch(loginUser(data));
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="email"
            placeholder="Email Address"
            name="email"
            ref={register({ required: true })}
            icon="mail"
            onInput={e => {
              setEmail(e.target.value);
              return e;
            }}
            endInputName={showSend && 'send'}
            endInputIsLoading={isLoading.verifyLoginEmail}
            onEndInputClick={validateEmail}
            required={true}
            errors={<React.Fragment>{errors.email && <p className="error">Email is required</p>}</React.Fragment>}
          />

          {email === verifiedEmail && !!accountIsValid && (
            <React.Fragment>
              <TextInput
                type="password"
                placeholder="Password"
                name="password"
                ref={register({ required: true })}
                icon="lock"
                errors={
                  <React.Fragment>{errors.password && <p className="error">Password is required</p>}</React.Fragment>
                }
              />

              <div className="forgot_div">
                <p className="forgot" onClick={() => history.push('/forgot')}>
                  Forgot Password?
                </p>
              </div>
              <br />
              <Button
                type="submit"
                text="Login"
                size="large"
                isLoading={isLoading.loginUser || isLoading.authenticateUser}
              />
            </React.Fragment>
          )}
          {verifiedEmail && !isLoading.verifyLoginEmail && accountIsValid === false && (
            <div>
              <h5>Account is inactive</h5>
              <br />
              <Button onClick={() => history.push('/forgot')} type="button" text="Activate Account" size="large" />
            </div>
          )}
          {verifiedEmail && !isLoading.verifyLoginEmail && accountIsValid === null && (
            <div>
              <h5 className="color-red">Account not found</h5>
              <br />
              <p>Register an account instead</p>
            </div>
          )}
        </div>
      </form>
    </Wrapper>
  );
};

export default LoginForm;
