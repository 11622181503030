import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'src/skins/common/components';
import { TextInput, PinInput } from 'src/skins/jupiter/components/inputs';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setTransactionPin } from 'src/store/actions/userActions';

/**
 * @description SetTransactionPinModal Component
 * @param {SetTransactionPinModalProps} props
 */
const SetTransactionPinModal = props => {
  const { isOpen, closeModal } = props;
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [pin, setPin] = useState({});

  const getPin = () => {
    let pinString = '';
    Object.keys(pin).forEach(item => (pinString = pinString + pin[item]));
    return pinString;
  };

  const onSubmit = data => {
    const { password } = data;
    const payload = {
      pin: getPin(),
      password,
    };

    dispatch(
      setTransactionPin(payload, success => {
        return success && closeModal();
      })
    );
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h5 className="color-primary">Set Transaction Pin</h5>
        <p>Pin can only be set once.</p>
        {/* <p>Please contact support to reset Pin.</p> */}

        <br />

        <p style={{ marginBottom: '0.5rem' }}>Enter Pin</p>
        <PinInput value={pin} onChange={setPin} />

        <br />

        <p style={{ marginBottom: '0.5rem' }}>Enter Password</p>
        <TextInput type="password" name="password" ref={register} required />
        <br />

        <Button type="submit" text="Set Pin" size="large" />
      </form>
    </Modal>
  );
};

SetTransactionPinModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

/**
 * SetTransactionPinModal PropType Definition
 * @typedef {Object} SetTransactionPinModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 */
SetTransactionPinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export { SetTransactionPinModal };
