import React from 'react';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { BuyTvCablesForm } from 'src/skins/mars/forms';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { MarsModal } from 'src/skins/mars/components/common';

const pageTitles = {
  dstv: 'Buy Dstv',
  startimes: 'Buy Startimes',
  gotv: 'Buy Gotv',
};

const Wrapper = styled.div`
  section {
    margin: 1rem 0;
  }
`;

const BIllsTvCables = () => {
  const history = useHistory();
  const { type } = useParams();
  return (
    <DashboardLayout>
      <MarsModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="bills">
        <Wrapper>
          <h2>{pageTitles[type] || 'Buy Tv Cables'}</h2>

          <section>
            <BuyTvCablesForm />
          </section>
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

export default BIllsTvCables;
