import React from 'react';
import { useHistory } from 'react-router-dom';
import { VenusButton } from 'src/skins/venus/components/common';
import { TextInput, SelectInput } from 'src/skins/venus/components/inputs';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createNewIssue } from 'src/store/actions/selfcareActions';
import { bytesToMegaBytes } from 'src/utils/bytesToMegaBytes';
import { useQuery } from 'src/utils';

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

const NewIssueForm = () => {
  const { category = '', transactionRef = '', amount = '' } = useQuery() || {};
  const transactionType = category ? transactionTypeOptions[category][0].value : '';

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.SELFCARE);
  const creatingIssue = isLoading.createNewIssue;

  const { register, handleSubmit, watch, setValue, control, errors, clearErrors, reset, setError } = useForm({
    defaultValues: {
      category,
      transactionType,
      transactionRef,
      amount,
    },
  });

  const onSubmit = data => {
    if (data.image) {
      const imageSizeInMb = bytesToMegaBytes(data.image[0].size);
      if (imageSizeInMb > 2) {
        return setError('image', {
          type: 'manual',
          message: 'Image size is too large!',
        });
      }
    }

    dispatch(
      createNewIssue(data, () => {
        reset({});
        history.push('/dashboard/support');
      })
    );
  };

  const watchCategory = watch('category');
  const watchTransactionType = watch('transactionType');
  const watchiIssueType = watch('issueType', '');
  const issueTypeIsOthers = watchiIssueType === 'Others';
  const requireImage = requireImageIssueTypes.some(x => x.toLowerCase() === watchiIssueType.toLowerCase());

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" encType="multipart/form-data">
        <Controller
          control={control}
          name="category"
          defaultValue={category || ''}
          rules={{ required: 'Transaction Category is required' }}
          render={() => (
            <SelectInput
              label="Category"
              name="category"
              options={transactionCategoryOptions}
              onChange={selectedOption => {
                setValue('category', selectedOption?.value || '');
                clearErrors('category');
                setValue('transactionType', '');
                if (!issueTypeIsOthers) setValue('issueType', '');
              }}
              placeholder="Select Transaction Category"
              value={watchCategory || null}
              errors={
                <React.Fragment>{errors.category && <p className="error">{errors.category.message}</p>}</React.Fragment>
              }
            />
          )}
        />

        {watchCategory && (
          <Controller
            control={control}
            name="transactionType"
            defaultValue={transactionType || ''}
            rules={{ required: 'Transaction Type is required' }}
            render={() => (
              <SelectInput
                label="Transaction Type"
                name="transactionType"
                options={watchCategory ? transactionTypeOptions[watchCategory] : []}
                onChange={selectedOption => {
                  setValue('transactionType', selectedOption?.value || '');
                  clearErrors('transactionType');
                }}
                placeholder="Select Transaction type"
                value={watchTransactionType || null}
                errors={
                  <React.Fragment>
                    {errors.transactionType && <p className="error">{errors.transactionType.message}</p>}
                  </React.Fragment>
                }
              />
            )}
          />
        )}

        {watchCategory && watchTransactionType && (
          <Controller
            control={control}
            name="issueType"
            defaultValue=""
            rules={{ required: 'Issue Type is required' }}
            render={() => (
              <SelectInput
                label="Issue Type"
                name="issueType"
                options={watchTransactionType ? issueTypeOptions[watchCategory] : []}
                onChange={selectedOption => {
                  setValue('issueType', selectedOption?.value || '');
                  clearErrors('issueType');
                }}
                placeholder="Select Issue type"
                errors={
                  <React.Fragment>
                    {errors.issueType && <p className="error">{errors.issueType.message}</p>}
                  </React.Fragment>
                }
              />
            )}
          />
        )}

        {watchTransactionType && watchCategory && issueTypeIsOthers && (
          <TextInput
            label="Additional comment or explanation"
            type="text"
            name="comment"
            placeholder="Additional comment"
            ref={register({ required: 'Additional comment is required' })}
            errors={
              <React.Fragment>{errors.comment && <p className="error">{errors.comment.message}</p>}</React.Fragment>
            }
          />
        )}

        {watchTransactionType && watchCategory === 'WITHDRAWAL' && (
          <React.Fragment>
            <TextInput
              label="Expected Amount"
              type="text"
              name="amount"
              placeholder="Expected Amount"
              ref={register({ required: 'Expected Amount is required' })}
              errors={
                <React.Fragment>{errors.amount && <p className="error">{errors.amount.message}</p>}</React.Fragment>
              }
              inputmode="numeric"
            />

            <TextInput
              label="Screenshot of receipt"
              type="file"
              name="image"
              placeholder="Screenshot of receipt"
              ref={register({ required: requireImage && 'Screenshot of receipt is required' })}
              errors={
                <React.Fragment>{errors.image && <p className="error">{errors.image.message}</p>}</React.Fragment>
              }
              helperText="Image must not be greater than 2MB"
              accept="image/*"
            />

            <TextInput
              label="RRN (Retrieval Reference Number)"
              type="text"
              name="rrn"
              placeholder="Retrieval Reference Number"
              ref={register({ required: requireImage && 'Retrieval Reference Number is required' })}
              errors={<React.Fragment>{errors.rrn && <p className="error">{errors.rrn.message}</p>}</React.Fragment>}
              inputmode="numeric"
            />

            <TextInput
              label="Masked PAN Number"
              type="text"
              name="pan"
              placeholder="Masked PAN Number"
              ref={register({ required: requireImage && 'Masked PAN Number is required' })}
              errors={<React.Fragment>{errors.pan && <p className="error">{errors.pan.message}</p>}</React.Fragment>}
              inputmode="numeric"
            />
          </React.Fragment>
        )}

        {watchTransactionType && watchCategory !== 'WITHDRAWAL' && (
          <React.Fragment>
            <TextInput
              label="Transaction Reference"
              type="text"
              name="transactionRef"
              placeholder="Transaction Reference"
              ref={register({ required: 'Transaction Reference is required' })}
              errors={
                <React.Fragment>
                  {errors.transactionRef && <p className="error">{errors.transactionRef.message}</p>}
                </React.Fragment>
              }
            />

            <TextInput
              label="Transaction Amount"
              type="text"
              name="amount"
              placeholder="Transaction Amount"
              ref={register({ required: 'Transaction Amount is required' })}
              errors={
                <React.Fragment>{errors.amount && <p className="error">{errors.amount.message}</p>}</React.Fragment>
              }
              inputmode="numeric"
            />
          </React.Fragment>
        )}

        <br />

        <VenusButton type="submit" text="Submit Issue" size="large" isLoading={creatingIssue} />
      </form>
    </Wrapper>
  );
};

const transactionCategoryOptions = [
  {
    label: 'TRANSFER',
    value: 'TRANSFER',
  },
  {
    label: 'WITHDRAWAL',
    value: 'WITHDRAWAL',
  },
  {
    label: 'BILLS e.g. AIRTIME, DATA, TV CABLES, ELECTRICITY etc',
    value: 'BILLS',
  },
  {
    label: 'WALLET',
    value: 'WALLET',
  },
];

const transactionTypeOptions = {
  TRANSFER: [
    {
      label: 'BANK TRANSFER',
      value: 'BANK TRANSFER',
    },
    {
      label: 'PEER TRANSFER',
      value: 'PEER TRANSFER',
    },
  ],
  WITHDRAWAL: [
    {
      label: 'POS WITHDRAWAL',
      value: 'POS WITHDRAWAL',
    },
    {
      label: 'CARD WITHDRAWAL',
      value: 'CARD WITHDRAWAL',
    },
  ],
  BILLS: [
    {
      label: 'BILLS - AIRTIME',
      value: 'BILLS - AIRTIME',
    },
    {
      label: 'BILLS - DATA',
      value: 'BILLS - DATA',
    },
    {
      label: 'BILLS - TV CABLES',
      value: 'BILLS - TV CABLES',
    },
    {
      label: 'BILLS - ELECTRICITY',
      value: 'BILLS - ELECTRICITY',
    },
  ],
  WALLET: [
    {
      label: 'TOPUP',
      value: 'TOPUP',
    },
  ],
};

const issueTypeOptions = {
  WITHDRAWAL: [
    {
      label: 'Transaction is Approved but did not settle in the agents wallet',
      value: 'Transaction is Approved but did not settle in the agents wallet',
    },
    {
      label: 'Transaction Declined but customer was debited',
      value: 'Transaction Declined but customer was debited',
    },
    {
      label: 'My machine is showing ‘tempered’',
      value: 'My machine is showing ‘tempered’',
    },
    {
      label: 'My machine printed decline but my wallet was credited',
      value: 'My machine printed decline but my wallet was credited',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  TRANSFER: [
    {
      label: 'The money I sent is showing Successful but value didn’t get to the recipient',
      value: 'The money I sent is showing Successful but value didn’t get to the recipient',
    },
    {
      label: 'I did a transfer transaction and the status is still showing Pending',
      value: 'I did a transfer transaction and the status is still showing Pending',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  BILLS: [
    {
      label: 'Customer didn’t get value',
      value: 'Customer didn’t get value',
    },
    {
      label: 'No token generated',
      value: 'No token generated',
    },
    {
      label: 'Accepted no value on Electricity BILLS',
      value: 'Accepted no value on Electricity BILLS',
    },
    {
      label: 'Excess payment on BILLS payment',
      value: 'Excess payment on BILLS payment',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
  WALLET: [
    {
      label: 'Transferred to my wallet but it’s not reflecting',
      value: 'Transferred to my wallet but it’s not reflecting',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ],
};

const requireImageIssueTypes = [
  'Transaction is Approved but did not settle in the agents wallet',
  'Transaction Declined but customer was debited',
];

export { NewIssueForm };
