const bankNames = [
  'ACCESS BANK NIGERIA',
  'ACCESS BANK PLC (DIAMOND)',
  'ACCESS MOBILE',
  'AFRIBANK NIGERIA PLC',
  'Aso Savings and Loans',
  'Citibank',
  'ECOBANK NIGERIA PLC',
  'Ecobank Mobile',
  'ENTERPRISE BANK LIMITED',
  'FIDELITY BANK PLC',
  'FIRST CITY MONUMENT BANK PLC',
  'FIRST BANK PLC',
  'FBN MOBILE',
  'FSDH Merchant Bank',
  'GTBANK PLC',
  'GTBank Mobile Money',
  'HERITAGE BANK',
  'JAIZ BANK',
  'Providus Bank',
  'PAYCOM',
  'Parkway',
  'PARALLEX BANK',
  'Stanbic Mobile',
  'STANBIC IBTC BANK PLC',
  'STERLING BANK PLC',
  'KEYSTONE BANK PLC',
  'SKYE BANK PLC',
  'STANDARD CHARTERED BANK NIGERIA LIMITED',
  'UNION BANK OF NIGERIA PLC',
  'UNITY BANK PLC',
  'UNITED BANK FOR AFRICA PLC',
  'WEMA BANK PLC',
  'ZENITH BANK PLC',
  'ZENITH Mobile',
];

export const getBankInputOptions = (banks = bankNames) => {
  return banks.map(bank => {
    return { label: bank, value: bank };
  });
};

export const formatToDigits = (number, max) => {
  if (number.length > 0) {
    number = number.replace(/[^0-9]/g, '');
  }
  return number.slice(0, max);
};

export const formatDate = date => {
  return date.split('/').reverse().join('-');
};
