import React from 'react';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ImcCollectionForm } from 'src/skins/venus/components/collections';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    h2 {
      font-size: large;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 2rem 0;
  }
`;

const ImcCollection = () => {
  const history = useHistory();
  const { vendors } = useSelector(state => state.COLLECTION);
  const vendor = vendors.find(x => x.vendorCode === 'imc') || {};

  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="collections">
        <Wrapper>
          <div className="header">
            <div>
              <Icon name="back" onClick={() => history.push('/dashboard/collections')} />
              <h2 style={{ marginLeft: '0.5rem' }}>{vendor.vendorName || 'Merchant Center'}</h2>
            </div>
            <img src={UserIcon} alt="USER" />
          </div>

          <section>
            <ImcCollectionForm />
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default ImcCollection;
