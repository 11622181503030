import { BiQuestionMark } from 'react-icons/bi';
import { FaMoneyBill } from 'react-icons/fa';
import {
  FiHome,
  FiUser,
  FiMail,
  FiLock,
  FiEye,
  FiEyeOff,
  FiPhone,
  FiCalendar,
  FiMapPin,
  FiSmartphone,
  FiBold,
  FiSearch,
  FiShoppingBag,
  FiPlus,
  FiList,
  FiSettings,
  FiDollarSign,
  FiChevronRight,
  FiChevronLeft,
  FiSend,
  FiFilter,
  FiArrowLeft,
  FiArrowDownLeft,
  FiArrowUpRight,
  FiCopy,
  FiEdit,
} from 'react-icons/fi';

/**
 * FeatherIcon PropType Definition
 * @typedef {Object} FeatherIconProps
 * @property {React} default
 * @property {React} account
 * @property {React} home
 * @property {React} mail
 * @property {React} lock
 * @property {React} eye
 * @property {React} eyeOff
 * @property {React} phone
 * @property {React} calendar
 * @property {React} locationOn
 * @property {React} accountNumber
 * @property {React} bank
 * @property {React} search
 * @property {React} wallet
 * @property {React} plus
 * @property {React} list
 * @property {React} settings
 * @property {React} dollar
 * @property {React} chevronRight
 * @property {React} chevronLeft
 * @property {React} send
 * @property {React} money
 *
 */

/**
 * @description FeatherIcon Props
 * @type {FeatherIconProps}
 */
const featherIcons = {
  default: BiQuestionMark,
  account: FiUser,
  home: FiHome,
  mail: FiMail,
  lock: FiLock,
  eye: FiEye,
  eyeOff: FiEyeOff,
  phone: FiPhone,
  calendar: FiCalendar,
  locationOn: FiMapPin,
  accountNumber: FiSmartphone,
  bank: FiBold,
  search: FiSearch,
  wallet: FiShoppingBag,
  plus: FiPlus,
  list: FiList,
  settings: FiSettings,
  dollar: FiDollarSign,
  chevronRight: FiChevronRight,
  chevronLeft: FiChevronLeft,
  send: FiSend,
  filter: FiFilter,
  back: FiArrowLeft,
  credit: FiArrowDownLeft,
  debit: FiArrowUpRight,
  copy: FiCopy,
  money: FaMoneyBill,
  edit: FiEdit,
};

export { featherIcons };
