import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Wrapper = styled.div`
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.05), inset 0px 2px 2px rgba(51, 51, 51, 0.05);

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.color.pluto.lightestGray};
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 0;
    /* background-color: #ffffff; */
    text-decoration: none;
    color: currentColor;
    outline: none;

    & > :last-child {
      width: 100%;
    }
  }

  .text-small {
    font-size: small;
  }

  .text-right {
    text-align: right;
  }

  .color-textGray {
    color: ${props => props.theme.color.mars.gray || props.theme.color.mars.gray};
  }

  .color-blue {
    color: ${props => props.theme.color.primary};
  }

  .color-red {
    color: ${props => props.theme.color.pluto.debitColor};
  }

  .font-weight-600 {
    font-weight: 600;
  }

  [status='OPEN'] {
    color: #005be6;
  }

  [status='RESOLVED'],
  [status='CLOSED'] {
    color: #08cf82;
  }
`;

export const IssueItem = ({ issue }) => {
  const { _id = '', category = '', transactionRef, issueType, createdAt, status = 'OPEN' } = issue || {};

  return (
    <Wrapper>
      <Link to={`/dashboard/support/${_id}`}>
        <div className="issue-info">
          <div className="flex-space-between">
            <span className="font-weight-600">{category ? `${category} ISSUE` : 'AN ISSUE'}</span>
            <span className="font-weight-600" status={status}>
              {status}
            </span>
          </div>
          <div className="flex-space-between text-small">
            <span className="color-textGray">{transactionRef || issueType || ''}</span>
            <span className="text-right" style={{ width: '13rem' }}>
              {createdAt ? moment(createdAt).fromNow() : ''}
            </span>
          </div>
        </div>
      </Link>
    </Wrapper>
  );
};

/**
 * IssueItem PropType Definition
 * @typedef {Object} IssueItemProps
 * @property {Object} issue
 */
IssueItem.propTypes = {
  issue: PropTypes.object,
};

export default IssueItem;
