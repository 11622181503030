import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MarsButton } from 'src/skins/mars/components/common';
import { TextInput } from 'src/skins/mars/components/inputs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { formatToDigits } from 'src/utils/helpers';
import { requestForgot } from 'src/store/actions/userActions';
import { AccountVerificationModal } from 'src/skins/mars/modals';

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [phoneData, setPhoneData] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const platform = useSelector(state => state.MMO.shortCode);
  const { isLoading } = useSelector(state => state.USER);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const onSubmit = data => {
    const { phoneNumber } = data;

    dispatch(
      requestForgot(phoneNumber, success => {
        setPhoneData(+phoneNumber);
        success && toggleModal();
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 style={{ marginBottom: '0.5rem' }}>Forgot password</h3>
          <p style={{ maxWidth: '280px' }}>Enter your phone number and we will send you an OTP</p>
        </div>

        <br />

        <div>
          <TextInput
            type="tel"
            label="Phone Number"
            placeholder="Phone Number"
            name="phoneNumber"
            ref={register({ required: true })}
            icon="phone"
            inputmode="numeric"
            pattern="[0-9]*"
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 11);
            }}
            errors={
              <React.Fragment>{errors.phoneNumber && <p className="error">Phone number is required</p>}</React.Fragment>
            }
          />
        </div>

        <br />

        <MarsButton type="submit" text="Send Reset Code" size="large" isLoading={isLoading.requestForgot} />
      </form>

      <br />

      <div className="color-gray text-center">
        <h6>
          Don’t have an account? <Link to="/auth#register">Sign up here</Link>{' '}
        </h6>
        <br />
        <h6>
          Forgot account access? <Link to="/auth#forgot">Reset password</Link>
        </h6>
      </div>

      <AccountVerificationModal
        userData={{ phoneNumber: phoneData, platform }}
        isOpen={modalIsOpen}
        closeModal={toggleModal}
      />
    </>
  );
};

export default ForgotPasswordForm;
