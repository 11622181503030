import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'src/skins/common/components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :first-child {
    color: ${props => props.theme.color.primary};
    font-size: 24px;
    font-weight: bold;

    &[sizing='small'] {
      font-size: 16px;
    }

    &[theme='secondary'] {
      color: ${props => props.theme.color.secondary};
    }
  }

  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    padding-top: 2px;
  }
`;

export const VenusButton = props => {
  const { size, type, text, onClick, isLoading, disabled, theme } = props;
  return (
    <Wrapper>
      <span sizing={size} theme={theme}>
        {text}
      </span>
      {isLoading ? (
        <span className="spinner-wrapper">
          <Spinner theme="primary" />
        </span>
      ) : (
        <button type={type} className="venus--button-circle" onClick={onClick} theme={theme} disabled={disabled}>
          ⮕
        </button>
      )}
    </Wrapper>
  );
};

VenusButton.defaultProps = {
  size: 'large',
  isLoading: false,
  disabled: false,
  className: '',
  type: 'button',
  text: ' ',
  theme: 'primary',
};

/**
 * VenusButton PropType Definition
 * @typedef {Object} VenusButtonProps
 * @property {('small'|'large')} size
 * @property {boolean} isLoading
 * @property {boolean} disabled
 * @property {string} className
 * @property {string} type
 * @property {string} text
 * @property {string} theme
 * @property {Function} onClick
 */
VenusButton.propTypes = {
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.string,
  onClick: PropTypes.func,
};

export default VenusButton;
