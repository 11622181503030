import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { infoAlert } from 'src/store/actions/alertActions';
import { Icon } from 'src/skins/common/icon';
import { VenusModal, VenusButton } from 'src/skins/venus/components/common';
import { copyToClipboard } from 'src/utils';

const ViewDevicePinModal = ({ isOpen, closeModal, generatedDevicePin }) => {
  const dispatch = useDispatch();
  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  return (
    <VenusModal isOpen={isOpen} closeModal={() => null}>
      <div>
        <h5 className="color-primary">Generated Device PIN</h5>
        <p style={{ marginTop: '0.5rem' }}>
          This PIN cannot be repeated, in case you forget it, just generate a new one
        </p>

        <br />

        <div style={generatedPinWrapperStyle}>
          <div className="text-xl text-center">{generatedDevicePin}</div>
          <div style={{ cursor: 'pointer' }}>
            <Icon name="copy" size="small" onClick={() => copyToClipboard('device-pin-input', copiedAlert)} />
          </div>
        </div>
        <input id="device-pin-input" type="text" defaultValue={generatedDevicePin} style={{ opacity: 0, height: 0 }} />

        <br />
        <br />

        <VenusButton type="button" text="Got It" size="large" onClick={closeModal} />
      </div>
    </VenusModal>
  );
};

const generatedPinWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',
  justifyContent: 'center',
};

ViewDevicePinModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  device: null,
  generatedDevicePin: null,
};

/**
 * ViewDevicePinModal PropType Definition
 * @typedef {Object} ViewDevicePinModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {object} device
 * @property {String} generatedDevicePin
 */
ViewDevicePinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  device: PropTypes.object,
  generatedDevicePin: PropTypes.string,
};

export { ViewDevicePinModal };
