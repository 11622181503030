import { SET_MMO, SET_MMO_ACTIONS, SET_MMOS, SET_MMO_SHORT_CODE } from 'src/store/types';

const initialState = {
  shortCode: '',
  mmos: [],
  mmo: { skin: '' },
  isLoading: {
    state: false,
    getAllMmos: false,
    getSingleMmo: true,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MMOS:
      return { ...state, mmos: action.payload };
    case SET_MMO:
      return {
        ...state,
        mmo: { ...action.payload, primaryColor: action.payload.brandColor || action.payload.primaryColor },
      };
    case SET_MMO_SHORT_CODE:
      return { ...state, shortCode: action.payload };
    case SET_MMO_ACTIONS:
      return {
        ...state,
        isLoading: {
          state: action.payload.state,
          [action.payload.action]: action.payload.state,
        },
      };
    default:
      return state;
  }
}
