import React from 'react';
import { useHistory } from 'react-router-dom';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { NewIssueForm } from 'src/skins/venus/components/support';
import styled from 'styled-components';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  section {
    margin: 1rem 0;
  }

  section:last-of-type {
    margin: 1rem 0;
    margin-bottom: 3rem;
  }

  .date-heading {
    display: block;
    margin: 1.25rem 0 0.75rem 0;
  }
`;

export const NewSupport = () => {
  const history = useHistory();

  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true}>
        <Wrapper>
          <h4>Report New Issue</h4>

          <section>
            <NewIssueForm />
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default NewSupport;
