import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/skins/common/icon';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const Header = props => {
  const { pageTitle, backLink } = props;
  const history = useHistory();
  return (
    <Wrapper>
      {backLink && <Icon name="chevronLeft" onClick={() => history.push(backLink || '/dashboard')} />}
      <div className="header">
        <h2>{pageTitle}</h2>
        <img src={UserIcon} alt="USER" />
      </div>
    </Wrapper>
  );
};

(Header.defaultProps = {
  backLink: '',
}),
  (Header.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    backLink: PropTypes.string,
  });

export { Header };
