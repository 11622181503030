import { SET_COLLECTION_LOADING, SET_COLLECTION_VENDORS, SET_COLLECTION_INVOICE } from 'src/store/types';

const initialState = {
  invoice: {},
  vendors: [],
  isLoading: {
    state: false,
    vendors: false,
    getInvoiceData: false,
    confirmInvoice: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COLLECTION_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.action]: action.payload.state,
        },
      };
    case SET_COLLECTION_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };
    case SET_COLLECTION_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
}
