import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.color.primary};
  padding: 0.5rem 1.5rem;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: currentColor;
  }

  .name {
    font-weight: 600;
  }

  .account {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    & > :first-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(50% - 1.5rem);
    }

    & > :last-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      align-self: auto;
    }

    & > :first-child {
      margin-right: 1.5rem;
    }

    & > :last-child {
      font-weight: 600;
    }
  }
`;

export const TransferBeneficiaryItem = props => {
  const { beneficiary, method } = props;
  const { name, bank, accountNumber, iuwi } = beneficiary;

  const getData = () => {
    return method === 'peer' ? { name, iuwi, state: true } : { name, bank, accountNumber, state: true };
  };

  return (
    <Wrapper>
      <Link
        to={{
          pathname: `/dashboard/transfer/${method}`,
          state: getData(),
        }}
      >
        <div className="name">{name || ''}</div>
        <div className="account">
          <span>{accountNumber || iuwi}</span>
          <span>{bank || ''}</span>
        </div>
      </Link>
    </Wrapper>
  );
};

TransferBeneficiaryItem.defaultPropTypes = {
  beneficiary: {},
  method: '',
};

/**
 * TransferBeneficiaryItem PropType Definition
 * @typedef {Object} TransferBeneficiaryItem
 * @property {Object} beneficiary
 * @property {String} method
 */
TransferBeneficiaryItem.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  method: PropTypes.string.isRequired,
};

export default TransferBeneficiaryItem;
