import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { Button } from 'src/skins/common/components';
import { TransactionItem } from 'src/skins/mars/components/aggregator';
import { formatCurrency } from 'src/utils';
import styled from 'styled-components';
import moment from 'moment';
import LODASH from 'lodash';
import { useSelector } from 'react-redux';
import { MarsModal } from 'src/skins/mars/components/common';

const balance = 3800000;
const LIMIT = 10;
const aggregatorTransactionsUrl = '/dashboard/aggregator/transactions';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  .hi-username {
    display: flex;
    align-items: center;
    h5 {
      font-weight: 500;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;
    }
  }

  .commission-balance-card {
    margin: 1rem 0 2.5rem 0;
  }

  section {
    margin: 1rem 0;
  }

  section:last-of-type {
    margin: 1rem 0;
    margin-bottom: 3rem;
  }
`;

export const AggregatorDashboard = () => {
  const history = useHistory();
  const { user } = useSelector(state => state.USER);
  const username = user.firstName || '';

  const [fetchingTransactions, setFetchingTransactions] = useState(true);
  const [transactions] = useState(fakeTransactions);

  setTimeout(() => {
    setFetchingTransactions(false);
  }, 1000);

  const sortedTransactions = LODASH.sortBy(transactions, 'createdAt');
  const reverseSortedTransactions = LODASH.reverse(sortedTransactions);
  const mappedTransactions = reverseSortedTransactions.splice(0, LIMIT).map((transaction, index) => {
    return <TransactionItem key={index} transaction={transaction} />;
  });

  return (
    <DashboardLayout>
      <MarsModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="dashboard">
        <Wrapper>
          <div className="header">
            <div className="hi-username">
              <h5>Welcome {username},</h5>
            </div>
          </div>

          {/* TODO: update aggregator commision balance */}
          <div className="commission-balance-card">
            <div className="card card-primary">
              <div className="title">{formatCurrency(balance || 0, 'NGN')}</div>
              <div className="subtitle">Your Total Commission</div>
            </div>
          </div>

          <section>
            <div className="flex-space-between">
              <h5>Latest Transaction</h5>
              <Link to={aggregatorTransactionsUrl} className="like-link">
                <h5>View all</h5>
              </Link>
            </div>
            <div>
              {fetchingTransactions === true && <div className="loading-state">fetching transactions...</div>}
              {fetchingTransactions === false && transactions.length > 0 && (
                <>
                  {mappedTransactions}
                  <br />
                  <Link to={aggregatorTransactionsUrl} className="like-link">
                    <Button size="large" text="View All" className="block" />
                  </Link>
                </>
              )}
              {fetchingTransactions === false && transactions.length === 0 && (
                <div className="empty-state color-gray">No Transactions Found</div>
              )}
            </div>
          </section>
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

const fakeTransactions = [
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
  {
    accountName: 'Omadoye Jedidiah',
    narrative: 'Recharge card',
    cancelled: Math.random() * 10 > 8 ? true : false,
    amount: Math.round(Math.random() * 10) * 1000,
    action: Math.random() * 10 > 5 ? 'credit' : 'debit',
    createdAt: moment(new Date())
      .subtract(Math.random() * 10, 'days')
      .toDate(),
  },
];

export default AggregatorDashboard;
