import React, { useState, useEffect } from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { InnerPageHeader, FeedbackItem } from 'src/skins/jupiter/components/support';
import { formatCurrency } from 'src/utils';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import LODASH from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleIssue, setIssue } from 'src/store/actions/selfcareActions';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }

  section {
    margin: 1rem 0;
    margin-bottom: 2rem;
  }

  .issue-details {
    & > * {
      display: flex;
      align-items: center;
      padding: 0.75rem 0;
      font-size: 0.875rem;
      border-bottom: 1px solid rgba(196, 196, 196, 0.2);

      & > * {
        width: 100%;
      }

      & > :first-child {
        font-weight: bold;
        max-width: 20rem;
      }

      & > :last-child {
        color: ${props => props.theme.color.primary};
        text-align: right;
      }

      [status='OPEN'] {
        color: #005be6;
        text-transform: uppercase;
        font-weight: 600;
      }

      [status='CLOSED'] {
        color: #08cf82;
        text-transform: uppercase;
        font-weight: 600;
      }

      &.flex-column {
        flex-direction: column;
        align-items: initial;

        & > :last-child {
          text-align: left;
        }
      }
    }
  }

  .section-title {
    font-size: 0.75rem;
    font-weight: bold;
    color: #828282;
  }
`;

export const SupportDetails = () => {
  const { id: issueId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, issue } = useSelector(state => state.SELFCARE);
  const fetchingIssue = isLoading.getSingleIssue;

  useEffect(() => {
    dispatch(setIssue({}));
    dispatch(getSingleIssue(issueId));
  }, []);

  const [feedbacks] = useState(issue.feedbacks || []);
  const sortedFeedbacks = LODASH.sortBy(feedbacks, 'createdAt');
  const reverseSortedFeedbacks = LODASH.reverse(sortedFeedbacks);
  const mappedFeedbacks = reverseSortedFeedbacks.map((feedback, index) => {
    return <FeedbackItem key={index} feedback={feedback} />;
  });

  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader>
          <h4>Issue In Detail</h4>
        </InnerPageHeader>

        <section>
          <div>
            {fetchingIssue === true && <div className="loading-state">fetching issue...</div>}
            {fetchingIssue === false && issue._id && (
              <React.Fragment>
                <div className="issue-details">
                  <div>
                    <span>Status:</span>
                    <span status={issue.status || ''}>{issue.status || ''}</span>
                  </div>
                  <div>
                    <span>Category:</span>
                    <span>{issue.category || ''}</span>
                  </div>
                  <div>
                    <span>Transaction Type:</span>
                    <span>{issue.transactionType || ''}</span>
                  </div>
                  <div>
                    <span>Issue Type:</span>
                    <span>{issue.issueType || ''}</span>
                  </div>
                  {issue.comment && (
                    <div className="flex-column">
                      <span>Comment/Explanation:</span>
                      <span>{issue.comment || ''}</span>
                    </div>
                  )}
                  {issue.category !== 'WITHDRAWAL' && (
                    <div>
                      <span>Transaction Ref:</span>
                      <span>{issue.transactionRef || ''}</span>
                    </div>
                  )}
                  <div>
                    <span>Amount:</span>
                    <span>#{formatCurrency(issue.amount || 0, '')}.00</span>
                  </div>
                  {issue.category === 'WITHDRAWAL' && (
                    <React.Fragment>
                      <div>
                        <span>Masked PAN Number:</span>
                        <span>{issue.pan || ''}</span>
                      </div>
                      <div>
                        <span>RRN Number:</span>
                        <span>{issue.rrn || ''}</span>
                      </div>
                      {issue.category === 'WITHDRAWAL' && issue.image && (
                        <div>
                          <span>Screenshot:</span>
                          <span>
                            <img src={issue.image} alt="receipt screenshot" style={{ height: '100px' }} />
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  <div>
                    <span>Issue date:</span>
                    <span>{moment(issue.createdAt || '').format('MMM D, Y')}</span>
                  </div>
                  <div>
                    <span>Issue time:</span>
                    <span>{moment(issue.createdAt || '').format('h:m a')}</span>
                  </div>
                </div>

                <br />

                <section>
                  <div className="flex-space-between">
                    <h5 className="section-title">{feedbacks.length || 0} FEEDBACKS</h5>
                  </div>
                  <div>
                    {feedbacks.length > 0 && mappedFeedbacks}
                    {feedbacks.length === 0 && <div className="empty-state color-gray">No Feedbacks Yet</div>}
                  </div>
                </section>
              </React.Fragment>
            )}
            {fetchingIssue === false && !issue._id && <div className="empty-state color-gray">Issue Not Found</div>}
          </div>
        </section>

        <br />
      </Wrapper>
    </DashboardLayout>
  );
};

export default SupportDetails;
