import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/skins/common/components';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { useHistory } from 'react-router-dom';
import { billsPageData, phcnImages } from 'src/utils/data';
import { Icon } from 'src/skins/common/icon';

import UserIcon from 'src/assets/svgs/icon-user.svg';
import { getElectricityTypes } from 'src/store/actions/billActions';

const pageTitle = 'Bills payment';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
    }
  }

  section {
    margin: 1rem 0;

    h6 {
      color: ${props => props.theme.color.darkGray};
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .bills-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    max-width: 100%;
    margin: 2rem 0;

    & > button {
      display: inline-block;
      padding: 0;
      background: transparent;
      color: inherit;
      border: 0;
      outline: none;
      font-size: 0.7rem;

      & > div {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }

      &:focus {
        & > div {
          box-shadow: none;
        }
      }
    }

    .action-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 5.5rem;
      padding: 0.5rem;
      font-size: 0.7rem;

      img {
        height: 2rem;
      }

      &:hover {
        cursor: pointer;

        img {
          opacity: 0.8;
        }
      }
    }
  }
`;

const Bills = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { electricityTypes, isLoading } = useSelector(state => state.BILLS);

  useEffect(() => {
    if (electricityTypes.length < 1) {
      dispatch(getElectricityTypes());
    }
  }, []);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div>
            <Icon name="back" onClick={() => history.push('/dashboard')} />
            <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <section>
          <h6>Recharge</h6>
          <div className="bills-grid">
            {billsPageData.recharge.map((item, index) => (
              <button key={index} onClick={() => history.push('/dashboard/bills/beneficiaries', { type: item.type })}>
                <Card className="action-card">
                  {item.imgSrc ? <img src={item.imgSrc} alt={item.name} /> : <>{item.name}</>}
                  <br />
                  <div>{item.name}</div>
                </Card>
              </button>
            ))}
          </div>
        </section>

        <section>
          <h6>TV Cables</h6>
          <div className="bills-grid">
            {billsPageData.tvcables.map((item, index) => (
              <button key={index} onClick={() => history.push('/dashboard/bills/beneficiaries', { type: item.type })}>
                <Card className="action-card">
                  {item.imgSrc ? <img src={item.imgSrc} alt={item.name} /> : <>{item.name}</>}
                </Card>
              </button>
            ))}
          </div>
        </section>

        <section>
          <h6>PHCN</h6>
          <div className="bills-grid">
            {isLoading.getElectricityTypes && <p>Loading...</p>}
            {!isLoading.getElectricityTypes &&
              electricityTypes.map((item, index) => (
                <button
                  key={index}
                  onClick={() => history.push('/dashboard/bills/beneficiaries', { type: item.disco })}
                >
                  <Card className="action-card">
                    {phcnImages[item.disco] ? (
                      <img src={phcnImages[item.disco]} alt={item.disco} />
                    ) : (
                      <p>{item.disco.toUpperCase()}</p>
                    )}
                  </Card>
                </button>
              ))}
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Bills;
