import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/skins/common/components/Button';
import { TextInput, CheckboxInput, SelectInput } from 'src/skins/mars/components/inputs';
import { TransactionSummaryModal, TransactionStatusModal, TransactionPinModal } from 'src/skins/mars/modals';
import { formatToDigits } from 'src/utils/helpers';
import { airtimeNetworkOption } from 'src/utils/data';
import { getBillSummary, confirmBillTransaction, getDataPlan } from 'src/store/actions/billActions';

export const BuyMobileDataForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionSummary, setTransactionSummary] = useState({});

  const [showAirtimeSummaryModal, setShowAirtimeSummaryModal] = useState(false);
  const [showTransactionStatusModal, setShowTransactionStatusModal] = useState(false);
  const [transactionPinModalStatus, setTransactionPinModalStatus] = useState(false);

  const { dataPlan, isLoading } = useSelector(state => state.BILLS);

  const getDataPlanOptions = network => {
    if (dataPlan[network]) {
      return dataPlan[network].map(plan => {
        return { value: plan.planId, label: plan.description, amount: plan.amount };
      });
    } else {
      return [];
    }
  };

  const beneficiary = history.location.state || {};

  useEffect(() => {
    if (beneficiary.state) {
      dispatch(getDataPlan(beneficiary.network));
    }
  }, []);

  const toggleAirtimeSummaryModal = () => setShowAirtimeSummaryModal(!showAirtimeSummaryModal);
  const toggleTransactionPinModalStatus = () => {
    setTransactionPinModalStatus(!transactionPinModalStatus);
  };

  const toggleTransactionStatusModal = () => {
    setShowTransactionStatusModal(!showTransactionStatusModal);
    if (transactionStatus.includes('success')) {
      history.push('/dashboard/bills');
    }
  };

  const { register, handleSubmit, setValue, clearErrors, watch, control } = useForm({
    defaultValues: {
      network: beneficiary.network || '',
      name: beneficiary.name || '',
      mobile: beneficiary.mobile || '',
      saveBeneficiary: false,
      dataPlan: '',
    },
  });

  const onSubmit = data => {
    const payload = {
      amount: +data.amount,
      network: data.network || beneficiary.network,
      mobile: +data.mobile || +beneficiary.mobile,
      planId: data.dataPlan,
    };

    dispatch(
      getBillSummary(payload, 'data', data => {
        if (data) {
          setTransactionSummary(data);
          toggleAirtimeSummaryModal();
          return;
        }
        return;
      })
    );
  };

  const handleConfirmMobileDataClick = pin => {
    const payload = {
      amount: transactionSummary.amount,
      mobile: +transactionSummary.phone,
      network: watch('network'),
      planId: watch('dataPlan'),
      pin,
    };

    if (watch('saveBeneficiary')) {
      payload.saveBeneficiary = watch('saveBeneficiary');
      payload.name = watch('name');
    }

    dispatch(
      confirmBillTransaction(payload, 'data', (_success, error) => {
        toggleAirtimeSummaryModal();
        if (error) {
          setTransactionStatus('failed');
          return toggleTransactionStatusModal();
        }
        setTransactionStatus('successful');
        toggleTransactionStatusModal();
        return;
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            control={control}
            name="network"
            render={() => (
              <SelectInput
                label="Network"
                name="network"
                ref={register}
                icon="bank"
                required
                isClearable={false}
                disabled={beneficiary.state}
                options={airtimeNetworkOption}
                value={watch('network') || null}
                onChange={selectedOption => {
                  setValue('network', selectedOption?.value || '');
                  clearErrors('network');
                  setValue('dataPlan', '');
                  dispatch(getDataPlan(selectedOption?.value || ''));
                  setValue('amount', '');
                  clearErrors('amount');
                }}
              />
            )}
          />
          {!isLoading.getDataPlan && (
            <Controller
              control={control}
              name="dataPlan"
              render={() => (
                <SelectInput
                  label="Data Plan"
                  name="dataPlan"
                  ref={register}
                  icon="bank"
                  required
                  isClearable={false}
                  options={getDataPlanOptions(watch('network'))}
                  value={watch('dataPlan') || null}
                  onChange={selectedOption => {
                    setValue('dataPlan', selectedOption?.value || '');
                    clearErrors('dataPlan');
                    setValue('amount', selectedOption?.amount || '');
                    clearErrors('amount');
                  }}
                />
              )}
            />
          )}
          <TextInput
            type="text"
            label="Amount"
            name="amount"
            ref={register}
            inputmode="numeric"
            pattern="[0-9]*"
            icon="money"
            required
            onInput={e => {
              let { value } = e.target;
              value = formatToDigits(value);
              e.target.value = value.slice(0, 15);
            }}
            readOnly={Boolean(watch('amount'))}
          />
          <TextInput
            type="text"
            label="Phone Number"
            name="mobile"
            ref={register}
            icon="phone"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            disabled={beneficiary.state}
            onInput={e => {
              e.target.value = formatToDigits(e.target.value, 11);
              return e;
            }}
          />
          <CheckboxInput
            label="Save as beneficiary"
            name="saveBeneficiary"
            ref={register}
            disabled={beneficiary.state}
          />

          {(watch('saveBeneficiary') || beneficiary.state) && (
            <TextInput
              type="text"
              label="Name"
              name="name"
              ref={register}
              icon="account"
              required
              disabled={beneficiary.state}
            />
          )}
        </div>

        <br />

        <Button type="submit" text="Buy Data Plan" size="large" isLoading={isLoading.getBillSummary} />
      </form>

      <TransactionSummaryModal
        transfer={transactionSummary}
        isOpen={showAirtimeSummaryModal}
        closeModal={toggleAirtimeSummaryModal}
        handleConfirmTransferClick={toggleTransactionPinModalStatus}
      />

      {transactionPinModalStatus && (
        <TransactionPinModal
          isOpen={transactionPinModalStatus}
          closeModal={toggleTransactionPinModalStatus}
          clickIsLoading={isLoading.confirmBillTransaction}
          handleConfirmTransactionClick={handleConfirmMobileDataClick}
        />
      )}

      <TransactionStatusModal
        isOpen={showTransactionStatusModal}
        closeModal={toggleTransactionStatusModal}
        status={transactionStatus}
      />
    </>
  );
};

export default BuyMobileDataForm;
