import React from 'react';
import PropTypes from 'prop-types';
import { icons } from './icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  .icon {
    color: inherit;
    align-items: 'center';
  }
  .x-small {
    font-size: 1.2rem;
  }
  .small {
    font-size: 1.5rem;
  }
  .medium {
    font-size: 1.7rem;
  }
  .large {
    font-size: 2rem;
  }
`;

/**
 * @description Icon Component
 * @param {IconProps} props
 */
const Icon = props => {
  const { name, size, className, onClick } = props;
  const iconPack = useSelector(state => state.MMO.mmo.iconPack);

  const IconType = ['material', 'feather'].includes(iconPack) ? icons[iconPack][name] : icons['material'][name];

  return (
    <Wrapper>
      <IconType className={`icon ${size} ${className}`} onClick={onClick} />
    </Wrapper>
  );
};

Icon.defaultProps = {
  name: 'default',
  size: 'medium',
  onClick: () => {},
};

/**
 * TextInput PropType Definition
 * @typedef {Object} IconProps
 * @property {('account'|'home'|'mail'|'lock'|'eye'|'eyeOff'|'phone'|'calendar'|'locationOn'|'accountNumber'|'bank'|'search'|'wallet'|'plus'|'list'|'settings'|'dollar'|'chevronRight'|'chevronLeft'|'money')} name
 * @property {('x-small|small'|'medium'|'large')} size
 * @property {string} className
 * @property {Function} onClick
 */
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export { Icon };
