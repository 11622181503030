import clients from 'src/clients';
import { SET_MMO, SET_MMOS, SET_MMO_ACTIONS, SET_MMO_SHORT_CODE } from 'src/store/types';
import { errorAlert } from './alertActions';

const { appService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_MMO_ACTIONS,
    payload: { action, state },
  };
};

const setMmos = mmos => {
  return { type: SET_MMOS, payload: mmos };
};

const setMmo = mmo => {
  return { type: SET_MMO, payload: mmo };
};

export const setMmoShortCode = shortCode => {
  return { type: SET_MMO_SHORT_CODE, payload: shortCode };
};

export const getAllMmos = () => dispatch => {
  dispatch(setIsLoading('getAllMmos', true));
  appService
    .get('/mmos?limit=100')
    .then(res => {
      dispatch(setMmos(res.data.mmos));
      dispatch(setIsLoading('getAllMmos', false));
    })
    .catch(() => {
      dispatch(errorAlert('Server error. Please contact Admin'));
      dispatch(setIsLoading('getAllMmos', false));
    });
};

export const getSingleMmo = mmo => dispatch => {
  dispatch(setIsLoading('getSingleMmo', true));
  appService
    .get(`/mmos/${mmo}`)
    .then(res => {
      dispatch(setMmo(res.data));
      dispatch(setIsLoading('getSingleMmo', false));
    })
    .catch(() => {
      dispatch(errorAlert('Technical Error'));
      dispatch(setIsLoading('getSingleMmo', false));
    });
};
