import React from 'react';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import {
  AirtimeBeneficiaries,
  MobileDataBeneficiaries,
  TvCablesBeneficiaries,
  ElectricityBeneficiaries,
} from 'src/skins/venus/components/beneficiaries/bills';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  section {
    margin: 1rem 0;
  }
`;

const title = {
  data: 'Buy Mobile Data',
  airtime: 'Buy Airtime',
  dstv: 'Buy Dstv',
  startimes: 'Buy Startimes',
  gotv: 'Buy Gotv',
  ikedc: 'Buy Electricity',
  ekedc: 'Buy Electricity',
  kedco: 'Buy Electricity',
  phed: 'Buy Electricity',
  jed: 'Buy Electricity',
  ibedc: 'Buy Electricity',
  kaedco: 'Buy Electricity',
  aedc: 'Buy Electricity',
};

const BillsBeneficiaries = () => {
  const history = useHistory();
  const billType = history.location.state.type;
  const electricityTypes = useSelector(state => state.BILLS.electricityTypes);

  const discos = electricityTypes.map(type => type.disco);

  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="bills">
        <Wrapper>
          <h2>{title[billType] || ''}</h2>

          <section>
            {billType === 'airtime' && <AirtimeBeneficiaries />}
            {billType === 'data' && <MobileDataBeneficiaries />}

            {['dstv', 'startimes', 'gotv'].indexOf(billType) > -1 && <TvCablesBeneficiaries />}
            {discos.indexOf(billType) > -1 && <ElectricityBeneficiaries />}
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default BillsBeneficiaries;
