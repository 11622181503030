import clients from 'src/clients';
import { SET_COLLECTION_LOADING, SET_COLLECTION_VENDORS, SET_COLLECTION_INVOICE } from 'src/store/types';
import { errorAlert, successAlert } from './alertActions';

const { collectionService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_COLLECTION_LOADING,
    payload: { action, state },
  };
};

export const getVendors = () => dispatch => {
  dispatch(setIsLoading('getVendors', true));
  collectionService
    .get('/invoice/vendors')
    .then(({ data }) => {
      dispatch({
        type: SET_COLLECTION_VENDORS,
        payload: data.data || [],
      });
    })
    .catch(() => dispatch(errorAlert()))
    .finally(() => dispatch(setIsLoading('getVendors', false)));
};

export const setInvoiceData = invoice => {
  return {
    type: SET_COLLECTION_INVOICE,
    payload: invoice,
  };
};

export const getInvoiceData = (invoiceNumber, vendorCode, callback) => dispatch => {
  dispatch(setIsLoading('getInvoiceData', true));
  collectionService
    .get(`/invoice/${invoiceNumber}/${vendorCode}`)
    .then(res => {
      if (res.data && res.data.isValidated === false) {
        return dispatch(errorAlert('Invalid Invoice'));
      }
      dispatch(setInvoiceData(res.data));
      if (callback) callback(res.data);
    })
    .catch(({ response }) => dispatch(errorAlert(response?.data?.message || null)))
    .finally(() => dispatch(setIsLoading('getInvoiceData', false)));
};

/**
 *
 * @param {object} data data
 * @param {number} data.amount amount
 * @param {string} data.invoiceNumber Invoice Number
 * @param {string} data.vendorCode vendorCode
 * @param {(data) => {}} callback
 */
export const getCollectionSummary = (data, callback) => dispatch => {
  dispatch(setIsLoading('getCollectionSummary', true));
  collectionService
    .post('/invoice/summary', data)
    .then(res => {
      if (callback) callback(res.data);
    })
    .catch(({ response }) => dispatch(errorAlert(response?.data?.message || null)))
    .finally(() => dispatch(setIsLoading('getCollectionSummary', false)));
};

/**
 *
 * @param {object} data data
 * @param {string} data.pin Pin
 * @param {string} data.vendorCode vendorCode
 * @param {(data, error) => {}} callback
 */
export const confirmInvoice = (data, callback) => (dispatch, getState) => {
  const { invoice } = getState().COLLECTION || {};
  const payload = {
    amount: invoice.amount,
    invoiceNumber: invoice.invoiceNumber,
    vendorCode: data.vendorCode,
    pin: data.pin,
    invoice,
  };

  dispatch(setIsLoading('confirmInvoice', true));
  collectionService
    .post('/invoice/confirm', payload)
    .then(res => {
      const msg = res?.data?.message || 'Transaction Successful';
      dispatch(successAlert(msg));
      callback && callback(msg, null);
    })
    .catch(err => {
      const msg = err?.response?.data?.message || 'Transaction Failed';
      dispatch(errorAlert(msg));
      callback && callback(null, msg);
    })
    .finally(() => dispatch(setIsLoading('confirmInvoice', false)));
};
