import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100vh;
		display: flex;
		overflow: hidden;
		font-size: 0.8rem;
		align-items: center;
		justify-content: center;
		font-family: Poppins, 'sans-serif';
		color: ${props => props.theme.color.text};
		background-color: ${props => props.theme.color.dark};
	}
	div, span {
		margin: 0;
		padding: 0;
	}
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}
	h1 {
		font-size: calc(0.4rem + 1.5em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h2 {
		font-size: calc(0.4rem + 1.4em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h3 {
		font-size: calc(0.4rem + 1.3em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h4 {
		font-size: calc(0.4rem + 1em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h5 {
		font-size: calc(0.4rem + 0.9em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h6 {
		font-size: calc(0.4rem + 0.4em);
		font-weight: 600;
	}
	p {
		font-size: calc(0.4rem + 0.5em);
		font-weight: 500;
	}
	a {
		color: ${props => props.theme.color.primary};
		text-decoration: none;

		&:focus,
		&:hover {
			color: ${props => props.theme.color.primary}BB;
		}
	}
	.error {
		color: ${props => props.theme.color.jupiter?.danger || props.theme.color.danger}
	}
	.text-decoration-none {
		text-decoration: none;
	}

	.color {
		&-primary {
			color: ${props => props.theme.color.primary};
		}
		&-gray {
			color: ${props => props.theme.color.jupiter.gray || props.theme.color.gray};
		}
		&-red {
			color: ${props => props.theme.color.jupiter.danger || props.theme.color.danger};
		}
	}

	.text {
		&-normal {
			font-size: 1rem;
		}
		&-lg {
			font-size: large;
		}
		&-sm {
			font-size: small;
		}
		&-xs {
			font-size: x-small;
		}
		&-xl {
			font-size: x-large;
		}
		&-left {
			text-align: left;
		}
		&-center {
			text-align: center;
		}
		&-right {
			text-align: right;
		}
		&-bold {
			font-weight: bold;
		}
		&-truncate {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.no-overflow {
		overflow: hidden !important;
	}

	.no-padding {
		padding: 0 !important;
	}

	.flex-space-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.loading-state,
	.empty-state {
		display: flex;
		height: 15vh;
		min-height: 10rem;
		justify-content: center;
		align-items: center;
	}

	.pointer {
		cursor: pointer;
	}

	.rotate-180 {
		transform: rotate(180deg);
	}

	a[as="button"] {
		text-decoration: none;
	}

	${'' /* Jupiter Button Circle */}
	.jupiter--button-circle {
		border: 1px solid transparent;
		background: ${props => props.theme.color.primary};
		color: ${props => props.theme.color.light};
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 100%;
		width: 48px;
		height: 48px;
		min-width: 48px;
		min-height: 48px;
		font-size: 22px;
		outline: none;

		&:focus,
		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		&[theme='secondary'] {
			background: ${props => props.theme.color.secondary};
			color: ${props => props.theme.color.light};
		}
	}

	.MuiButton-root.button {
		font-size: 0.8rem;

		&.large {
			font-size: 0.9rem;
		}
	}

	${'' /* For PageView .container */}
	.container {
		&.light {
			color: ${props => props.theme.color.jupiter.text || props.theme.color.text} !important;
		}
	}
`;

export default GlobalStyle;
