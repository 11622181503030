import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'src/skins/common/icon';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { getMyDevices, setMyDevice, updateDevicePin } from 'src/store/actions/deviceActions';
import { formatCurrency } from 'src/utils';
import { MarsModal } from 'src/skins/mars/components/common';
import {
  ActivateDeviceModal,
  EditDeviceNameModal,
  TransactionPinModal,
  ViewDevicePinModal,
} from 'src/skins/mars/modals';

const pageTitle = 'My Devices';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  section {
    margin: 0;
  }

  .my-device {
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    margin: 1rem 0;
    padding: 0.5rem;

    .my-device-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      text-align: center;
      gap: 0.5rem;
      z-index: 1;

      div:first-child {
        margin-top: -0.5rem;

        & > * {
          color: #f7aa4f;
          font-weight: bold;
          line-height: 100%;
        }
      }
    }

    .device-details {
      display: flex;
      justify-content: space-between;

      & + .device-details {
        margin-top: 0.5rem;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #d7d7d7;
      margin: 0.5rem 0;
    }

    .device-name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
    }

    .device-name {
      font-size: larger;
      font-weight: bold;
      max-width: 10rem;
    }

    .device-name-edit-icon,
    .device-link {
      color: inherit;
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 12px;
      margin: 2px 0;

      &:focus,
      &:hover {
        color: ${props => props.theme.color.primary};
        cursor: pointer;
      }
    }

    .device-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      color: ${props => props.theme.color.primary};
      border: 2px solid ${props => props.theme.color.primary};
      background-color: transparent;
      border-radius: 4px;
      font-weight: bold;

      &:focus,
      &:hover {
        background-color: ${props => props.theme.color.primary}24;
        cursor: pointer;
        outline: none;
      }
    }

    .device-data-title {
      font-size: 12px;
      color: #aaa;
    }

    .os-type {
      display: flex;
      gap: 5px;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .device-data-value {
      font-weight: 600;
      max-width: 10rem;
    }
  }
`;

const Devices = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { myDevice, myDevices, isLoading } = useSelector(state => state.DEVICE);

  const [showActivateDeviceModal, setShowActivateDeviceModal] = useState(false);
  const openActivateDeviceModal = device => {
    setShowActivateDeviceModal(true);
    dispatch(setMyDevice(device));
  };
  const closeActivateDeviceModal = () => {
    setShowActivateDeviceModal(false);
    dispatch(setMyDevice());
  };

  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const openEditDeviceModal = device => {
    setShowEditDeviceModal(true);
    dispatch(setMyDevice(device));
  };
  const closeEditDeviceModal = () => {
    setShowEditDeviceModal(false);
    dispatch(setMyDevice());
  };

  const [showTransactionPinModal, setShowTransactionPinModal] = useState(false);
  const openTransactionPinModal = device => {
    setShowTransactionPinModal(true);
    dispatch(setMyDevice(device));
  };
  const closeTransactionPinModal = device => {
    setShowTransactionPinModal(false);
    dispatch(setMyDevice(device));
  };

  const [generatedDevicePin, setGeneratedDevicePin] = useState(null);
  const closeViewDevicePinModal = () => {
    setGeneratedDevicePin(null);
    dispatch(setMyDevice());
  };

  const generatePin = enteredPin => {
    const data = { posId: myDevice?._id, transactionPin: enteredPin };
    dispatch(
      updateDevicePin(data, {
        success: data => {
          closeTransactionPinModal(myDevice);
          setGeneratedDevicePin(data?.data?.pin);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getMyDevices());
  }, []);

  return (
    <DashboardLayout>
      <MarsModal
        isOpen={true}
        closeModal={() => history.replace('/dashboard')}
        fullheight={true}
        backgroundPage="dashboard"
      >
        <Wrapper>
          <div className="header">
            <h2>{pageTitle}</h2>
          </div>

          <br />

          <section>
            {isLoading.getMyDevices ? (
              <p>Fetching devices ....</p>
            ) : myDevices.length > 0 ? (
              myDevices.map((device, index) => {
                const { _id, deviceName, serialNumber, mapping, os } = device || {};
                const { isActivated } = mapping || {};
                return (
                  <div key={index} className="my-device">
                    {isActivated?.status !== true && (
                      <div className="my-device-overlay">
                        <div>
                          <p>POS Device is not activated,</p>
                          <p>Please fund your wallet to activate it.</p>
                        </div>
                        <button type="button" className="device-button" onClick={() => openActivateDeviceModal(device)}>
                          Fund Wallet
                        </button>
                      </div>
                    )}

                    <div>
                      <div className="device-details">
                        <div className="device-name-wrapper">
                          <div className="device-name text-truncate">{deviceName}</div>
                          <div className="device-name-edit-icon">
                            <Icon name="edit" size="x-small" onClick={() => openEditDeviceModal(device)} />
                          </div>
                        </div>
                        <div>
                          <Link to={`/dashboard/transactions?terminal_id=${_id}`} className="device-link">
                            View Transactions
                          </Link>
                        </div>
                      </div>

                      <div className="device-details">
                        <div>
                          <div className="device-data-title">Serial No.</div>
                          <div className="device-data-value text-truncate">{serialNumber}</div>
                        </div>
                        {os !== 'flutterwave' && (
                          <div>
                            <button
                              type="button"
                              className="device-button"
                              onClick={() => openTransactionPinModal(device)}
                            >
                              Generate PIN
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="os-type">
                        <div className="device-data-title">POS Type:</div>
                        <div className="device-data-value capitalize">{os}</div>
                      </div>
                    </div>

                    <div className="divider" />

                    <div className="device-details">
                      <div>
                        <div className="device-data-title">Transaction Volume</div>
                        <div className="device-data-value text-lg">0</div>
                      </div>
                      <div>
                        <div className="device-data-title">Transaction Value</div>
                        <div className="device-data-value text-right text-lg">{formatCurrency(0)}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No devices found</p>
            )}
          </section>

          {showActivateDeviceModal && (
            <ActivateDeviceModal
              isOpen={showActivateDeviceModal}
              closeModal={closeActivateDeviceModal}
              device={myDevice}
            />
          )}

          {showEditDeviceModal && (
            <EditDeviceNameModal isOpen={showEditDeviceModal} closeModal={closeEditDeviceModal} device={myDevice} />
          )}

          {showTransactionPinModal && (
            <TransactionPinModal
              description="Enter your current Transaction PIN to generate a new PIN for your POS Device"
              submitText="Generate Device PIN"
              isOpen={showTransactionPinModal}
              closeModal={closeTransactionPinModal}
              clickIsLoading={isLoading.updateDevicePin}
              handleConfirmTransactionClick={generatePin}
            />
          )}

          {generatedDevicePin && (
            <ViewDevicePinModal
              isOpen={Boolean(generatedDevicePin)}
              closeModal={closeViewDevicePinModal}
              device={myDevice}
              generatedDevicePin={generatedDevicePin}
            />
          )}
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

export default Devices;
