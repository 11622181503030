import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { infoAlert } from 'src/store/actions/alertActions';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { Spinner } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';
import { getUserWallet, gotoYawo } from 'src/store/actions/userActions';
import { formatCurrency, copyToClipboard } from 'src/utils';
import Banner from 'src/skins/common/components/Banner';
import TransactionHistory from 'src/skins/jupiter/pages/dashboard/TransactionHistory';
import { ConfirmActionModal } from 'src/skins/jupiter/modals';

import VerifiedMarkIcon from 'src/assets/svgs/icon-verified-mark.svg';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import IconTransfer from 'src/skins/jupiter/assets/svgs/icon-transfer.svg';
import IconBills from 'src/skins/jupiter/assets/svgs/icon-bills.svg';
import YawoLogo from 'src/skins/jupiter/assets/svgs/yawo-logo.svg';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .hi-username {
    display: flex;
    align-items: center;
    color: ${props => props.theme.color.primary};

    h2 {
      font-size: 1rem;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;
    }
  }

  .transfer-id {
    font-size: 1rem;
    font-weight: 400;
    color: ${props => props.theme.color.primary};
  }

  .transfer_id {
    .icon {
      font-size: 1rem;
      color: ${props => props.theme.color.primary};
    }
  }

  .available-balance--wrapper {
    position: relative;
  }

  .available-balance--toggle {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    appearance: none;
    outline: none;
    border: 0;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
  }

  .available-balance {
    color: ${props => props.theme.color.light};
    border-radius: 10px;
    padding: 1rem 2rem;
    text-align: center;
    min-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
      107.36deg,
      ${props => props.theme.color.primary} 3.57%,
      rgba(0, 52, 102, 0.8) 52.66%,
      ${props => props.theme.color.primary} 101.76%
    );
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;

    .title {
      color: rgba(255, 255, 255, 0.8);
      font-size: 1rem;
    }

    .balance {
      font-size: 1.25rem;
    }

    &--wrapper {
      margin: 1.5rem 0;
      position: relative;
      z-index: 0;
    }

    &--effect {
      position: relative;
      z-index: -1;

      &:after,
      &:before {
        position: absolute;
        top: 0;
        content: '';
        width: 100%;
        min-height: 8rem;
        background: ${props => props.theme.color.jupiter.cardBoxShadow || '#F6F9FF'};
        border-radius: 10px;
        transform: matrix(0.99, -0.1, 0.13, 1, 0, 0);
      }

      &:before {
        transform: matrix(0.99, -0.1, 0.13, 1, 0, 0);
      }

      &:after {
        transform: matrix(0.99, 0.1, -0.13, 0.99, 0, 0);
      }
    }
  }

  section {
    margin: 1rem 0;

    &.last-section {
      margin: 1rem 0 0 0;
      background: #f7f9ff;
      border-radius: 50px 50px 0 0;
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 2rem 2rem 0 2rem;
      min-height: calc(100vh - 440px);
    }
  }
  .section-title {
    color: ${props => props.theme.color.jupiter.darkGray || props.theme.color.darkGray};
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .dashboard-actions {
    display: flex;
    padding: 1rem 1rem 1.5rem;
    overflow-x: auto;

    & a {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      border: 0;
      outline: none;
      text-align: center;
      text-decoration: none;
      min-width: 5.5rem;
      color: ${props => props.theme.color.jupiter.text || props.theme.color.text};

      & > div {
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:focus {
        & > div {
          box-shadow: none;
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }

      img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
        background: linear-gradient(
          16.84deg,
          ${props => props.theme.color.primary} -10.65%,
          ${props => props.theme.color.primary} 32.7%,
          ${props => props.theme.color.primary} 65.78%,
          ${props => props.theme.color.secondary} 98.86%
        );
        color: ${props => props.theme.color.light};
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 3.5rem;
        padding: 1rem;
      }
    }
  }

  .scrolltip {
    position: relative;

    .scrolltiptext {
      visibility: visible;
      color: ${props => props.theme.color.primary};
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
    }
  }

  .soon {
    padding-left: 0.2rem;
    font-size: 0.5rem;
  }
  .transfer_id {
    display: flex;
    grid-gap: 0.5rem;
    justify-content: space-between;
  }
  .transfer_id > div {
    display: flex;
    grid-gap: 0.5rem;
    cursor: pointer;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Dashboard = () => {
  const [showAvailableBalance, setShowAvailableBalance] = useState(false);
  const toggleAvailableBalance = () => setShowAvailableBalance(!showAvailableBalance);

  const mmo = useSelector(state => state.MMO.mmo);
  const mmoServices = mmo.services ? mmo.services.split(';') : [];
  const { user, wallet, isLoading } = useSelector(state => state.USER);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserWallet());
  }, [getUserWallet]);

  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  const [showConfirmToOpenYawoAppModal, setConfirmToOpenYawoAppModal] = useState(false);
  const openConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(true);
  const closeConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(false);
  const acceptToOpenYawoApp = () => gotoYawo(closeConfirmToOpenYawoAppModal);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div className="hi-username">
            <h2>Hi {`${user.firstName} ${user.lastName}`},</h2>
            <img src={VerifiedMarkIcon} alt="✔" />
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <div className="transfer_id">
          <div>
            <p className="transfer-id">Transfer ID: {user.iuwi}</p>
            <Icon name="copy" size="small" onClick={() => copyToClipboard('transferId', copiedAlert)} />
          </div>
          {isLoading.getUserWallet && <Spinner theme="primary" />}
        </div>

        <input id="transferId" type="text" value={user.iuwi} onChange={null} readOnly style={hiddenInputStyle} />

        <div className="available-balance--wrapper">
          <button type="button" className="available-balance--toggle" onClick={toggleAvailableBalance}>
            <Icon size="small" name={showAvailableBalance ? 'eye' : 'eyeOff'} />
          </button>
          <div className="available-balance">
            <div className="title">Available Balance</div>
            <div className="balance">
              {!isLoading.getUserWallet
                ? showAvailableBalance
                  ? formatCurrency(wallet.walletBalance || 0, 'NGN')
                  : '********'
                : '•••'}
            </div>
          </div>
        </div>

        {!user.hasPin && (
          <Banner type="warning">
            <p>
              {'Transaction pin not set. '}
              <span className="link" onClick={() => history.push('/dashboard/settings')}>
                Click Here
              </span>
            </p>
          </Banner>
        )}

        <section className="scrolltip">
          <div className="dashboard-actions">
            <Link to="/dashboard/transfer" className="action-card">
              <img src={IconTransfer} alt="..." />
              Transfer
            </Link>
            <Link to="/dashboard/bills" className="action-card">
              <img src={IconBills} alt="..." />
              Bills payment
            </Link>
            <Link to="/dashboard/support" className="action-card">
              <img src={IconTransfer} alt="..." />
              Issues
            </Link>
            {mmoServices.some(x => x === 'clec') && (
              <Link to="/dashboard/collections" className="action-card">
                <img src={IconBills} alt="..." />
                Collections
              </Link>
            )}
            <Link to="/dashboard/devices" className="action-card">
              <img src={IconBills} alt="..." />
              My Devices
            </Link>
            {mmoServices.some(x => x === 'yawo') && (
              <>
                <Link to="#" onClick={openConfirmToOpenYawoAppModal} className="action-card">
                  <img src={YawoLogo} alt="..." />
                  Yawo
                </Link>
                <ConfirmActionModal
                  isOpen={showConfirmToOpenYawoAppModal}
                  actionTitle="Confirm To Access Yawo"
                  actionMessage="This action takes you to Yawo App"
                  onAccept={acceptToOpenYawoApp}
                  onDecline={closeConfirmToOpenYawoAppModal}
                  closeModal={closeConfirmToOpenYawoAppModal}
                />
              </>
            )}
            <Link to="/dashboard/withdrawal/third-party" className="action-card">
              <img src={IconTransfer} alt="..." />
              Cardless Withdrawal
            </Link>
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconTransfer} alt="..." />
							Cash
						</Link> */}
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconTransfer} alt="..." />
							Insurance
						</Link> */}
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconBills} alt="..." />
							Shop
						</Link> */}
            <span style={{ width: '1rem' }} serving-as-space="">
              &nbsp;
            </span>
          </div>
          <p className="scrolltiptext">
            scroll to view more ⮕
          </p>
        </section>

        <section className="last-section">
          <div className="section-title">Recent Transactions</div>
          <TransactionHistory recentTransactions={true} />
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '-2rem' };

export default Dashboard;
