import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'src/skins/common/components';
import { VenusModal } from 'src/skins/venus/components/common';
import { PinInput } from 'src/skins/venus/components/inputs';
import styled from 'styled-components';

const Wrapper = styled.div`
  .divider {
    width: 100%;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  button {
    width: 100%;
    height: 4em;
    background: transparent;
    color: ${props => props.theme.color.primary};
    font-size: 1.2rem;
    border: 0;
    outline: none;
    appearance: none;
    cursor: pointer;
  }
  .isLoading {
    pointer-events: none;
  }
`;

/**
 * @description TransactionOtpModal Component
 * @param {TransactionOtpModalProps} props
 */
const TransactionOtpModal = props => {
  const {
    title,
    description,
    submitText = 'Confirm Transaction',
    isOpen,
    closeModal,
    clickIsLoading,
    handleConfirmTransactionClick,
  } = props;
  const [otp, setOtp] = useState({});

  const getOtp = () => {
    let otpString = '';
    Object.keys(otp).forEach(item => (otpString = otpString + otp[item]));
    return otpString;
  };

  const onSubmitClick = () => {
    handleConfirmTransactionClick(getOtp());
  };

  return (
    <VenusModal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <h5 className="color-primary">{title || 'Input Otp'}</h5>
        {description && <p style={{ marginTop: '0.5rem' }}>{description}</p>}

        <br />

        <p>Enter Otp</p>
        <PinInput value={otp} onChange={setOtp} pinLength={5} />

        <br />

        <button type="submit" className={clickIsLoading ? 'isLoading' : ''} onClick={onSubmitClick}>
          {clickIsLoading ? <Spinner theme="primary" /> : submitText}
        </button>
      </Wrapper>
    </VenusModal>
  );
};

TransactionOtpModal.defaultProps = {
  title: '',
  description: '',
  isOpen: false,
  closeModal: null,
  clickIsLoading: false,
  handleConfirmTransactionClick: null,
};

/**
 * Set
 *TransactionOtpModal PropType Definition
 * @typedef {Object} TransactionOtpModalProps
 * @property {String} title
 * @property {String} description
 * @property {String} submitText
 * @property {Boolean} isOpen
 * @property {Function} closeModal
 * @property {boolean} clickIsLoading
 * @property {(otp:string) => {}} handleConfirmTransactionClick
 */
TransactionOtpModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  clickIsLoading: PropTypes.bool.isRequired,
  handleConfirmTransactionClick: PropTypes.func.isRequired,
};

export { TransactionOtpModal };
