import React from 'react';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { BuyElectricityForm } from 'src/skins/venus/forms';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  section {
    margin: 1rem 0;
  }
`;

const BillsElectricity = () => {
  const history = useHistory();
  return (
    <DashboardLayout>
      <VenusModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="bills">
        <Wrapper>
          <h2>Buy Electricity</h2>

          <section>
            <BuyElectricityForm />
          </section>
        </Wrapper>
      </VenusModal>
    </DashboardLayout>
  );
};

export default BillsElectricity;
