import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  button {
    width: 100%;
    outline: none;
    appearance: none;
    text-align: initial;
    text-decoration: none;
    color: currentColor;
    background: transparent;
    border: 1px solid ${props => props.theme.color.primary};
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
  }
  .name {
    font-weight: 600;
  }
  .account {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    & > :first-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(50% - 1.5rem);
    }

    & > :last-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      align-self: auto;
    }
    & > :first-child {
      margin-right: 1.5rem;
    }
    & > :last-child {
      font-weight: 600;
    }
  }
`;

export const TvCablesBeneficiaryItem = props => {
  const { beneficiary, onClick } = props;
  const { name, cardNumber, type } = beneficiary;

  return (
    <Wrapper>
      <button onClick={onClick}>
        <div className="name">{name || ''}</div>
        <div className="account">
          <span>{cardNumber || ''}</span>
          <span>{type || ''}</span>
        </div>
      </button>
    </Wrapper>
  );
};

TvCablesBeneficiaryItem.defaultPropTypes = {
  beneficiary: {},
  onClick: null,
};

/**
 * BuyAirtimeListItem PropType Definition
 * @typedef {Object} BuyAirtimeListItemProps
 * @property {Object} beneficiary
 * @property {Function} onClick
 */
TvCablesBeneficiaryItem.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TvCablesBeneficiaryItem;
