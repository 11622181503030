import PlutoStyle from './PlutoStyle';
import VenusGlobalStyle from './VenusGlobalStyle';
import JupiterStyle from './JupiterStyle';
import MarsGlobalStyle from './MarsGlobalStyle';

export default {
  pluto: PlutoStyle,
  venus: VenusGlobalStyle,
  jupiter: JupiterStyle,
  mars: MarsGlobalStyle,
};
