import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .input_section {
    height: 2.5rem;
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    outline: 0px;
    color: ${props => props.theme.color.primary};
    transition: ease 0.5s all;
  }

  .input {
    width: 1rem;
    height: 1rem;
    appearance: none;
    border: 1px solid ${props => props.theme.color.mars.gray || props.theme.color.primary};
    border-radius: 0;
    outline: none;
    position: relative;

    &:checked {
      border: 1px solid ${props => props.theme.color.primary};

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0.1rem;
        left: 0.3rem;
        width: 0.25rem;
        height: 0.5rem;
        border: solid ${props => props.theme.color.primary};
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        margin-left: 0.5px;
      }
    }

    &[type='checkbox'] + label {
      margin-left: 0.5rem;
      margin-top: 0.1rem;
    }
  }

  .label {
    color: ${props => props.theme.color.primary};
    min-width: max-content;
    font-size: 1rem;
    display: inline-block;
    font-size: 400;
    transition: ease 0.5s all;
    height: 22px;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & > div[disabled] {
    opacity: 0.55;

    .label {
      opacity: 0.55;
      filter: grayscale(100%);
    }
  }
`;

/**
 * @description CheckboxInput Component
 * @param {CheckboxInputProps} props
 */
const CheckboxInput = forwardRef((props, ref) => {
  const { label, name, required, disabled, checked, onChange, onInput } = props;
  const inputId = `id-${name}`;

  return (
    <Wrapper>
      <div disabled={disabled}>
        <div className="input_section" tabIndex="-1">
          {onChange ? (
            <input
              id={inputId}
              className="input"
              type="checkbox"
              name={name}
              ref={ref}
              onInput={onInput}
              required={required}
              disabled={disabled}
              checked={checked}
              onChange={onChange}
            />
          ) : (
            <input
              id={inputId}
              className="input"
              type="checkbox"
              name={name}
              ref={ref}
              onInput={onInput}
              required={required}
              disabled={disabled}
            />
          )}
          {label && (
            <label className="label" htmlFor={inputId}>
              {label}
            </label>
          )}
        </div>
      </div>
    </Wrapper>
  );
});

CheckboxInput.displayName = 'CheckboxInput';

/**
 * CheckboxInput PropType Definition
 * @typedef {Object} CheckboxInputProps
 * @property {String} label
 * @property {String} name
 * @property {Boolean} checked
 * @property {Function} onChange
 * @property {Function} onInput
 * @property {Boolean} required
 * @property {Boolean} disabled
 */
CheckboxInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export { CheckboxInput };
