import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import IconHexagon from 'src/assets/svgs/icon-hexagon.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.light};
  color: ${props => props.theme.color.primary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  main {
    height: 100%;
    overflow-y: auto;
    padding: 1rem 1rem 0 1rem;
  }

  nav {
    width: 100%;
    box-shadow: 0 -1px 0 0 #ededed;
    background-color: ${props => props.theme.color.light};

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      margin: 0;

      li {
        a {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: auto;
          height: 3rem;
          background: transparent;
          color: ${props => props.theme.color.gray};
          font-weight: 600;
          text-decoration: none;
          margin: 1rem;
          outline: none;
          line-height: 100%;

          &:focus,
          &.active {
            color: #727eae;
          }

          &:hover {
            color: ${props => props.theme.color.primary};
          }

          span:last-child {
            font-size: 10px;
          }
        }

        &.always-active {
          a {
            background: url('${IconHexagon}') center no-repeat;
            background-size: contain;
            color: ${props => props.theme.color.light};
            width: 3rem;
          }
        }
      }
    }
  }
`;

const DashboardLayout = ({ children }) => {
  return (
    <PageView className="no-overflow no-padding">
      <Wrapper>
        <main>{children}</main>

        <nav>
          <ul>
            <li>
              <NavLink to="/dashboard" exact activeClassName="active">
                <Icon size="small" name="home" />
                <span>Overview</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/wallet" exact activeClassName="active">
                <Icon size="small" name="wallet" />
                <span>Wallet</span>
              </NavLink>
            </li>
            <li className="always-active">
              <Link to="#new">
                <Icon size="small" name="plus" />
              </Link>
            </li>
            <li>
              <NavLink to="/dashboard/transactions" activeClassName="active">
                <Icon size="small" name="list" />
                <span>History</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/settings" exact activeClassName="active">
                <Icon size="small" name="settings" />
                <span>Settings</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </Wrapper>
    </PageView>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export { DashboardLayout };
