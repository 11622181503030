import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { infoAlert } from 'src/store/actions/alertActions';
import { DashboardLayout } from 'src/skins/venus/components/layouts';
import { Spinner } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';
import { getUserWallet, gotoYawo } from 'src/store/actions/userActions';
import { formatCurrency, copyToClipboard } from 'src/utils';
import Banner from 'src/skins/common/components/Banner';
import TransactionHistory from 'src/skins/venus/pages/dashboard/TransactionHistory';
import { ConfirmActionModal } from 'src/skins/venus/modals';

import VerifiedMarkIcon from 'src/assets/svgs/icon-verified-mark.svg';
import UserIcon from 'src/assets/svgs/icon-user.svg';
import VenusBgEffect1 from 'src/assets/svgs/venus-bg-effect-1.svg';
import VenusBgEffect2 from 'src/assets/svgs/venus-bg-effect-2.svg';
import VenusBgEffect3 from 'src/assets/svgs/venus-bg-effect-3.svg';
import IconTransfer from 'src/skins/venus/assets/images/icon-transfer.png';
import IconBills from 'src/skins/venus/assets/images/icon-bills.png';
import YawoLogo from 'src/skins/venus/assets/svgs/yawo-logo.svg';

const Wrapper = styled.div`
  .header-wrapper {
    margin: -1rem -1rem 2rem -1rem;
    background-color: ${props => props.theme.color.primary};
    background-image: url('${VenusBgEffect1}'), url('${VenusBgEffect2}'), url('${VenusBgEffect3}');
    background-position: center -25%, left 125%, 115% bottom;
    background-size: 175px 125px;
    background-repeat: no-repeat;
    color: ${props => props.theme.color.light};
    padding: 2rem 1rem;
    border-radius: 0 0 0 50px;
    box-shadow: 0px 8px 20px rgba(97, 62, 234, 0.32);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
      filter: brightness(200%);
    }
  }

  .hi-username {
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;
    }
  }

  .transfer-id {
    font-size: 1rem;
    font-weight: 400;
  }

  .available-balance--wrapper {
    position: relative;
    margin-top: 2rem;
  }

  .available-balance--toggle {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    appearance: none;
    outline: none;
    border: 0;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
  }

  .available-balance {
    background: transparent;
    text-align: center;

    .title {
      color: rgba(255, 255, 255, 0.55);
      font-size: 0.9rem;
      font-weight: 600;
    }

    .balance {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  section {
    margin: 1rem 0;
  }

  .dashboard-actions {
    display: flex;
    padding: 1rem 1rem 1.5rem;
    overflow-x: auto;

    & a {
      display: inline-block;
      padding: 0;
      background: ${props => props.theme.color.primary};
      color: ${props => props.theme.color.light};
      border: 0;
      outline: none;
      text-align: center;
      text-decoration: none;
      min-width: 35%;

      & > div {
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:focus {
        & > div {
          box-shadow: none;
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:nth-child(even) {
        background: ${props => props.theme.color.secondary};
        color: ${props => props.theme.color.light};
      }

      img {
        height: 50px;
        display: block;
        margin-bottom: 0.5rem;
      }
    }

    .action-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 7rem;
      padding: 0.4rem;
      font-size: 0.8rem;
      font-weight: 600;
      box-shadow: 0px 8px 20px rgba(97, 62, 234, 0.32);
      border-radius: 26px;
    }
  }

  .scrolltip {
    position: relative;

    .scrolltiptext {
      visibility: visible;
      color: ${props => props.theme.color.primary};
      position: absolute;
      z-index: 1;
      bottom: 23px;
      right: 0;
    }
  }

  .soon {
    padding-left: 0.2rem;
    font-size: 0.5rem;
  }
  .transfer_id {
    display: flex;
    grid-gap: 0.5rem;
    justify-content: space-between;
  }
  .transfer_id > div {
    display: flex;
    grid-gap: 0.5rem;
    cursor: pointer;
  }
`;

const Dashboard = () => {
  const [showAvailableBalance, setShowAvailableBalance] = useState(false);
  const toggleAvailableBalance = () => setShowAvailableBalance(!showAvailableBalance);

  const mmo = useSelector(state => state.MMO.mmo);
  const mmoServices = mmo.services ? mmo.services.split(';') : [];
  const { user, wallet, isLoading } = useSelector(state => state.USER);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // PREVENT refetching the user wallet when Dashboard is used as a backgroundPage
    if (history.location.pathname === '/dashboard') {
      dispatch(getUserWallet());
    }
  }, [getUserWallet]);

  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  const [showConfirmToOpenYawoAppModal, setConfirmToOpenYawoAppModal] = useState(false);
  const openConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(true);
  const closeConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(false);
  const acceptToOpenYawoApp = () => gotoYawo(closeConfirmToOpenYawoAppModal);

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header-wrapper">
          <div className="header">
            <div className="hi-username">
              <h2>Hi {`${user.firstName} ${user.lastName}`},</h2>
              <img src={VerifiedMarkIcon} alt="✔" />
            </div>
            <img src={UserIcon} alt="USER" />
          </div>

          <div className="transfer_id">
            <div>
              <p className="transfer-id">Transfer ID: {user.iuwi}</p>
              <Icon name="copy" size="small" onClick={() => copyToClipboard('transferId', copiedAlert)} />
            </div>
            {isLoading.getUserWallet && <Spinner theme="primary" />}
          </div>

          <input id="transferId" type="text" value={user.iuwi} onChange={null} readOnly style={hiddenInputStyle} />

          <div className="available-balance--wrapper">
            <button type="button" className="available-balance--toggle" onClick={toggleAvailableBalance}>
              <Icon size="small" name={showAvailableBalance ? 'eye' : 'eyeOff'} />
            </button>
            <div className="available-balance">
              <div className="title">Available Balance</div>
              <div className="balance">
                {!isLoading.getUserWallet
                  ? showAvailableBalance
                    ? formatCurrency(wallet.walletBalance || 0, 'NGN')
                    : '********'
                  : '•••'}
              </div>
            </div>
          </div>
        </div>

        {!user.hasPin && (
          <Banner type="warning">
            <p>
              {'Transaction pin not set. '}
              <span className="link" onClick={() => history.push('/dashboard/settings')}>
                Click Here
              </span>
            </p>
          </Banner>
        )}

        <section className="scrolltip">
          <div className="color-gray">Make a transaction</div>
          <div className="dashboard-actions">
            <Link to="/dashboard/transfer" className="action-card">
              <img src={IconTransfer} alt="..." />
              Transfer
            </Link>
            <Link to="/dashboard/bills" className="action-card">
              <img src={IconBills} alt="..." />
              Bills payment
            </Link>
            <Link to="/dashboard/support" className="action-card">
              <img src={IconTransfer} alt="..." />
              Issues
            </Link>
            {mmoServices.some(x => x === 'clec') && (
              <Link to="/dashboard/collections" className="action-card">
                <img src={IconBills} alt="..." />
                Collections
              </Link>
            )}
            <Link to="/dashboard/devices" className="action-card">
              <img src={IconTransfer} alt="..." />
              My Devices
            </Link>
            {mmoServices.some(x => x === 'yawo') && (
              <>
                <Link to="#" onClick={openConfirmToOpenYawoAppModal} className="action-card">
                  <img src={YawoLogo} alt="..." />
                  Yawo
                </Link>
                <ConfirmActionModal
                  isOpen={showConfirmToOpenYawoAppModal}
                  actionTitle="Confirm To Access Yawo"
                  actionMessage="This action takes you to Yawo App"
                  onAccept={acceptToOpenYawoApp}
                  onDecline={closeConfirmToOpenYawoAppModal}
                  closeModal={closeConfirmToOpenYawoAppModal}
                />
              </>
            )}
            <Link to="/dashboard/withdrawal/third-party" className="action-card">
              <img src={IconTransfer} alt="..." />
              Cardless Withdrawal
            </Link>
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconTransfer} alt="..." />
							Cash
						</Link> */}
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconTransfer} alt="..." />
							Insurance
						</Link> */}
            {/* <Link
							to="/dashboard"
							className="action-card"
							onClick={() => dispatch(infoAlert('Coming Soon'))}
						>
							<img src={IconBills} alt="..." />
							Shop
						</Link> */}
            <span style={{ width: '1rem' }} serving-as-space="">
              &nbsp;
            </span>
          </div>
          <center className="text-xl color-gray">&bull; &bull; &bull;</center>
          <p className="scrolltiptext">scroll to view more ⮕</p>
        </section>

        <section>
          <div className="color-gray">Recent transactions</div>
          <br />
          <TransactionHistory recentTransactions={true} />
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '-2rem' };

export default Dashboard;
