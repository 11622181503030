import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PageView } from 'src/skins/common/components';
import { SetPasswordForm } from 'src/skins/jupiter/forms';

const Wrapper = styled.div`
  .top_nav {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .auth_body {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo_div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 40%;
  }
  .auth_state {
    display: flex;
    justify-content: center;
  }
  .auth_item {
    border-bottom: 2px solid ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.primary};
    width: max-content;
    text-align: center;
    cursor: pointer;
  }
  .forms {
    padding-top: 1rem;
    margin-bottom: 3rem;
  }
`;

const SetPassword = () => {
  const history = useHistory();
  const { mmo } = useSelector(state => state.MMO);
  return (
    <PageView>
      <Wrapper>
        <div className="top_nav" onClick={() => history.push('/auth')}>
          <p>BACK</p>
        </div>
        <div className="auth_body">
          <div className="logo_div">
            <img src={mmo.logo} alt="Village Assist" className="logo" />
          </div>
          <div className="auth_state">
            <h5 className="auth_item">Set Password</h5>
          </div>
          <div className="forms">
            <SetPasswordForm />
          </div>
        </div>
      </Wrapper>
    </PageView>
  );
};

SetPassword.propTypes = {};

export default SetPassword;
