import React, { useState } from 'react';
import { Button } from 'src/skins/common/components';
import { TextInput } from 'src/skins/pluto/components/inputs';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setPassword } from 'src/store/actions/userActions';

export const SetPasswordForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const userPayload = history.location.state;

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      accountBank: '',
    },
  });

  const onSubmit = data => {
    const payload = { ...userPayload, password: data.password, phoneNumber: userPayload.phone };

    dispatch(setPassword(payload, toggleModal));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextInput
            type="password"
            label="Enter Password"
            name="password"
            ref={register({ required: true })}
            icon="lock"
            errors={<React.Fragment>{errors.password && <p className="error">Password is required</p>}</React.Fragment>}
          />
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirm_password"
            ref={register({ required: true })}
            icon="lock"
            onInput={e => {
              const password = document.querySelector('input[name="password"]').value;
              e.target.value !== password
                ? setError('confirm_password', {
                    type: 'confirm',
                    message: 'Password must be the same',
                  })
                : clearErrors('confirm_password');
              return e;
            }}
            errors={
              <React.Fragment>
                {errors.confirm_password && errors.confirm_password.type === 'required' && (
                  <p className="error">Password is required</p>
                )}
                {errors.confirm_password && errors.confirm_password.type && (
                  <p className="error">{errors.confirm_password.message}</p>
                )}
              </React.Fragment>
            }
          />
        </div>

        <br />

        <Button type="submit" text="Set Password" size="large" />
      </form>
    </>
  );
};

export default SetPasswordForm;
