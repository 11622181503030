import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import { Button } from 'src/skins/common/components';
import { InnerPageHeader, IssueItem } from 'src/skins/pluto/components/support';
import styled from 'styled-components';
import LODASH from 'lodash';
import { Spinner } from 'src/skins/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIssues, resetIssues } from 'src/store/actions/selfcareActions';

const DEFAULT_LIMIT = 10;

const Wrapper = styled.div`
  color: ${props => props.theme.color.pluto.text || props.theme.color.text};

  .commission-balance-card {
    margin: 1rem 0 2.5rem 0;
  }

  section {
    margin: 1rem 0;
  }

  section:last-of-type {
    margin: 1rem 0;
    margin-bottom: 3rem;
  }

  .issue-summary {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);

    & > div {
      font-weight: bold;
      text-align: center;

      & > :first-child {
        font-size: 0.875rem;
      }
      & > :last-child {
        font-size: 1.5rem;
      }

      &.total-issues {
        & > :first-child {
          color: #828282;
        }
      }

      &.open-issues {
        & > :first-child {
          color: #005be6;
        }
      }

      &.resolved-issues,
      &.closed-issues {
        & > :first-child {
          color: #08cf82;
        }
      }
    }
  }

  .section-title {
    font-size: 0.75rem;
    font-weight: bold;
    color: #828282;
  }
`;

export const SupportDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, issues: issuesObj } = useSelector(state => state.SELFCARE);
  const totalIssuesCount = issuesObj ? issuesObj.count : 0;
  const openIssuesCount = issuesObj ? issuesObj.openCount : 0;
  const closedIssuesCount = issuesObj ? issuesObj.closedCount : 0;
  const resolvedIssuesCount = issuesObj ? issuesObj.resolvedCount : 0;
  const pageLimit = issuesObj ? LODASH.toNumber(issuesObj.limit) : DEFAULT_LIMIT;
  const currentPage = issuesObj ? LODASH.toNumber(issuesObj.page) : 1;
  const showLoadMoreButton = totalIssuesCount >= pageLimit * currentPage;
  const nextPage = showLoadMoreButton ? currentPage + 1 : currentPage;

  const fetchingIssues = isLoading.getAllIssues && currentPage === nextPage && currentPage === 1;
  const fetchingMoreIssues = isLoading.getAllIssues && nextPage > currentPage;

  const [issues, setIssues] = useState([]);
  const setIssuesCallback = newlyFetchedIssues => {
    const uniqueIssues = LODASH.uniqBy([...issues, ...newlyFetchedIssues], '_id');
    setIssues(uniqueIssues);
  };

  useEffect(() => {
    setIssues([]);
    dispatch(resetIssues());
    dispatch(getAllIssues({ limit: DEFAULT_LIMIT, page: 1, setIssuesCallback }));
  }, []);

  const sortedIssues = LODASH.sortBy(issues, 'createdAt');
  const reverseSortedIssues = LODASH.reverse(sortedIssues);
  const mappedIssues = reverseSortedIssues.map((issue, index) => {
    return <IssueItem key={index} issue={issue} />;
  });

  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader>
          <h4>Reported Issues</h4>
        </InnerPageHeader>

        <div className="issue-summary">
          <div className="total-issues">
            <div>TOTAL</div>
            <div>{totalIssuesCount}</div>
          </div>
          <div className="open-issues">
            <div>OPEN</div>
            <div>{openIssuesCount}</div>
          </div>
          <div className="closed-issues">
            <div>CLOSED</div>
            <div>{closedIssuesCount}</div>
          </div>
          <div className="resolved-issues">
            <div>RESOLVED</div>
            <div>{resolvedIssuesCount}</div>
          </div>
        </div>

        <div>
          <br />
        </div>

        <Link to="/dashboard/support/new" as="button">
          <Button size="large" text="+ Report New Issue" className="block" />
        </Link>

        <div>
          <br />
        </div>

        <section>
          <div className="flex-space-between">
            <h5 className="section-title">ISSUES</h5>
          </div>
          <div>
            {fetchingIssues === true && <div className="loading-state">fetching issues...</div>}
            {fetchingIssues === false && issues.length > 0 && (
              <React.Fragment>
                {mappedIssues}
                {showLoadMoreButton && (
                  <React.Fragment>
                    <br />
                    {fetchingMoreIssues ? (
                      <center>
                        <Spinner theme="primary" />
                      </center>
                    ) : (
                      <Button
                        size="large"
                        text="Load More"
                        className="block"
                        onClick={() => dispatch(getAllIssues({ limit: pageLimit, page: nextPage, setIssuesCallback }))}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {fetchingIssues === false && issues.length === 0 && (
              <div className="empty-state color-gray">No Reported Issues Yet</div>
            )}
          </div>
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default SupportDashboard;
