import React from 'react';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import TransactionHistoryDetailsContent from 'src/skins/jupiter/components/transactions/TransactionHistoryDetailsContent';
import { useHistory } from 'react-router-dom';

const pageTitle = 'Transaction Details';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    .go-back {
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;

      & > * {
        padding-top: 0.25rem;
      }
    }
  }

  section {
    margin: 1rem 0;
  }

  .header-buttons {
    display: flex;
    align-items: center;
  }

  button.download-details {
    border: 1px solid ${props => props.theme.color.primary};
    color: ${props => props.theme.color.primary};
    border-radius: 5px;
    outline: none;
    appearance: none;
    background: transparent;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
  }

  button.icon-wrapper {
    border: 0;
    outline: none;
    appearance: none;
    background: transparent;
    margin-left: 0.5rem;
  }
`;

const TransactionHistoryDetails = () => {
  const history = useHistory();
  const goBack = () => {
    if (window.history.length > 2) return history.goBack();
    return history.replace('/dashboard/transactions');
  };

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <span className="flex-space-between">
            <button className="go-back">
              <Icon size="large" name="back" onClick={goBack} />
            </button>
            <h3>{pageTitle}</h3>
          </span>
        </div>

        <section>
          <TransactionHistoryDetailsContent />
        </section>
      </Wrapper>
    </DashboardLayout>
  );
};

export default TransactionHistoryDetails;
