import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
  box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.05), inset 0px 2px 2px rgba(51, 51, 51, 0.05);

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.color.pluto.lightestGray};
  }

  .feedback-info {
    font-size: 1rem;
    padding: 1rem 0;
    /* background-color: #ffffff; */
    text-decoration: none;
    color: currentColor;
    outline: none;

    & > :last-child {
      width: 100%;
    }
  }

  .text-small {
    font-size: small;
  }

  .color-textGray {
    color: ${props => props.theme.color.textGray};
  }

  .color-blue {
    color: ${props => props.theme.color.primary};
  }

  .color-red {
    color: ${props => props.theme.color.pluto.debitColor};
  }

  .font-weight-600 {
    font-weight: 600;
  }

  [status='OPEN'] {
    color: #005be6;
  }

  [status='CLOSED'] {
    color: #08cf82;
  }
`;

export const FeedbackItem = ({ feedback }) => {
  const { _id = '', message = '', date: createdAt, author } = feedback || {};

  return (
    <Wrapper>
      <div id={_id} className="feedback-info">
        <div className="text-small">
          <span>{message}</span>
        </div>
        <div className="text-small">
          <span>{createdAt ? moment(createdAt).fromNow() : ''}</span>{' '}
          <span className="font-weight-600">from {author || 'Admin'}</span>
        </div>
      </div>
    </Wrapper>
  );
};

/**
 * FeedbackItem PropType Definition
 * @typedef {Object} FeedbackItemProps
 * @property {Object} feedback
 */
FeedbackItem.propTypes = {
  feedback: PropTypes.object,
};

export default FeedbackItem;
