export const theme = {
  color: {
    // Application level Colors
    primary: '#FF0C0C',
    secondary: '#999999',
    caution: '#DA8D19',
    success: '#1DB131',
    danger: '#DD4B39',
    light: '#FFFFFF',
    black: '#171D33',
    dark: '#2C514E',
    white: '#ffffff',

    darkGray: '#757F8C',
    text: '#4F4F4F',
    textDark: '#1B2124',
    info: '#0E52AF',
    disabled: 'rgba(0, 0, 0, 0.5)',
    gray: '#B4B4B4',
    lightGray: '#00000033',

    // Skin level Colors -- eg: pluto: {red: 'rgba(0, 0, 0, 0.2)'}
    // For Aggregator
    pluto: {
      lightestGray: 'rgba(0, 0, 0, 0.05)',
      lighterGray: 'rgba(0, 0, 0, 0.1)',
      lightGray: 'rgba(0, 0, 0, 0.2)',
      cardBoxShadow: '#DED7F9',
      cancelledColor: '#C4C4C4',
      cancelledBgColor: 'rgba(196, 196, 196, 0.3)',
      creditBgColor: 'rgba(140, 242, 131, 0.3)',
      debitBgColor: 'rgba(248, 67, 67, 0.3)',
      creditColor: '#19D315',
      debitColor: '#EE0808',
    },

    venus: {
      cardBoxShadow: '#DED7F9',
      text: '#171D33',
      gray: '#A6AAB4',
      danger: '#FA2E69',
      success: '#01A85A',
    },

    jupiter: {
      cardBoxShadow: '#F6F9FF',
      text: '#4A5555',
      gray: '#4A555580',
      danger: '#DD4B39',
      success: '#10C971',
    },

    mars: {
      cardBoxShadow: '#DED7F9',
      text: '#25242D',
      gray: '#969595',
      danger: '#FF4A4A',
      success: '#4AF05B',
    },
  },
  breakpoint: {
    md: '840px',
  },
};

// // List of themes
// 1. pluto
// 2. venus
// 3. jupiter
// 4. mars

// TEST_THEME Example
// export const TEST_THEME = process.env.NODE_ENV === 'development' ? 'pluto' : '';
export const TEST_THEME = process.env.NODE_ENV === 'development' ? 'jupiter' : '';
