import axios from 'axios';
import keys from 'src/utils/keys';
import { reportError } from 'src/store/actions/errorAlert';

const { appService, mediaService } = keys;

const clients = {};

clients.appService = axios.create({
  baseURL: `${appService.server}/mmom`,
  auth: appService.auth,
});

clients.otpService = axios.create({
  baseURL: `${appService.server}/otp`,
  auth: appService.auth,
});

clients.posService = axios.create({
  baseURL: `${appService.server}/pos`,
  auth: appService.auth,
});

clients.transferService = axios.create({
  baseURL: `${appService.server}/transfers`,
  auth: appService.auth,
});

clients.collectionService = axios.create({
  baseURL: `${appService.server}/collections`,
  auth: appService.auth,
});

clients.billService = axios.create({
  baseURL: `${appService.server}/bills`,
  auth: appService.auth,
});

clients.mediaService = axios.create({
  baseURL: mediaService.server,
  auth: mediaService.auth,
});

Object.keys(clients).map(key => {
  return clients[key].interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 500) {
        const data = {
          appName: 'mmo-mobile-app',
          errorLevel: 'ISE',
          message: error.response.data,
          fileName: JSON.stringify(error.response),
          meta: JSON.stringify(error),
        };
        reportError(data);
      }
      return Promise.reject(error);
    }
  );
});

export const setServiceHeader = token => {
  Object.keys(clients).map(key => (clients[key].defaults.headers['gat'] = token));
  return;
};

export const deleteServiceHeader = () => {
  Object.keys(clients).map(key => delete clients[key].defaults.headers['gat']);
  return;
};

export default Object.freeze(clients);
