import React from 'react';
import styled from 'styled-components';
import { DashboardLayout, InnerPageHeader } from 'src/skins/jupiter/components/layouts';
import { PrivacyPolicyContent } from 'src/skins/common/components';

const Wrapper = styled.div`
  margin-bottom: 3.5rem;
`;

export const PrivacyPolicy = () => {
  return (
    <DashboardLayout>
      <Wrapper>
        <InnerPageHeader navigateBackTo="/dashboard/settings">
          <h4>Privacy policy</h4>
        </InnerPageHeader>

        <PrivacyPolicyContent />
      </Wrapper>
    </DashboardLayout>
  );
};

export default PrivacyPolicy;
