import React from 'react';
import PropTypes from 'prop-types';
import { Card as MaterialCard } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled(MaterialCard)`
  .card {
    padding: 0.5rem;
  }
`;

/**
 * @description Card Component
 * @param {CardProps} props
 */
const Card = props => {
  const { children, elevation, className } = props;
  return (
    <Wrapper elevation={elevation}>
      <div className={`card ${className}`}>{children}</div>
    </Wrapper>
  );
};

Card.defaultProps = {
  elevation: 1,
  className: '',
};

/**
 * Card PropType Definition
 * @typedef {Object} CardProps
 * @property {(1|2|3|4|5)} elevation
 * @property {string} className
 */
Card.propTypes = {
  children: PropTypes.any.isRequired,
  elevation: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export { Card };
