const keys = {};

// Auth service server and auth keys.
keys.appService = {
  server: process.env.REACT_APP_SERVER,
  auth: {
    username: process.env.REACT_APP_APP_SERVER_USERNAME,
    password: process.env.REACT_APP_APP_SERVER_PASSWORD,
  },
};

// Media service server and auth keys.
keys.mediaService = {
  server: process.env.REACT_APP_MEDIA_SERVER,
  auth: {
    username: process.env.REACT_APP_MEDIA_SERVER_USERNAME,
    password: process.env.REACT_APP_MEDIA_SERVER_PASSWORD,
  },
};

export default Object.freeze(keys);
