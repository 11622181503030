import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { isMobile } from 'react-device-detect';
import Notify from 'src/skins/common/components/Notify';
import globalStyles from './globalStyle';
import { useSelector } from 'react-redux';
import { theme, TEST_THEME } from './themeVariables';
import ErrorHandler from 'src/skins/common/components/ErrorHandler';

const WebWrapper = styled.div`
  margin: 1rem 0rem;
  height: 100vh;
  max-height: 100vh;
  width: 400px;

  @media only screen and (max-width: ${props => props.theme.breakpoint.md}) {
    border-radius: 0px;
    max-height: 100vh;
    width: 100vw;
    margin: 0rem;
  }
`;

const MobileWrapper = styled.div`
  height: 100vh;
  max-height: 100vh;
  margin: 0rem;
  width: 100vw;
`;

const wrapperSelect = {
  mobile: MobileWrapper,
  web: WebWrapper,
};

const Theme = ({ children }) => {
  const [deviceType, setDeviceType] = useState('web');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const { mmo, isLoading } = useSelector(state => state.MMO);
  const [skin, setSkin] = useState('pluto');

  useEffect(() => {
    isMobile ? setDeviceType('mobile') : setDeviceType('web');
  }, [isMobile]);

  useEffect(() => {
    !isLoading.getSingleMmo && mmo.primaryColor && setPrimaryColor(mmo.primaryColor);
    !isLoading.getSingleMmo && mmo.secondaryColor && setSecondaryColor(mmo.secondaryColor);
    !isLoading.getSingleMmo && mmo.skin && setSkin(TEST_THEME || mmo.skin);
  }, [isLoading.getSingleMmo, mmo.primaryColor, mmo.skin]);

  const Wrapper = wrapperSelect[deviceType];

  if (primaryColor) {
    theme.color.primary = primaryColor;
  }

  if (secondaryColor) {
    theme.color.secondary = secondaryColor;
  }

  const GlobalStyle = globalStyles[skin];

  return (
    <ThemeProvider theme={theme}>
      <div>
        <GlobalStyle />
        <Wrapper>
          <ErrorHandler>{children}</ErrorHandler>
        </Wrapper>
        <Notify />
      </div>
    </ThemeProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Theme;
