import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  background-color: transparent !important;
  .spinner {
    background-color: transparent !important;
  }
  .light {
    color: #ffffff;
  }
  .primary {
    color: ${props => props.theme.color.primary} !important;
  }
`;

const sizes = { small: 20, large: 40 };

/**
 * @description Spinner Component
 * @param {SpinnerProps} props
 */
const Spinner = props => {
  const { className, theme, size } = props;
  return (
    <Wrapper>
      <CircularProgress size={sizes[size]} className={`spinner ${theme} ${className}`} />
    </Wrapper>
  );
};

Spinner.defaultProps = {
  theme: 'primary',
  size: 'small',
};

/**
 * Spinner PropType Definition
 * @typedef {Object} SpinnerProps
 * @property {('primary'|'light')} theme Default = 'primary'
 * @property {('small'|'large')} size Default = 'small'
 * @property {string} className
 */
Spinner.propTypes = {
  theme: PropTypes.oneOf(['primary', 'light']).isRequired,
  size: PropTypes.oneOf(['small', 'light']).isRequired,
  className: PropTypes.string,
};

export { Spinner };
