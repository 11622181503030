import { BiQuestionMark } from 'react-icons/bi';
import { FaMoneyBillWave } from 'react-icons/fa';
import {
  MdHome,
  MdAccountCircle,
  MdMail,
  MdLock,
  MdVisibility,
  MdVisibilityOff,
  MdPhone,
  MdToday,
  MdLocationOn,
  MdFontDownload,
  MdFormatBold,
  MdSearch,
  MdAccountBalanceWallet,
  MdAdd,
  MdList,
  MdSettings,
  MdAttachMoney,
  MdChevronRight,
  MdChevronLeft,
  MdKeyboardTab,
  MdFilterList,
  MdArrowBack,
  MdCallMade,
  MdCallReceived,
  MdContentCopy,
  MdEdit,
} from 'react-icons/md';

/**
 * IconProps PropType Definition
 * @typedef {Object} MaterialIconProps
 * @property {React} default
 * @property {React} account
 * @property {React} home
 * @property {React} mail
 * @property {React} lock
 * @property {React} eye
 * @property {React} eyeOff
 * @property {React} phone
 * @property {React} calendar
 * @property {React} locationOn
 * @property {React} accountNumber
 * @property {React} bank
 * @property {React} search
 * @property {React} wallet
 * @property {React} plus
 * @property {React} list
 * @property {React} settings
 * @property {React} dollar
 * @property {React} chevronRight
 * @property {React} chevronLeft
 * @property {React} send
 * @property {React} money
 */

/**
 * @description MaterialIcon Props
 * @type {MaterialIconProps}
 */
const materialIcons = {
  default: BiQuestionMark,
  account: MdAccountCircle,
  home: MdHome,
  mail: MdMail,
  lock: MdLock,
  eye: MdVisibility,
  eyeOff: MdVisibilityOff,
  phone: MdPhone,
  calendar: MdToday,
  locationOn: MdLocationOn,
  accountNumber: MdFontDownload,
  bank: MdFormatBold,
  search: MdSearch,
  wallet: MdAccountBalanceWallet,
  plus: MdAdd,
  list: MdList,
  settings: MdSettings,
  dollar: MdAttachMoney,
  chevronRight: MdChevronRight,
  chevronLeft: MdChevronLeft,
  send: MdKeyboardTab,
  filter: MdFilterList,
  back: MdArrowBack,
  credit: MdCallMade,
  debit: MdCallReceived,
  copy: MdContentCopy,
  money: FaMoneyBillWave,
  edit: MdEdit,
};

export { materialIcons };
