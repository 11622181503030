import clients from 'src/clients';
import { SET_BILLS_LOADING, SET_DATA_PLAN, SET_TV_CABLE_PLAN, SET_ELECTRICITY_TYPES } from 'src/store/types';
import { errorAlert } from './alertActions';

const { billService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_BILLS_LOADING,
    payload: { action, state },
  };
};

/**
 * Redux Action to get Transaction Summary
 * @param {object} data
 * @param {number} data.amount
 * @param {number} data.phone
 * @param {string} data.network
 * @param {string} data.planId - Option for data type.
 * @param {string} data.disco - Option for electricity type.
 * @param {string} data.meterType - Option for electricity type.
 * @param {string} data.meterNumber - Option for electricity type.
 * @param {('airtime'|'data'| 'electricity')} type
 * @param {(summaryData: {}) => any} callback
 */
export const getBillSummary = (data, type, callback) => dispatch => {
  dispatch(setIsLoading('getBillSummary', true));
  billService
    .post(`/${type}/summary`, data)
    .then(res => {
      callback(res.data);
      dispatch(setIsLoading('getBillSummary', false));
    })
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      dispatch(setIsLoading('getBillSummary', false));
    });
};

/**
 * Redux Action to confirm transaction
 * @param {object} data
 * @param {number} data.amount
 * @param {number} data.phone
 * @param {string} data.network
 * @param {string} data.pin Transaction Pin.
 * @param {string} data.planId - Option for data type.
 * @param {string} data.disco - Option for electricity type.
 * @param {string} data.meterType - Option for electricity type.
 * @param {string} data.meterNumber - Option for electricity type.
 * @param {string} data.saveBeneficiary - Add to Beneficiary.
 * @param {string} data.name - Beneficiary name.
 * @param {('airtime'|'data'|'electricity')} type
 * @param {(summaryData: {}, error: boolean) => any} callback
 */
export const confirmBillTransaction = (data, type, callback) => dispatch => {
  dispatch(setIsLoading('confirmBillTransaction', true));
  billService
    .post(`/${type}`, data)
    .then(res => {
      callback(res.data, false);
      dispatch(setIsLoading('confirmBillTransaction', false));
    })
    .catch(err => {
      dispatch(errorAlert(err.response.data.message));
      callback(null, true);
      dispatch(setIsLoading('confirmBillTransaction', false));
    });
};

const setDataPlan = (network, data) => {
  return {
    type: SET_DATA_PLAN,
    payload: { network, data },
  };
};

export const getDataPlan = network => dispatch => {
  dispatch(setIsLoading('getDataPlan', true));
  billService
    .get(`/data/${network}/plan`)
    .then(res => {
      dispatch(setDataPlan(network, res.data.data));
      dispatch(setIsLoading('getDataPlan', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getDataPlan', false));
    });
};

const setTvCablePlan = (type, data) => {
  return {
    type: SET_TV_CABLE_PLAN,
    payload: { type, data },
  };
};

export const getTvCablePlan = type => dispatch => {
  dispatch(setIsLoading('getTvCablePlan', true));
  billService
    .get(`/tv-cables/${type}`)
    .then(res => {
      dispatch(setTvCablePlan(type, res.data.data));
      dispatch(setIsLoading('getTvCablePlan', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getTvCablePlan', false));
    });
};

export const getElectricityTypes = () => dispatch => {
  dispatch(setIsLoading('getElectricityTypes', true));
  billService
    .get('/electricity/types')
    .then(res => {
      dispatch({
        type: SET_ELECTRICITY_TYPES,
        payload: res.data,
      });
      dispatch(setIsLoading('getElectricityTypes', false));
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(setIsLoading('getElectricityTypes', false));
    });
};
