import { APP_IS_LOADING, APP_NOT_LOADING, SET_APP_IS_ACTIVE, SET_BASE_URL } from 'src/store/types';

const initialState = {
  isLoading: true,
  isActive: true,
  baseUrl: '/',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_IS_LOADING:
      return { ...state, isLoading: true };
    case APP_NOT_LOADING:
      return { ...state, isLoading: false };
    case SET_APP_IS_ACTIVE:
      return { ...state, isActive: action.payload };
    case SET_BASE_URL:
      return { ...state, baseUrl: action.payload };
    default:
      return state;
  }
}
