import React from 'react';
import { AuthLayout } from 'src/skins/pluto/components/layouts';
import { RegisterForm } from 'src/skins/pluto/forms';

const Register = () => {
  return (
    <AuthLayout pageTitle="Create Account" description="Thanks for choosing us" isRegistering>
      <RegisterForm />
    </AuthLayout>
  );
};

Register.propTypes = {};

export default Register;
