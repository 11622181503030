import { SET_BILLS_LOADING, SET_DATA_PLAN, SET_ELECTRICITY_TYPES, SET_TV_CABLE_PLAN } from 'src/store/types';

const initialState = {
  dataPlan: {},
  tvCablePlan: {},
  electricityTypes: [],
  isLoading: {
    state: false,
    getBillPlan: false,
    getTvCablePlan: false,
    getBillSummary: false,
    getElectricityTypes: false,
    confirmBillTransaction: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BILLS_LOADING:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.action]: action.payload.state,
        },
      };
    case SET_DATA_PLAN:
      return {
        ...state,
        dataPlan: {
          ...state.dataPlan,
          [action.payload.network]: action.payload.data,
        },
      };
    case SET_TV_CABLE_PLAN:
      return {
        ...state,
        tvCablePlan: {
          ...state.tvCablePlan,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_ELECTRICITY_TYPES:
      return {
        ...state,
        electricityTypes: action.payload,
      };
    default:
      return state;
  }
}
