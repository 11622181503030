import QS from 'qs';
import clients from 'src/clients';
import { SET_TRANSACTION_LOADING, ADD_TRANSACTIONS, SET_TRANSACTION_PAGE } from 'src/store/types';
import { errorAlert } from './alertActions';

const { appService, transferService, mediaService } = clients;

const setIsLoading = (action, state) => {
  return {
    type: SET_TRANSACTION_LOADING,
    payload: { action, state },
  };
};

export const setPage = page => {
  return { type: SET_TRANSACTION_PAGE, payload: page };
};

const setTransactions = transactions => {
  return { type: ADD_TRANSACTIONS, payload: transactions };
};

/**
 * @param {string} transactionRef
 */
export const getReceipt = transactionRef => dispatch => {
  mediaService
    .get(`/transactions/${transactionRef}/receipt`, { responseType: 'arraybuffer' })
    .then(res => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([res.data]));
      a.download = 'receipt.pdf';
      document.body.appendChild(a);
      a.click();
    })
    .catch(() => {
      dispatch(errorAlert('Unable to download receipt'));
    });
};

/**
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.platform
 */
export const getTransactions =
  (page = 1, otherQueries) =>
  dispatch => {
    const queryString = QS.stringify({ page, ...otherQueries });
    dispatch(setIsLoading('getTransactions', true));
    appService
      .get(`/users/history?${queryString}`)
      .then(res => {
        dispatch(setTransactions(res.data));
        dispatch(setIsLoading('getTransactions', false));
      })
      .catch(() => dispatch(setIsLoading('getTransactions', false)));
  };

/**
 * Get Bank List
 * @param {(data: [string]|null, error: string|null) => any} callback
 */
export const getBankList = callback => dispatch => {
  dispatch(setIsLoading('getBankList', true));
  transferService
    .get('/bank/list')
    .then(res => callback(res.data, null))
    .catch(err => callback(null, err.response.data))
    .finally(() => dispatch(setIsLoading('getBankList', false)));
};

export const newTransfer = (data, type, callback) => dispatch => {
  dispatch(setIsLoading('newTransfer', true));
  transferService
    .post(`/${type}`, data)
    .then(res => callback(res.data.message, false))
    .catch(err => {
      callback(false, err.response.data.message);
      dispatch(errorAlert(err.response.data.message));
    })
    .finally(() => dispatch(setIsLoading('newTransfer', false)));
};

export const getTransactionSummary = (data, type, callback) => dispatch => {
  dispatch(setIsLoading('getTransactionSummary', true));
  transferService
    .post(`/${type}/summary`, data)
    .then(res => callback(res.data, false))
    .catch(err => dispatch(errorAlert(err.response.data.message)))
    .finally(() => dispatch(setIsLoading('getTransactionSummary', false)));
};

export const getTransactionDetails = (reference, callback) => dispatch => {
  dispatch(setIsLoading('getTransactionDetails', true));
  appService
    .get(`/users/transaction/${reference}`)
    .then(res => callback(res.data, false))
    .catch(err => dispatch(errorAlert(err.response.data.message || err.message || '')))
    .finally(() => dispatch(setIsLoading('getTransactionDetails', false)));
};

// Cardless Withdrawals

export const requestThirdPartyWithdrawal = (data, callback) => dispatch => {
  dispatch(setIsLoading('requestThirdPartyWithdrawal', true));
  appService
    .post('/third-party/withdrawal', data)
    .then(res => callback(res.data.message, false))
    .catch(err => {
      callback(false, err?.response?.data?.message);
      dispatch(errorAlert(err?.response?.data?.message || err?.message || ''));
    })
    .finally(() => dispatch(setIsLoading('requestThirdPartyWithdrawal', false)));
};

export const getThirdPartyWithdrawalSummary = (data, callback) => dispatch => {
  dispatch(setIsLoading('getThirdPartyWithdrawalSummary', true));
  appService
    .post('/third-party/withdrawal-summary', data)
    .then(res => callback(res.data, false))
    .catch(err => dispatch(errorAlert(err?.response?.data?.message || err?.message || '')))
    .finally(() => dispatch(setIsLoading('getThirdPartyWithdrawalSummary', false)));
};
