import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

/**
 * @description FadeIn Component
 * @param {FadeInProps} props
 */
const FadeIn = ({ children }) => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000, tension: 10 },
    height: '100%',
    borderRadius: 'inherit',
  });
  return <animated.div style={props}>{children}</animated.div>;
};

/**
 * FadeIn PropType Definition
 * @typedef {Object} FadeInProps
 * @property {React} children
 */
FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FadeIn };
