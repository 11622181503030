import React from 'react';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { BuyAirtimeForm } from 'src/skins/mars/forms';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MarsModal } from 'src/skins/mars/components/common';

const pageTitle = 'Buy Airtime';

const Wrapper = styled.div`
  section {
    margin: 1rem 0;
  }
`;

const BuyAirtime = () => {
  const history = useHistory();
  return (
    <DashboardLayout>
      <MarsModal isOpen={true} closeModal={() => history.goBack()} fullheight={true} backgroundPage="bills">
        <Wrapper>
          <h2>{pageTitle}</h2>

          <section>
            <BuyAirtimeForm />
          </section>
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

export default BuyAirtime;
