import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { Spinner } from './Spinner';

const Wrapper = styled.div`
  .button {
    text-transform: none;
    height: 2rem;
    min-width: 5rem;
    padding: 1rem;
    color: ${props => props.theme.color.light};
    border: 1px solid transparent;
    border-radius: 8px;
    :focus {
      outline: 2px solid transparent;
    }
  }
  .small {
    width: max-content;
  }
  .large {
    width: 100%;
    height: 3rem;
    font-size: 1.4em;
  }
  .primary {
    background-color: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.light};
    :hover {
      background-color: ${props => props.theme.color.primary}BB;
    }
  }
  .success {
    background-color: ${props => props.theme.color.success};
    :hover {
      background-color: ${props => props.theme.color.success}BB;
    }
  }
  .failure {
    background-color: ${props => props.theme.color.danger};
    :hover {
      background-color: ${props => props.theme.color.danger}BB;
    }
  }
  .light {
    background-color: ${props => props.theme.color.light};
    color: ${props => props.theme.color.light};
    :hover {
      background-color: ${props => props.theme.color.light}BB;
      color: ${props => props.theme.color.light};
    }
  }
  .disabled {
    pointer-events: none;
    background-color: ${props => props.theme.color.disabled};
  }
  .loading {
    pointer-events: none;

    .spinner {
      margin-top: 0.4rem;
    }
  }
  .rounded {
    border-radius: 2rem;
  }
  .outline {
    background-color: ${props => props.theme.color.light};
    color: ${props => props.theme.color.primary};
    border: 1px solid ${props => props.theme.color.primary};
    :hover {
      background-color: ${props => props.theme.color.light};
      color: ${props => props.theme.color.primary}BB;
      border: 1px solid ${props => props.theme.color.primary}BB;
      opacity: 0.7;
    }
  }
`;

/**
 * @description Button Component
 * @param {ButtonProps} props
 */
const Button = props => {
  const { type, text, alt, theme, spinnerTheme, onClick, endIcon, startIcon, size, className, isLoading, style } =
    props;
  return (
    <Wrapper>
      <Tooltip title={alt || text}>
        <MaterialButton
          type={type}
          onClick={onClick}
          endIcon={endIcon}
          startIcon={startIcon}
          className={`button ${theme} ${size} ${style} ${isLoading && 'loading'} ${className}`}
        >
          {isLoading ? <Spinner theme={spinnerTheme} /> : text}
        </MaterialButton>
      </Tooltip>
    </Wrapper>
  );
};

Button.defaultProps = {
  type: 'button',
  theme: 'primary',
  spinnerTheme: 'light',
  isLoading: false,
  style: 'block',
  size: 'small',
};

/**
 * Button PropType Definition
 * @typedef {Object} ButtonProps
 * @property {('primary'|'light'|'success'|'failure'|'disabled')} theme
 * @property {('primary'|'light')} spinnerTheme
 * @property {('block'|'rounded')} style
 * @property {('small'|'large')} size
 * @property {string} className
 * @property {string} text
 * @property {string} alt
 * @property {boolean} isLoading
 * @property {React} startIcon
 * @property {React} endIcon
 * @property {Function} onClick
 */
Button.propTypes = {
  theme: PropTypes.oneOf(['primary', 'light', 'success', 'failure', 'disabled']).isRequired,
  spinnerTheme: PropTypes.oneOf(['primary', 'light']).isRequired,
  style: PropTypes.oneOf(['block', 'rounded']).isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  alt: PropTypes.string,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  onClick: PropTypes.func,
};

export { Button };
