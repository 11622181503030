import React from 'react';
import Theme from './theme/Theme';
import Skins from './skins';

const App = () => {
  return (
    <Theme>
      <Skins />
    </Theme>
  );
};

export default App;
