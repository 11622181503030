import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    background-color: #ffffff;
    color: ${props => props.theme.color.darkGray};
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08);

    &:hover {
      border-color: ${props => props.theme.color.primary}25;
      cursor: pointer;

      .account-image {
        background-color: ${props => props.theme.color.primary}BB;
      }
    }
  }

  .account-image {
    margin: 0 0.5rem;
    min-width: 2rem;
    min-height: 2rem;
    background-color: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.light}BB;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    display: grid;
    place-items: center;
  }

  .account-summary {
    margin: 0 0.5rem;
    width: calc(100% - 4rem);
  }

  .data-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & :first-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1.5rem;
    }

    & :last-child {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: auto;
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
    }
  }

  .black {
    color: ${props => props.theme.color.black};
  }
`;

export const TransferBeneficiaryItem = props => {
  const { beneficiary, method } = props;
  const { name, bank, accountNumber, iuwi } = beneficiary;
  const initials = name ? name[0] : '';

  const getData = () => {
    return method === 'peer' ? { name, iuwi, state: true } : { name, bank, accountNumber, state: true };
  };

  return (
    <Wrapper>
      <Link
        to={{
          pathname: `/dashboard/transfer/${method}`,
          state: getData(),
        }}
      >
        <div className="account-image">{initials}</div>
        <div className="account-summary">
          <div className="data-flex bold">
            <span className="black">{name || ''}</span>
            <span>&nbsp;</span>
          </div>
          <div className="data-flex">
            <span>{accountNumber || iuwi}</span>
            <span>{bank || ''}</span>
          </div>
        </div>
      </Link>
    </Wrapper>
  );
};

TransferBeneficiaryItem.defaultPropTypes = {
  beneficiary: {},
  method: '',
};

/**
 * TransferBeneficiaryItem PropType Definition
 * @typedef {Object} TransferBeneficiaryItem
 * @property {Object} beneficiary
 * @property {String} method
 */
TransferBeneficiaryItem.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  method: PropTypes.string.isRequired,
};

export default TransferBeneficiaryItem;
