import AirtimeImage from 'src/assets/images/bills/airtime.svg';
import DataImage from 'src/assets/images/bills/data.svg';
//import InternetImage from 'src/assets/images/bills/internet.svg';
import AEDCImage from 'src/assets/images/bills/aedc.png';
import EKEDCImage from 'src/assets/images/bills/ekedc.png';
import IBEDCImage from 'src/assets/images/bills/ibedc.png';
import IKDCImage from 'src/assets/images/bills/ikdc.png';
import KADCImage from 'src/assets/images/bills/kadc.png';
import KEDCImage from 'src/assets/images/bills/kedc.png';

import DSTVImage from 'src/assets/images/bills/dstv.png';
import GOTVImage from 'src/assets/images/bills/gotv.png';
import StarTimesImage from 'src/assets/images/bills/startimes.png';

export const billsPageData = {
  recharge: [
    { name: 'Airtime', imgSrc: AirtimeImage, type: 'airtime' },
    { name: 'Mobile Data', imgSrc: DataImage, type: 'data' },
  ],
  tvcables: [
    { name: 'DSTV', imgSrc: DSTVImage, type: 'dstv' },
    { name: 'GOTV', imgSrc: GOTVImage, type: 'gotv' },
    { name: 'STARTIMES', imgSrc: StarTimesImage, type: 'startimes' },
  ],
};

export const phcnImages = {
  ekedc: EKEDCImage,
  ikedc: IKDCImage,
  ibedc: IBEDCImage,
  aedc: AEDCImage,
  kaedco: KADCImage,
  kedco: KEDCImage,
};

export const airtimeNetworkOption = [
  { label: 'MTN', value: 'mtn' },
  { label: 'Airtel', value: 'air' },
  { label: 'GLO', value: 'glo' },
  { label: 'Etisalat', value: 'eti' },
];
