import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MarsButton } from 'src/skins/mars/components/common';
import { DashboardLayout } from 'src/skins/mars/components/layouts';
import { infoAlert } from 'src/store/actions/alertActions';
import { copyToClipboard } from 'src/utils';
import { createVirtualWallet, getVirtualWalletRequests } from 'src/store/actions/userActions';
import { MarsModal } from 'src/skins/mars/components/common';
import { TextInput } from 'src/skins/mars/components/inputs';
import { RequestBvnModal } from 'src/skins/mars/modals';

const pageTitle = 'Fund wallet';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  section {
    margin: 1rem 0;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }
`;

const Wallet = () => {
  const history = useHistory();

  const mmo = useSelector(state => state.MMO.mmo);
  const { wallet, isLoading, virtualWalletRequests } = useSelector(state => state.USER);
  const unapprovedWalletRequests = virtualWalletRequests.filter(request => {
    return request.status !== 'approved';
  });
  const dispatch = useDispatch();
  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  useEffect(() => {
    dispatch(getVirtualWalletRequests());
  }, []);

  const virtualWalletsHaveBankName = bankName => {
    const virtualWallets = wallet?.virtualWallets || [];
    const hasBankName = virtualWallets.some(wallet => {
      return new RegExp(bankName, 'i').test(wallet.bankName);
    });
    return hasBankName;
  };

  const mmoIsNotBlacklisted = !mmo.blacklisted;
  const mmoIsKredi = mmo?.shortCode === 'kredi';
  const mmoIsNotKredi = !mmoIsKredi;
  const safeHavenWalletExist = () => virtualWalletsHaveBankName('SAFE HAVEN');
  const krediWalletExist = () => virtualWalletsHaveBankName('Kredi');
  const providusWalletExist = () => virtualWalletsHaveBankName('Providus');
  const transferToMsg =
    wallet?.virtualWallets?.length > 1
      ? 'Transfer to any of these accounts to fund your wallet'
      : 'Transfer to this account to fund your wallet';

  const [showBvnModal, setShowBvnModal] = useState(false);
  const openBvnModal = vendor => setShowBvnModal(vendor);
  const closeBvnModal = () => setShowBvnModal(false);

  const virtualWallets = mmoIsKredi
    ? (wallet.virtualWallets || []).filter(wallet => {
        return new RegExp('kredi', 'i').test(wallet.bankName);
      })
    : wallet.virtualWallets || [];

  return (
    <DashboardLayout>
      <MarsModal
        isOpen={true}
        closeModal={() => history.replace('/dashboard')}
        fullheight={true}
        backgroundPage="dashboard"
      >
        <Wrapper>
          <div className="header">
            <h2>{pageTitle}</h2>
          </div>

          <br />

          <div className="text-center">{transferToMsg}</div>

          <section>
            {isLoading.getUserWallet && <p className="text-center">Fetching Wallet details ....</p>}
            {isLoading.createVirtualWallet && <p className="text-center">Creating virtual wallet</p>}

            <br />

            {!isLoading.state &&
              virtualWallets.length > 0 &&
              virtualWallets.map((wallet, index) => (
                <div key={wallet.accountNumber}>
                  <TextInput
                    type="text"
                    name="accountName"
                    placeholder="Account Name"
                    value={wallet.accountName}
                    onChange={() => null}
                    readOnly
                  />
                  <TextInput
                    type="text"
                    name="accountNumber"
                    placeholder="Account Number"
                    value={wallet.accountNumber}
                    onChange={() => null}
                    readOnly
                  />
                  <TextInput
                    type="text"
                    name="bankName"
                    placeholder="Bank Name"
                    value={wallet.bankName}
                    onChange={() => null}
                    readOnly
                  />
                  <div>
                    <MarsButton
                      size="large"
                      text="Copy account number"
                      onClick={() => copyToClipboard(`virtualAcctNumber${index}`, copiedAlert)}
                    />
                    <input
                      id={`virtualAcctNumber${index}`}
                      type="text"
                      value={wallet.accountNumber}
                      onChange={null}
                      readOnly
                      style={hiddenInputStyle}
                    />
                  </div>
                </div>
              ))}

            {mmoIsNotBlacklisted && mmoIsNotKredi && (
              <>
                {!safeHavenWalletExist() && (
                  <>
                    <MarsButton
                      size="small"
                      text="Generate Safe Haven Virtual Account"
                      isLoading={isLoading.createVirtualWallet === 'safe-haven'}
                      onClick={() => openBvnModal('safe-haven')}
                    />
                    <RequestBvnModal
                      isOpen={showBvnModal === 'safe-haven'}
                      closeModal={closeBvnModal}
                      clickIsLoading={isLoading.createVirtualWallet === 'safe-haven'}
                      handleConfirmTransactionClick={bvn => {
                        dispatch(
                          createVirtualWallet(
                            { bvn, vendor: 'safe-haven' },
                            {
                              success: () => closeBvnModal(),
                            }
                          )
                        );
                      }}
                    />
                  </>
                )}

                <br />
              </>
            )}

            {!krediWalletExist() && (
              <>
                <MarsButton
                  size="small"
                  text="Generate Kredi Virtual Account"
                  isLoading={isLoading.createVirtualWallet === 'kredi'}
                  onClick={() => openBvnModal('kredi')}
                />
                <RequestBvnModal
                  isOpen={showBvnModal === 'kredi'}
                  closeModal={closeBvnModal}
                  clickIsLoading={isLoading.createVirtualWallet === 'kredi'}
                  handleConfirmTransactionClick={bvn => {
                    dispatch(
                      createVirtualWallet(
                        { bvn, vendor: 'kredi' },
                        {
                          success: () => closeBvnModal(),
                        }
                      )
                    );
                  }}
                />
                <br />
              </>
            )}

            {mmoIsNotBlacklisted && mmoIsNotKredi && (
              <>
                {!providusWalletExist() && (
                  <>
                    <MarsButton
                      size="small"
                      text="Generate Providus Virtual Account"
                      isLoading={isLoading.createVirtualWallet === 'providus'}
                      onClick={() => openBvnModal('providus')}
                    />
                    <RequestBvnModal
                      isOpen={showBvnModal === 'providus'}
                      closeModal={closeBvnModal}
                      clickIsLoading={isLoading.createVirtualWallet === 'providus'}
                      handleConfirmTransactionClick={bvn => {
                        dispatch(
                          createVirtualWallet(
                            { bvn, vendor: 'providus' },
                            {
                              success: () => closeBvnModal(),
                            }
                          )
                        );
                      }}
                    />
                  </>
                )}
              </>
            )}
          </section>

          {unapprovedWalletRequests.length > 0 && (
            <section>
              <br />

              <div className="header">
                <h3>Wallet Requests</h3>
              </div>

              <div>
                {unapprovedWalletRequests.map((request, index) => (
                  <div key={index}>
                    <div className="flex-between">
                      <span>{index + 1}.</span>
                    </div>
                    <div className="flex-between">
                      <span>Virtual Wallet Type</span>
                      <span>{request.vendor}</span>
                    </div>
                    <div className="flex-between">
                      <span>Status</span>
                      <span>{request.status}</span>
                    </div>
                    {request.status !== 'pending' && request.reason && (
                      <div className="flex-between">
                        <span>Reason</span>
                        <span>{request.reason}</span>
                      </div>
                    )}
                    <div className="flex-between">
                      <span>Requested on</span>
                      <span>{request.timeCreated ? new Date(request.timeCreated).toDateString() : ''}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </Wrapper>
      </MarsModal>
    </DashboardLayout>
  );
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '0' };

export default Wallet;
