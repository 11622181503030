import clients from 'src/clients';

const { appService } = clients;

/**
 * Report Error Data
 * @param {object} data
 * @param {string} data.appName
 * @param {string} data.errorLevel
 * @param {string} data.fileName
 * @param {string} data.module
 * @param {string} data.functionName
 * @param {string} data.lineNumber
 * @param {string} data.message
 * @param {string} data.requestId
 * @param {string} data.meta
 */
export const reportError = data => {
  appService.post('/mmos/report-error', data);
};
