import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'src/skins/common/icon';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.color.pluto.lightestGray};
    box-shadow: 0px 2px 6px rgba(111, 106, 106, 0.05), inset 0px 2px 2px rgba(111, 106, 106, 0.05);
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 0;
    background-color: #ffffff;
    text-decoration: none;
    color: currentColor;
    outline: none;

    & > :last-child {
      width: 100%;
    }
  }

  .transaction-icon {
    display: grid;
    place-items: center;
    min-width: 45px;
    min-height: 45px;
    border-radius: 5px;
    padding-top: 0.25rem;
    margin-right: 1rem;

    &[action='credit'] {
      background: ${props => props.theme.color.pluto.creditBgColor};
      color: ${props => props.theme.color.pluto.creditColor};
    }

    &[action='debit'] {
      background: ${props => props.theme.color.pluto.debitBgColor};
      color: ${props => props.theme.color.pluto.debitColor};
    }

    &[cancelled] {
      background: ${props => props.theme.color.pluto.cancelledBgColor};
      color: ${props => props.theme.color.pluto.cancelledColor};
    }
  }

  .text-small {
    font-size: small;
  }

  .color-textGray {
    color: ${props => props.theme.color.textGray};
  }

  .color-blue {
    color: ${props => props.theme.color.primary};
  }

  .color-red {
    color: ${props => props.theme.color.pluto.debitColor};
  }
`;

export const TransactionItem = ({ transaction }) => {
  const { id = '123', accountName, amount, action, narrative, cancelled } = transaction || {};
  const iconName = action === 'credit' ? 'credit' : 'debit';

  return (
    <Wrapper>
      <Link to={`/dashboard/aggregator/transactions/${id}`}>
        <div className="transaction-icon" action={action} cancelled={cancelled ? '' : null}>
          <Icon name={iconName} size="small" />
        </div>
        <div className="transaction-info">
          <div className="flex-space-between">
            <span>{accountName}</span>
            <span className="color-blue">#{amount}</span>
          </div>
          <div className="flex-space-between text-small">
            <span className="color-textGray">{narrative}</span>
            <span className="color-red">View All</span>
          </div>
        </div>
      </Link>
    </Wrapper>
  );
};

/**
 * TransactionItem PropType Definition
 * @typedef {Object} TransactionItemProps
 * @property {Object} transaction
 */
TransactionItem.propTypes = {
  transaction: PropTypes.object,
};

export default TransactionItem;
