import React from 'react';
import { Link } from 'react-router-dom';
import { Button, PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Auth = () => {
  const { mmo } = useSelector(state => state.MMO);

  return (
    <PageView>
      <Wrapper>
        <h2>{mmo.name}</h2>
        <p>{mmo.motto}</p>
        <Link to="/login" className="text-decoration-none">
          <Button text="Sign In" size="large" />
        </Link>
        <Link to="/register" className="text-decoration-none">
          <Button text="Sign Up" size="large" className="outline" />
        </Link>
      </Wrapper>
    </PageView>
  );
};

export default Auth;
