import React from 'react';
import PropTypes from 'prop-types';
import { MarsModal } from 'src/skins/mars/components/common';
import styled from 'styled-components';

const Wrapper = styled.div`
  .aggregator-status-icon {
    position: relative;
    margin: 1rem auto 2rem auto;

    &:before {
      display: block;
      content: '';
      width: 8rem;
      height: 8rem;
      border: 1px solid ${props => props.theme.color.primary};
      border-radius: 50%;
    }

    &[status='pending'] {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% + 0.125rem);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 0.5rem;
        background-color: #fbd305;
      }
    }

    &[status='sent'],
    &[status='successful'] {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 1rem;
        left: 7.5rem;
        width: 2rem;
        height: 5rem;
        border: solid #1de331;
        border-width: 0 0.5rem 0.5rem 0;
        transform: rotate(45deg);
      }
    }
  }

  .aggregator-content {
    margin-top: 1rem;
  }
`;

const AggregatorRequestModal = ({ isOpen, closeModal, status, children }) => {
  return (
    <MarsModal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <center>
          <div className="aggregator-status-icon" status={status}></div>
          <div className="aggregator-content">{children}</div>
        </center>
      </Wrapper>
    </MarsModal>
  );
};

AggregatorRequestModal.defaultProps = {
  isOpen: false,
  closeModal: null,
  status: 'failed',
};

/**
 * TransactionStatusModal PropType Definition
 * @typedef {Object} TransactionStatusModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {('successful'|'pending'|'failed')} status
 * @property {Node} children
 */
AggregatorRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export { AggregatorRequestModal };
