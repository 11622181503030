import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'src/skins/common/components';
import styled from 'styled-components';
import { formatCurrency } from 'src/utils';
import { useState } from 'react';

const Wrapper = styled.div`
  position: relative;
  margin: -1.5rem;

  .invoice-summary-title {
    color: ${props => props.theme.color.primary};
    font-weight: 500;
    padding: 1.5rem;
  }

  .invoice-summary {
    margin: -1rem 0 1.25rem 0;
    padding: 0 1.5rem;
    color: ${props => props.theme.color.black};

    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      margin-bottom: 0.75rem;

      & > :first-child {
        justify-self: flex-start;
        font-weight: 500;
      }

      & > :last-child {
        justify-self: flex-end;
        text-align: right;
      }
    }
  }

  .divider {
    width: 100%;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  button {
    width: 100%;
    height: 4em;
    background: transparent;
    color: ${props => props.theme.color.primary};
    font-size: 1.2rem;
    border: 0;
    outline: none;
    appearance: none;
    cursor: pointer;
  }
  .isLoading {
    pointer-events: none;
  }
`;

/**
 * @description CollectionSummaryModal Component
 * @param {CollectionSummaryModalProps} props
 */
const CollectionSummaryModal = props => {
  const { isOpen, closeModal, invoice, clickIsLoading, handleConfirmTransferClick } = props;

  const [data, setData] = useState({});

  const displayItem = (name, value) => (
    <div>
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );

  useEffect(() => {
    setData(invoice);
  }, [invoice]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Wrapper>
        <h5 className="invoice-summary-title">Collection Summary</h5>

        <br />

        <div className="invoice-summary">
          {data.name && displayItem('Beneficiary: ', data.name)}
          {data.description && displayItem('Description: ', data.description)}
          {data.transactionType && displayItem('Transaction Type: ', data.transactionType)}
          {data.invoiceType && displayItem('Collection Type: ', data.invoiceType)}
          {data.amount && displayItem('Amount: ', formatCurrency(data.amount || 0, 'NGN'))}
          {data.charges && displayItem('Charge: ', data.charges)}

          {data.invoiceData &&
            data.invoiceData.map((dataInput, index) => (
              <React.Fragment key={index}>
                {dataInput.value && displayItem(`${dataInput.label}: `, dataInput.value)}
              </React.Fragment>
            ))}
        </div>

        <div className="divider"></div>

        <button onClick={handleConfirmTransferClick} className={clickIsLoading ? 'isLoading' : ''}>
          {clickIsLoading ? <Spinner theme="primary" /> : 'Confirm'}
        </button>
      </Wrapper>
    </Modal>
  );
};

CollectionSummaryModal.defaultProps = {
  invoice: {},
  isOpen: false,
  closeModal: null,
  clickIsLoading: false,
  handleConfirmTransferClick: null,
};

/**
 * CollectionSummaryModal PropType Definition
 * @typedef {Object} CollectionSummaryModalProps
 * @property {boolean} isOpen
 * @property {boolean} clickIsLoading
 * @property {Function} closeModal
 * @property {Object} invoice
 * @property {Function} handleConfirmTransferClick
 */
CollectionSummaryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  clickIsLoading: PropTypes.bool.isRequired,
  handleConfirmTransferClick: PropTypes.func.isRequired,
};

export { CollectionSummaryModal };
