import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { PageView } from 'src/skins/common/components';
import styled from 'styled-components';
import { Icon } from 'src/skins/common/icon';
import VenusBgEffect from 'src/assets/svgs/venus-bg-effect.svg';

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.light};
  color: ${props => props.theme.color.venus.text};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  main {
    height: 100%;
    overflow-y: auto;
    padding: 1rem 1rem 0 1rem;
    background-image: url('${VenusBgEffect}');
    background-position: left calc(100% + 55px);
    background-size: 175px 127px;
    background-repeat: no-repeat;
  }

  nav {
    width: 100%;
    background-color: ${props => props.theme.color.light};
    box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.06);

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      margin: 0;

      li {
        a {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          background: transparent;
          color: ${props => props.theme.color.gray};
          font-weight: 600;
          text-decoration: none;
          margin: 1rem;
          outline: none;
          line-height: 100%;

          &:focus,
          &.active {
            color: ${props => props.theme.color.primary};
          }

          &:hover {
            color: ${props => props.theme.color.primary};
          }

          span:last-child {
            font-size: 10px;
          }
        }

        &.always-active {
          position: relative;
          width: 4rem;
          height: 2.5rem;

          a {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: -1rem;
            margin-left: 2rem;
            transform: translate(-50%, -50%);
            background-size: contain;
            background: ${props => props.theme.color.primary};
            color: ${props => props.theme.color.light};
            width: 3.75rem;
            height: 3.75rem;
            border-radius: 50%;
            box-shadow: 0px 4px 12px rgba(97, 62, 234, 0.5);
          }
        }
      }
    }
  }
`;

const DashboardLayout = ({ children }) => {
  return (
    <React.Fragment>
      <PageView className="no-overflow no-padding">
        <Wrapper>
          <main>{children}</main>

          <nav>
            <ul>
              <li>
                <NavLink to="/dashboard" exact activeClassName="active">
                  <Icon size="small" name="home" />
                  <span>Overview</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/wallet" exact activeClassName="active">
                  <Icon size="small" name="wallet" />
                  <span>Wallet</span>
                </NavLink>
              </li>
              <li className="always-active">
                <Link to="#new">
                  <Icon size="small" name="plus" />
                </Link>
              </li>
              <li>
                <NavLink to="/dashboard/transactions" activeClassName="active">
                  <Icon size="small" name="list" />
                  <span>History</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/settings" exact activeClassName="active">
                  <Icon size="small" name="settings" />
                  <span>Settings</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </Wrapper>
      </PageView>
    </React.Fragment>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export { DashboardLayout };
