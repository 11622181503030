import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100vh;
		display: flex;
		overflow: hidden;
		font-size: 0.8rem;
		align-items: center;
		justify-content: center;
		font-family: Poppins, 'sans-serif';
		color: ${props => props.theme.color.text};
		background-color: ${props => props.theme.color.dark};
	}
	div, span {
		margin: 0;
		padding: 0;
	}
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}
	h1 {
		font-size: calc(0.4rem + 1.5em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h2 {
		font-size: calc(0.4rem + 1.4em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h3 {
		font-size: calc(0.4rem + 1.3em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h4 {
		font-size: calc(0.4rem + 1em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h5 {
		font-size: calc(0.4rem + 0.9em);
		font-weight: 600;
		color: ${props => props.theme.color.darkText};
	}
	h6 {
		font-size: calc(0.4rem + 0.4em);
		font-weight: 600;
	}
	p {
		font-size: calc(0.4rem + 0.5em);
		font-weight: 500;
	}
	a {
		color: ${props => props.theme.color.primary};
		text-decoration: none;

		&:focus,
		&:hover {
			color: ${props => props.theme.color.primary}BB;
		}
	}
	.error {
		color: ${props => props.theme.color.venus?.danger || props.theme.color.danger}
	}
	.text-decoration-none {
		text-decoration: none;
	}

	.color {
		&-primary {
			color: ${props => props.theme.color.primary};
		}
		&-gray {
			color: ${props => props.theme.color.venus.gray || props.theme.color.gray};
		}
		&-red {
			color: ${props => props.theme.color.venus.danger || props.theme.color.danger};
		}
	}

	.text {
		&-normal {
			font-size: 1rem;
		}
		&-lg {
			font-size: large;
		}
		&-sm {
			font-size: small;
		}
		&-xs {
			font-size: x-small;
		}
		&-xl {
			font-size: x-large;
		}
		&-left {
			text-align: left;
		}
		&-center {
			text-align: center;
		}
		&-right {
			text-align: right;
		}
		&-bold {
			font-weight: bold;
		}
	}

	.no-overflow {
		overflow: hidden !important;
	}

	.no-padding {
		padding: 0 !important;
	}

	.flex-space-between {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.loading-state,
	.empty-state {
		display: flex;
		height: 15vh;
		min-height: 10rem;
		justify-content: center;
		align-items: center;
	}

	.pointer {
		cursor: pointer;
	}

	.rotate-180 {
		transform: rotate(180deg);
	}

	a[as="button"] {
		text-decoration: none;
	}

${'' /* Venus Button Circle */}
	.venus--button-circle {
		border: 1px solid transparent;
		/* background: ${props => props.theme.color.primary}; */
		background: linear-gradient(16.84deg,
			${props => props.theme.color.primary} -10.65%,
			${props => props.theme.color.primary} 32.7%,
			${props => props.theme.color.primary} 65.78%,
			${props => props.theme.color.secondary} 98.86%
		);
		color: ${props => props.theme.color.light};
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 100%;
		width: 48px;
		height: 48px;
		min-width: 48px;
		min-height: 48px;
		font-size: 24px;
		outline: none;

		&:focus,
		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		&[theme='secondary'] {
			background: ${props => props.theme.color.secondary};
			color: ${props => props.theme.color.light};
		}
	}

	.divider {
		position: relative;

		&:before {
			content: '';
			border-top: 1px solid ${props => props.theme.color.gray};
			display: block;
			width: 100%;
			position: absolute;
			top: 50%;
			z-index: -1;
			margin-top: -1px;
		}

		&:after {
			content: attr(data-text);
			text-align: center;
			display: block;
			width: fit-content;
			margin: 0 auto;
			font-size: 1.1rem;
			background-color: ${props => props.theme.color.light};
			color: ${props => props.theme.color.gray};
			padding: 0.2rem 0.8rem;
			margin-top: -2px;
		}
	}

	${'' /* For PageView .container */}
	.container {
		&.light {
			color: ${props => props.theme.color.venus.text || props.theme.color.text} !important;
		}
	}

	${'' /* For Aggregator */}
	.like-link {
		color: ${props => props.theme.color.primary};
		text-decoration: none;
		:hover {
			opacity: 0.85;
		}
	}

	.error-msg {
		color: ${props => props.theme.color.venus?.danger || props.theme.color.danger};
		padding: 0.25rem 0;
		font-size: small;
	}

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 2rem 1rem;
		background: ${props => props.theme.color.light};
		color: ${props => props.theme.color.primary};
		box-shadow: 0 20px 20px ${props => props.theme.color.lightGray};
		border-radius: 6px;

		&.card-primary {
			background: ${props => props.theme.color.primary};
			color: ${props => props.theme.color.light};
			box-shadow: 0 20px 20px ${props => props.theme.color.venus.cardBoxShadow};
		}

		.title {
			font-size: 1.4rem;
			font-weight: bold;
			letter-spacing: 1px;
		}

		.subtitle {
			opacity: 0.9;
			letter-spacing: 0.1px;
		}

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
`;

export default GlobalStyle;
